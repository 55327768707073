import { combineReducers } from 'redux'
import authReducer from './auth.reducer'
import messageReducer from './message.reducer'
import categoriesReducer from './category.reducer'
import clubbedBuyingReducer from './clubbed-buying.reducer'
import messageTemplateReducer from './message-template.reducer'
import usersReducer from './users.reducer'
import productReducer from './product.reducer'
import attributeReducer from './attributes.reducer'
import edgeReducer from './edge.reducer'
import OrdersReducer from './orders.reducer'

export default combineReducers({
    auth                      : authReducer,    
    message                   : messageReducer,
    categories                : categoriesReducer,
    clubbedBuying             : clubbedBuyingReducer,
    messageTemplate           : messageTemplateReducer,
    users                     : usersReducer,  
    products                  : productReducer,
    attributes                : attributeReducer,
    edge                      : edgeReducer,
    orders                     : OrdersReducer
})