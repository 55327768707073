import { EMAIL_LIST, EMAIL_BY_NAME, EMAIL_ADD, EMAIL_EDIT, SMS_LIST, SMS_BY_NAME, SMS_ADD, SMS_EDIT, FIREBASE_NOTIFICATION_LIST,FIREBASE_NOTIFICATION_ADD,FIREBASE_NOTIFICATION_EDIT,FIREBASE_NOTIFICATION_BY_NAME } from "../actions/types";

const initialState = { 
  messageTemplate: {
    emailList:null,
    addEmailData:null,
    editEmailData:null,
    emailByName:null,

    smsList:null,
    addSmsData:null,
    editSmsData:null,
    smsByName:null,

    firebaseNotificationList:null,
    addFirebaseNotificationData:null,
    editFirebaseNotificationData:null,
    firebaseNotificationByName:null
  }
}

export default function foo(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case EMAIL_LIST:
      return {
        messageTemplate:{
          ...state.messageTemplate,
          emailList:payload,
        } ,
      }; 
    case EMAIL_BY_NAME:
      return {
        messageTemplate:{
          ...state.messageTemplate,
          emailByName:payload
        },
    }; 
    case EMAIL_ADD:
      return {
        messageTemplate:{
          ...state.messageTemplate,
          addEmailData:payload
        },
    }; 
    case EMAIL_EDIT:
      return {
        messageTemplate:{
          ...state.messageTemplate,
          editEmailData:payload
        },
    }; 

    case SMS_LIST:
      return {
        messageTemplate:{
          ...state.messageTemplate,
          smsList:payload,
        } ,
      }; 
    case SMS_BY_NAME:
      return {
        messageTemplate:{
          ...state.messageTemplate,
          smsByName:payload
        },
    }; 
    case SMS_ADD:
      return {
        messageTemplate:{
          ...state.messageTemplate,
          addSmsData:payload
        },
    }; 
    case SMS_EDIT:
      return {
        messageTemplate:{
          ...state.messageTemplate,
          editSmsData:payload
        },
    };  


    case FIREBASE_NOTIFICATION_LIST:
      return {
        messageTemplate:{
          ...state.messageTemplate,
          firebaseNotificationList:payload,
        } ,
      }; 
    case FIREBASE_NOTIFICATION_BY_NAME:
      return {
        messageTemplate:{
          ...state.messageTemplate,
          firebaseNotificationByName:payload
        },
    }; 
    case FIREBASE_NOTIFICATION_ADD:
      return {
        messageTemplate:{
          ...state.messageTemplate,
          addFirebaseNotificationData:payload
        },
    }; 
    case FIREBASE_NOTIFICATION_EDIT:
      return {
        messageTemplate:{
          ...state.messageTemplate,
          editFirebaseNotificationData:payload
        },
    };  
    default:
      return state;
  }
}