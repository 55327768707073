import React,{useState} from "react"
import axios from "axios"
import { clubbedBuyingBannerEdit } from "../../../actions/clubbed-buying.action"
import { toast } from "react-toastify"

const BannerEdit = ({defaultData,formHandle, dispatch}) =>{
  const [imageUrl, setImageUrl]            = useState()
  const [previmageUrl, setPrevImageUrl]    = useState(defaultData.image)
  const [btnLoader, setBtnLoader]          = useState(false)

  // IMAGE UPLOAD
  const uploadF = (selectorFiles) => {
    var data = new FormData();
    data.append('file', selectorFiles[0]);
    data.append('foldername', 'banner');
    axios.post("/file/upload", data)      
      .then((response) => {
        setImageUrl(response.data);
        setPrevImageUrl(response.data);
      }).catch(err => {
        setBtnLoader(false)
        console.error(err.response.data)
      })
  }

  // SUBMIT FORM 
  const onSubmitEditBanner = async (e) => { 
    const formData = new FormData(e.target)
    const data = {}
    e.preventDefault()
    for (let entry of formData.entries()) {
        data[entry[0]] = entry[1]
    } 

    await toast.promise(
			dispatch(clubbedBuyingBannerEdit(data,defaultData.id)),
			{
				pending: 'Banner updating...',
				success: 'Successfully! Banner Updated',
				error: {
          render(){
            setBtnLoader(false)
            return 'Banner not updated'
          }
        }
			}
		)

    await e.target.reset()
    await setImageUrl(null)
    await formHandle(false)
  }

  return(
    <>
    {defaultData &&
      <div className="modal effect-flip-vertical show" id="modaldemo8" aria-modal="true" style={{display:"block"}}>
        <div className="modal-dialog modal-dialog-right" role="document">
          <form onSubmit={ (e) => {setBtnLoader(true); onSubmitEditBanner(e)}} className="modal-content modal-content-demo">
            <div className="modal-header">
              <h6 className="modal-title">Edit Banner</h6>
              <button aria-label="Close" onClick={() => formHandle(false)} className="close" data-dismiss="modal" type="button"><span aria-hidden="true">×</span></button>
            </div>
            <div className="modal-body">

            <div className="row">
              <div className="form-group col-md-6"> 
                <label className="main-content-label tx-11">Device</label> 
                <select name="device" defaultValue={defaultData.device} className="form-control">
                  <option value="web">Web</option>
                  <option value="mobile">Mobile</option>
                </select>
              </div>

              <div className="form-group col-md-6"> 
                <label className="main-content-label tx-11 title-right" data-title="Position in slider">Position <i className='bx bx-info-circle tooltip_custom font-size-15 text-primary'></i></label> 
                <select name="in_order" defaultValue={defaultData.in_order} className="form-control" required>
                  <option value="-">Select...</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10">10</option>
                </select>
              </div>

              <div className="form-group col-md-6"> 
                <label className="main-content-label tx-11">Status</label> 
                <select name="status" defaultValue={defaultData.status} className="form-control">
                  <option value="active">Active</option>
                  <option value="inactive">Inactive</option>
                </select>
              </div>

              <div className="form-group col-md-6"> 
                <label className="main-content-label tx-11">Element Type</label> 
                <select name="type" defaultValue={defaultData?.type} className="form-control">
                  <option value=''>Select elemet type..</option>
                  <option value="image">Image</option>
                  <option value="video">Video</option>
                </select>
              </div>
            </div>

              <div className="form-group"> 
                <label className="main-content-label tx-11">Link Page</label> 
                <div className="input-group">
                  <input type="text" name="link" className="form-control" defaultValue={defaultData.link} placeholder="URL"/>
                  <div className="input-group-append">
                    <div className="input-group-text" id="btnGroupAddon"><i className='bx bx-link' ></i></div>
                  </div>
                </div>
              </div>

               {/* IMAGE */}
               <div className="form-group"> 
                <label className="main-content-label tx-11">Image</label> 
                <input name="image" defaultValue={imageUrl} type="hidden" />
                <input className="form-control" type="file" onChange={(e)=> uploadF(e.target.files)} /> 
                {previmageUrl ? <><hr/> <img src={previmageUrl} alt="hashtag bazaar" className="rounded wd-100p wd-sm-200" /></> : ''}
              </div>

            </div>

            <div className="modal-footer">
              <div className="btn ripple btn-outline-primary" data-dismiss="modal" type="button" onClick={() => formHandle(false)}>Close</div>
              <button disabled={btnLoader} className="btn ripple btn-primary" type='submit'>
                {!btnLoader ? 'Save changes' : <span className="spinner-border spinner-border-sm mx-5"></span>}
              </button>
            </div>

          </form>
        </div>
      </div>
    }
    </>
  )
}

export default BannerEdit;