import React, {useEffect, useState} from 'react'
import { connect } from "react-redux"
import { useHistory } from "react-router-dom"
import MainLayout from '../../layouts/main.component'
import { getEmailList, deleteEmailTemplate } from '../../../actions/message-templates.action'
import { Link } from 'react-router-dom'
import DeleteConfirm from '../../../helpers/delete.helper'
import { EMAIL_BY_NAME } from '../../../actions/types'

const EmailList = ({capability,dispatch, emailList}) => {

  const history             = useHistory()
  const [search, setSearch] = useState('')
  document.title            = 'Email Templates List'
  useEffect(()              => dispatch(getEmailList()), [dispatch])

  //DELETE
	const [showDelete, setShowDelete]  = useState(false)
	const [deleteId, setDeleteId]      = useState()
	const [deleteName, setDeleteName]  = useState()

  return (
    <MainLayout>

      {/* BREADCRUMB */}             
      <nav className="breadcrumb mt-3 mb-0"> 
				<div className="cursor-pointer mr-4 text-primary" onClick={history.goBack}><i className='bx bxs-chevron-left'></i> Back</div>
        <div className="mr-auto page_title">Email Template</div>
				<div className="breadcrumb-item" onClick={()=> history.push('/') }>Dashboard</div>
				<div className="breadcrumb-item active">Email Template</div>
			</nav>

      {/* DELETE */}
      {showDelete && <DeleteConfirm  id={deleteId} title={deleteName} componentHandle={setShowDelete} dispatch={dispatch} deleteItem={deleteEmailTemplate}/>}

      {/* HEADER */}
      <div className="d-flex justify-content-between align-items-center">
        <div className="h4 mt-4 mb-3 center">Email Template <span className="badge badge-primary ml-2">{emailList ? emailList.length : 0}</span></div>
        <div className="d-flex align-items-center">
          <button className="btn btn-white border-5 mr-2 title-top" data-title='Refresh' onClick={()=> window.location.reload()}><i className="bx bx-refresh pt-1 font-size-16"></i></button>
          <div className="form-group w-100 mb-0"> 
            <div className="input-icon"> 
              <span className="input-icon-addon"> <i className="fe fe-search" /> </span> 
              <input type="search" onChange={e => setSearch(e.target.value.toLowerCase())} className="form-control ht-40" placeholder="Search" /> 
            </div> 
          </div>   
          {capability?.email_template.add    && <Link to={'/message-template/email/addnew'} className="text-nowrap ml-2"><button className="btn btn-primary"> + Add Template</button></Link> }
        </div>
      </div>

      {/* LIST TABLE */}
      <div className='card'>
        <div className='card-body'>
          <div className='userlist-table'>

            {/* SEARCH */}
            {search && <div className="mb-2">Search <b className="text-warning">"{search}"</b></div>}
            <style>
              {`.category_table tbody tr:not([name${search.length ? `*="${search}"`: ''}]){
                  visibility:hidden;
                  position:absolute
              }`}
            </style>

            <table className="table card-table category_table table-bordered stable-vcenter text-nowrap mb-0 table-hover">
              <thead>
                <tr>
                  <th className='text-center'>#</th>
                  <th>Name</th>
                  <th>Subject</th>
                  {capability?.email_template.view || capability?.email_template.edit || capability?.email_template.delete ? <th>Action</th> :null}
                </tr>
              </thead>
              <tbody>
                { emailList ? emailList.map((emailData, index) => (
                    <tr key={index} className='cursor-pointer' name={emailData.name.toLowerCase() + emailData.subject.toLowerCase()} onClick={() => history.push('/message-template/email/view/'+emailData.name)}>
                      <td className='text-center'></td>
                      <td>{emailData.name}</td>
                      <td>{emailData.subject}</td>

                      {/* VIEW, EDIT & DELETE */}
                      {capability?.email_template.view || capability?.email_template.edit || capability?.email_template.delete ?
                      <td className='text-center' onClick={e => e.stopPropagation()}>
                        <div className="btn-group">
                          {capability?.email_template.view ? <button className="btn btn-sm btn-white" data-title="View" onClick={()=> history.push('/message-template/email/view/'+emailData.name)}><i className='las la-eye h4 m-0 text-secondary'></i></button> :null}
                          {capability?.email_template.edit ? <button className="btn btn-sm btn-white" data-title="Edit" onClick={()=> { dispatch({ type: EMAIL_BY_NAME, payload: null, }); history.push('/message-template/email/edit/'+emailData.name) }}><i className='bx bx-pencil h4 m-0 text-warning'></i></button> :null}
                          {capability?.email_template.delete ? <button className="btn btn-sm btn-white" data-title="Delete" onClick={()=> {setShowDelete(true); setDeleteId(emailData.id); setDeleteName(emailData.name)}}><i className='bx bx-trash h4 m-0 text-danger'></i></button> :null}
                        </div>
                      </td> :null}

                    </tr>
                )): <tr name=' ' className='pointer-event-none'>
                  <td colSpan="5" className="text-center count_ignore">
                    <div className="d-flex justify-content-center flex-column mt-5 mb-3 align-items-center">
                      <div>Wait <b>Buddy</b>...</div>
                      <div className="spinner-grow" role="status"/>
                    </div>
                    </td>
                  </tr>
                }
              
              </tbody>
            </table> 
          </div>
        </div>
      </div>
    </MainLayout>  
  )
}

function mapStateToProps(state) {
  const { message }           = state.message
  const { emailList }         = state.messageTemplate.messageTemplate
  let capability              = state.auth.currentUser?.capability
  capability                  = capability ? JSON.parse(capability) : null
  return {
    message,
    emailList,
    capability
  };
}

export default connect(mapStateToProps)(EmailList);