import React, {useEffect, useState} from 'react'
import { connect } from "react-redux"
import { useHistory } from "react-router-dom"
import MainLayout from '../../layouts/main.component'
import { clubbedBuyingSocietyList,clubbedBuyingSocietyById, clubbedBuyingAddSociety, clubbedBuyingDeleteSociety } from '../../../actions/clubbed-buying.action'
import ClubbedBuyingSocietyAdd from './society-add.component'
import { toast } from 'react-toastify'
import ClubbedBuyingSocietyEdit from './society-edit.component'
import 'react-toastify/dist/ReactToastify.css' 
import DeleteConfirm from '../../../helpers/delete.helper'

const ClubbedBuyingSocietyList = ({ dispatch, societyList, societyById, capability }) => {

  const history = useHistory()
  const [editSocietyForm, setEditSocietyForm] = useState(false)
  const [search, setSearch] = useState('')

  
  useEffect(()=>{
    dispatch(clubbedBuyingSocietyList());
    document.title = 'Society | Clubbed Buying ';
  }, [dispatch])

  
  //---  ADD SOCIETY ----
  const onSubmitAddSociety = async (e) => { 
    const formData = new FormData(e.target)
    const data = {}
    e.preventDefault()
    for (let entry of formData.entries()) {
        data[entry[0]] = entry[1]
    } 

    const submitData = {
      name: data.name,
      status:data.status,
      address: {
        line1: data.line1,
        line2: data.line2,
        city: data.city,
        state: "Uttar Pradesh",
        pincode: data.pincode,
        country: "India"
      }
    }

    await toast.promise(
			dispatch(clubbedBuyingAddSociety(submitData)),
			{
				pending: 'Society adding...',
				success: 'Successfully! Society Added',
				error: 'Society not added'
			}
		)

    e.target.reset();
  }


  //DELETE
  const [showDelete, setShowDelete]  = useState(false)
  const [deleteId, setDeleteId]      = useState()
  const [deleteName, setDeleteName]  = useState()


  // --- EDIT SOCIETY ---
  const editSociety = async (id) =>{
    await dispatch(clubbedBuyingSocietyById(id));
    await setEditSocietyForm(true);
  }

  return (
    <MainLayout>


      {/* EDIT */}
      {editSocietyForm && <ClubbedBuyingSocietyEdit defaultData={societyById} dispatch={dispatch} formHandle={setEditSocietyForm}/>}


      {/* DELETE */}
      {showDelete && <DeleteConfirm  id={deleteId} title={deleteName} componentHandle={setShowDelete} dispatch={dispatch} deleteItem={clubbedBuyingDeleteSociety}/>}


      {/* BREADCRUMB */}
      <nav className="breadcrumb mt-3 mb-0"> 
				<div className="cursor-pointer mr-4 text-primary" data-title='Refresh' onClick={history.goBack}><i className='bx bxs-chevron-left'></i> Back</div>
        <div className="mr-auto page_title">Society List</div>
				<div className="breadcrumb-item" onClick={()=> history.push('/') }>Dashboard</div>
				<div className="breadcrumb-item active">Society</div>
			</nav>


      {/* Header */}
      <div className="d-flex justify-content-between align-items-center">
        <div className="h4 mt-4 mb-3">Society<span className="badge badge-primary ml-2">{societyList?.length}</span></div>
        <div className='d-flex align-items-center'>
          <button className="btn btn-white border-5 mr-2 title-top" data-title='Refresh' onClick={()=> window.location.reload()}><i className="bx bx-refresh pt-1 font-size-16"></i></button>  
          <div className="form-group w-100 mb-0"> 
            <div className="input-icon"> 
              <span className="input-icon-addon"> <i className="fe fe-search" /> </span> 
              <input type="search" onChange={e => setSearch(e.target.value.toLowerCase())} className="form-control ht-40" placeholder="Search society" />
            </div> 
          </div> 
        </div>
      </div>


      <div className="row">
        {/* ADD CATEGORY */}
        {capability?.society.add ? 
        <div className="col-md-5">
          <div className="card card-body stick-top">
            <h4 className="font-weight-500 font-size-16 mb-4 text-secondary">Add New Society</h4>
            <form onSubmit={ (e) => onSubmitAddSociety(e)}>   
              <ClubbedBuyingSocietyAdd society={societyList}/>                              
            </form>  
          </div>
        </div> :null}


        {/* CATEGORY LIST */}
        <div className={`${capability?.society.add ? 'col-md-7' : 'col-md-12'}`}>
        
          <style>{/* SEARCH */}
            {`.category_table tbody tr:not([name${search.length ? `*="${search}"`: ''}]){
                visibility:hidden;
                position:absolute
            }`}
          </style>

          <div className="card card-body userlist-table">
            {search && <div className="mb-2">Search <b className="text-warning">"{search}"</b></div>}

						<table className="table table-bordered category_table border-0 text-nowrap mb-0">
              <thead>
                <tr>
                  <th className='text-center'>#</th>
                  <th><span>Name</span></th>
                  <th>Address</th>
                  {capability?.society.edit | capability?.society.delete ? <th>Action</th> :null}
                </tr>
              </thead>
              <tbody>
                {societyList && societyList.length == 0 ?
                  <tr><td className="text-center" colSpan="100">No Data Available</td></tr> 
                :
                  <>
                    {/* ----- SOCIETY */}
                    {societyList && societyList.map((socData,index) => (
                      <Society capability={capability} setShowDelete={setShowDelete}  setDeleteId={setDeleteId} setDeleteName={setDeleteName} key={index} socData={socData} editSociety={editSociety}/>
                    ))}
                  </>
                }
              </tbody>
            </table>

            {/* LOADER */}
            {!societyList && 
            <div className="d-flex justify-content-center flex-column mt-4 mb-3 align-items-center">
              <div>Wait <b>Buddy</b>...</div>
              <div className="spinner-grow" role="status"/>
            </div>}

          </div>
        </div>
      </div>
    </MainLayout>
  )
}

const Society = ({ socData, editSociety, setShowDelete,  setDeleteId, setDeleteName, capability}) => {

  return ( 
    <tr key={socData.id} name={socData.name.toLowerCase()}>
      <td className="text-center"></td>
      <td>
        <div>{ socData.name }</div>
        {socData.status === 'pending' ? <span className="badge badge-warning">Pending</span>:''}
        {socData.status === 'active' ? <span className="badge badge-success">Active</span>:''} 
        {socData.status === 'inactive' ? <span className="badge badge-secondary">InActive</span>:''}
        {socData.status === 'banned' ? <span className="badge badge-danger">Banned</span>:''}
      </td>
      <td className="white-space-normal">
        {`${socData.address.line1}, 
          ${socData.address.line2 && socData.address.line2 +','}
          ${socData.address.city}, 
          ${socData.address.state} -  
          ${socData.address.pincode}, 
          ${socData.address.country}, 
        `}
      </td>
      {capability?.society.edit || capability?.society.delete ? 
      <td>
        <div className="btn-group align-top center">
          {/* EDIT & DELETE */}
          {capability?.society.edit ? <button className="btn btn-sm btn-white" data-title="Edit Society" onClick={()=> editSociety(socData.id)}><i className='bx bx-pencil h4 m-0 text-primary'></i></button> : null}
          {capability?.society.delete ? <button className="btn btn-sm btn-white" data-title="Delete Society"  onClick={() => {setShowDelete(true); setDeleteId(socData.id); setDeleteName(socData.name);}}><i className='bx bx-trash h4 m-0 text-danger'></i></button> :null}
        </div>
      </td> :null}
    </tr>
  )
}



function mapStateToProps(state) {
  const { societyList }    = state.clubbedBuying.clubbedBuying
  const { societyById }    = state.clubbedBuying.clubbedBuying
  let capability           = state?.auth?.currentUser?.capability
  capability               = capability ? JSON.parse(capability) : null
  return {
    societyList,
    societyById,
    capability
  }
}

export default connect(mapStateToProps)(ClubbedBuyingSocietyList);