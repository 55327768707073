import React, {useEffect} from 'react'
import { connect } from "react-redux"
import { useHistory } from "react-router-dom"
import { deleteEmailTemplate, getEmailByName } from '../../../actions/message-templates.action'
import MainLayout from '../../layouts/main.component'
import { Link } from 'react-router-dom'


const ViewEmail = ({dispatch, messageTemplate, ...props}) => {
  const history = useHistory();

  useEffect(() => {
    document.title  = `View Email | ${messageTemplate?.emailByName?.name}`
  },[messageTemplate])
  
  useEffect(()=>{
    dispatch(getEmailByName(props.match.params.name))
    document.title = 'Email Templates';
  }, [dispatch])

  //---DELETE EMAIL----
  const delEmailTemplate = async (e) => {
    dispatch(deleteEmailTemplate(e));
    setTimeout(()=>{history.push('/message-template/email/list')},1000)
  }

  return (
    <MainLayout>

      {/* BREADCRUMB */}             
      <nav className="breadcrumb mt-3 mb-0"> 
				<div className="cursor-pointer mr-4 text-primary" onClick={history.goBack}><i className='bx bxs-chevron-left'></i> Back</div>
        <div className="mr-auto page_title">View Email Template</div>
				<div className="breadcrumb-item" onClick={()=> history.push('/') }>Dashboard</div>
				<div className="breadcrumb-item" onClick={()=> history.push('/message-template/email/list') }>Email Template</div>
				<div className="breadcrumb-item active">View</div>
			</nav>

      {/* HEADER */}
      <div className="d-flex justify-content-between align-items-center">
        <div className="h4 mt-4 mb-3">View Email Template</div>
        <div className="d-flex align-items-center">
          <Link to={'/message-template/email/edit/'+messageTemplate?.emailByName?.name} className="btn btn-warning"><i className='bx bx-pencil'></i> Edit</Link>  
          <Link to={'/message-template/email/addnew'} className="btn btn-primary mx-2"><i className='bx bx-plus'/> Add</Link>  
          <Link to={'/message-template/email/list'}  className="btn btn-secondary"><i className="fe fe-list" /> Go To List</Link> 
        </div>
      </div>

      {messageTemplate.emailByName ? 
        <div className="card  animated fadeIn p-3">
          <table className="table m-0">
            <tbody>
              <tr>
                <th className='text-wrap border-0'><span>Name</span></th>
                <td className='text-wrap border-0'>{messageTemplate.emailByName.name}</td>
              </tr>  
              <tr>
                <th className='text-wrap'><span>Subject</span></th>
                <td className='text-wrap'>{messageTemplate.emailByName.subject}</td>
              </tr>
              <tr>
                <th className='text-wrap'><span>Sender Name</span></th>
                <td className='text-wrap'>{messageTemplate.emailByName.sender_name}</td>
              </tr>
              <tr>
                <th className='text-wrap'><span>Sender Email</span></th>
                <td className='text-wrap'>{messageTemplate.emailByName.sender_email}</td>
              </tr>
              <tr>
                <th className='text-wrap'><span>Content</span></th>
                <td className='text-wrap'>
                  <div dangerouslySetInnerHTML={{__html: messageTemplate.emailByName.content}} />
                </td>
              </tr>
            </tbody>
          </table>  
        </div>
      :                                     
        <div className="d-flex justify-content-center flex-column mt-5 mb-3 align-items-center">
          <div>Wait <b>Buddy</b>...</div>
          <div className="spinner-grow" role="status"/>
        </div>     
      }  
    </MainLayout>
  )
}

function mapStateToProps(state) {
  const { messageTemplate }     = state.messageTemplate

  return {
    messageTemplate
  };
}

export default connect(mapStateToProps)(ViewEmail);