import React,{useEffect, useState, useRef} from "react"
import { toast } from 'react-toastify'
import { clubbedBuyEditOrder, regenerateInvoice, clubbedBuyDeleteSingleOrderProduct, getOrderById, logsList, newLog, deleteOrder} from "../../../actions/orders.action"
import DeleteConfirm from "../../../helpers/delete.helper"
import axios from "axios"
import { connect } from "react-redux"
import { paymentModeOptions, paymentStatusOptions, orderStatusOptions, forChangingPaymentStatusPaymentModeRequired } from "../../../helpers/order.helper"
import { Link } from "react-router-dom"
import moment from "moment"
import { CLEAR_ORDER_BY_ID } from "../../../actions/types"
import { convertToArray } from "../../../helpers/common-function.helper"
import Select from 'react-select'



const ClubbedBuyingEditOrder = ({dispatch, clubbedBuyLogsList, orderDetails, refreshList, formHandle, orderId, capability}) =>{
  const [addOrderItem, setAddOrderItem]                 = useState(false)
  const [editOrderItem, setEditOrderItem]               = useState(false)
  const [editOrderItemDetails, setEditOrderItemDetails] = useState()
  const [editItemNumber, setEditItemNumber]             = useState()
  const [editShippingAddress, setEditShippingAddress]   = useState(false)
  const [editBillingAddress, setEditBillingAddress]     = useState(false)
  const [deleteResponse, setDeleteResponse]             = useState(false)
  const [submitData, setSubmitData]                     = useState()
  const [confirmStatus, setConfirmStatus]               = useState(false)
  const [showAddLog, setShowAddLog]                     = useState(false)
  const [loader, setLoader]                             = useState(false)
  const [isUpatingDropdown, setIsUpdatingDropdown]      = useState(false)
  const [orderEditAllow, setOrderEditAllow]             = useState(false)
  const [advanceOptionAllow, setAdvanceOptionAllow]     = useState(false)
  const [deliveryCharge, setDeliveryCharge]             = useState()


  //ORDER LIST WHATSAPP MESSAGE
  const [orderListMessage, setOrderListMessage] = useState('')
  const [copyMessage, setCopyMessage]           = useState(false)
  useEffect(() => { //GENERATE MESSAGE
    if(!orderDetails) return
    setDeliveryCharge(parseFloat(orderDetails?.prices?.delivery_charge) < 1 ? 'no' : 'yes')
    setOrderListMessage(
`*📃 Order Product List:*
${orderDetails.items?.map(item => `
${item?.variation?.name}${'```'}
Brand   : ${item?.product?.brand||'n/a'}
Quantity: ${item?.quantity||'n/a'}${'```'}
`).join('')}`
    )
  },[orderDetails])

 
  useEffect(() => dispatch(logsList(orderId)),[])

  useEffect(() => {
    setSubmitData({
      "user_id"       : orderDetails?.user?._id,
      "society"       : orderDetails?.society?._id,
      "payment_mode"  : orderDetails?.payment_mode,
      "payment_status": orderDetails?.payment_status,
      "notify"        : 'no',
      "send_invoice"  : 'no',
      "status"        : orderDetails?.status,
      "notes"         : 'note not added on changes',
      "isDeliveryCharge" : orderDetails?.prices?.delivery_charge,
      "billing_address"  : {
          "name"                      : orderDetails?.billing_address?.name,
          "mobile_number"             : orderDetails?.billing_address?.mobile_number,
          "alternate_mobile_number"   : orderDetails?.billing_address?.alternate_mobile_number,
          "address"                   : orderDetails?.billing_address?.address,
          "locality"                  : orderDetails?.billing_address?.locality,
          "landmark"                  : orderDetails?.billing_address?.landmark,
          "city"                      : orderDetails?.billing_address?.city,
          "state"                     : orderDetails?.billing_address?.state, 
          "pincode"                   : orderDetails?.billing_address?.pincode
      },
      "shipping_address": {
        "name"                        : orderDetails?.shipping_address?.name,
        "mobile_number"               : orderDetails?.shipping_address?.mobile_number,
        "alternate_mobile_number"     : orderDetails?.shipping_address?.alternate_mobile_number,
        "address"                     : orderDetails?.shipping_address?.address,
        "locality"                    : orderDetails?.shipping_address?.locality,
        "landmark"                    : orderDetails?.shipping_address?.landmark,
        "city"                        : orderDetails?.shipping_address?.city,
        "state"                       : orderDetails?.shipping_address?.state, 
        "pincode"                     : orderDetails?.shipping_address?.pincode
      },
      "weeks_detail": orderDetails?.weeks_detail,    
      "items"       : []
    })

    setSubmitData(prevData => ({ ...prevData, items:[] }))

    orderDetails?.items?.map(itemData => {
      setSubmitData(prevData => ({
        ...prevData,
        items:[
          ...prevData?.items,
          {
            "product"   : itemData?.product?._id,
            "variation" : itemData?.variation?._id,
            "quantity"  : itemData?.quantity
          }
        ]
      }))
    })
    setOrderEditAllow(orderDetails?.status == 'cb-order-delivered' || orderDetails?.status == 'cb-order-cancelled' ? false : true)
  },[orderDetails])
  

  useEffect(() => {
    dispatch(getOrderById(orderId))
    if(deleteResponse){
      var noteMessage = `
          <b><i class='bx bx-trash' ></i> Product Deleted</b>
          <div class="note_old mb--10">
            <div><b>Product : </b> ${delteProduct?.variation?.name}</div> 
            <div><b>Qty :</b> ${delteProduct?.quantity}</div>
          </div>
        `

      var submitData = {
        "orderId" : orderId,
        "notes"   : noteMessage,
        "status"  : 'New Note Added by Admin Panel',
        "notify"  : 'no'
      }
      
      dispatch(newLog(submitData))
      refreshList()
    }
  },[deleteResponse])

  useEffect(async () => {
    if(orderDetails?.items?.length == 0){
      document.querySelector('#hideMe')?.classList?.add('fadeInDown')
      setTimeout(() => formHandle(false), 1000)
    }
  },[orderDetails])


  function closePopup(){
    document.querySelector('.modal-dialog')?.classList?.add('fadeInDown')
    setTimeout(() =>  formHandle(false),500)
    dispatch({
      type: CLEAR_ORDER_BY_ID
    })
  }

  //DELETE
  const [showDelete, setShowDelete]  = useState(false)
  const [deleteId, setDeleteId]      = useState()
  const [deleteName, setDeleteName]  = useState()
  const [delteProduct, setDeleteProduct]  = useState()


  //STATUS MANAGMENT
  const orderStatusRef   = useRef()
  const paymentStatusRef = useRef()
  const paymentModeRef   = useRef()


  //UPDATE DELIVERY CHARGE
  async function updateDeliveryCharge(){
    var finalData = submitData
    var noteMessage = `
      <b>Delivery Charge Updated</b>
      <div class="note_old">
        ₹${orderDetails?.prices?.delivery_charge}
      </div>
      <div class="note_new">
        ${deliveryCharge == 'yes' ? 'Request remove Delivery Charge' : 'Request apply Delivery Charge'}
      </div>
    `
    finalData.notes            = noteMessage
    finalData.isDeliveryCharge = deliveryCharge == 'yes' ? 'no' : 'yes'
      await toast.promise(
        dispatch(clubbedBuyEditOrder(orderId,finalData)),
        {
          pending: 'Updating delivery charge ...',
          success: 'Successfully! Delivery Updated',
          error: 'Not Updated'
        }
      )
      await dispatch(getOrderById(orderId))
      await setAdvanceOptionAllow(false)
      await dispatch(logsList(orderId))
      refreshList()
  }


  //UPDATE PAYMENT STATUS
  async function updatePaymentStatus(){
    setIsUpdatingDropdown(true)
    var finalData   = submitData
    var noteMessage = `
      <b>Payment Status Change</b>
      <div class="note_old">
        ${orderDetails?.payment_status}
      </div>
      <div class="note_new">
        ${paymentStatusRef.current.value}
      </div>
    `
    finalData.notes = noteMessage
    
    if(paymentStatusRef.current.value != orderDetails.payment_status){ //RUN WHEN PAYMENT STATUS CHANGE
      var checkLength = forChangingPaymentStatusPaymentModeRequired.filter(option => option == paymentModeRef.current.value).length
      if(paymentStatusRef.current.value == 'paid'){
        if(checkLength){
          var confirmIt = window.confirm(`✔️ Are you sure Change Payment Status to - "${paymentStatusRef.current.value}"`)
        }else{
          paymentStatusRef.current.value = orderDetails?.payment_status
          alert(`Buddy! 🙂 First Update Payment Mode, From Customer Made Payment : \n\n${JSON.stringify(forChangingPaymentStatusPaymentModeRequired).replaceAll(',',', ').replaceAll('[','').replaceAll(']','')}`)
        }
      }else{
        var confirmIt = window.confirm(`✔️ Are you sure Change Payment Status to - "${paymentStatusRef.current.value}"`)
      }
    }
    
    if(confirmIt){
      finalData.payment_status = paymentStatusRef.current.value
      await toast.promise(
        dispatch(clubbedBuyEditOrder(orderId,finalData)),
        {
          pending: 'Updating...',
          success: 'Successfully! Updated',
          error: 'Not Updated'
        }
      )
      await dispatch(getOrderById(orderId))
      await setAdvanceOptionAllow(false)
      await dispatch(logsList(orderId))
      refreshList()
    }else{
      paymentStatusRef.current.value = orderDetails?.payment_status
    }
    setIsUpdatingDropdown(false)
  }


  //UPDATE PAYMENT MODE
  async function updatePaymentMode(){
    setIsUpdatingDropdown(true)
    var finalData            = submitData
    finalData.payment_mode   = paymentModeRef.current.value
    
    if(paymentModeRef.current.value != orderDetails.payment_mode) { //RUN WHEN PAYMENT MODE CHANGE
      var confirmIt = window.confirm(`✔️ Are you sure Change Payment Mode to - "${paymentModeRef.current.value}"`)
      var checkOrder =  forChangingPaymentStatusPaymentModeRequired.filter(pMethod => paymentModeRef.current.value == pMethod)
      if(checkOrder.length){ 
        var noteMessage = `
          <b>Payment Mode Change</b>
          <div class="note_old">
            <div>Payment Mode: ${orderDetails?.payment_mode}</div>
            ${checkOrder == 'cod' ? '' : `<div>Payment Status: ${finalData.payment_status}</div>`}
            ${checkOrder == 'cod' ? '' : `<div><b>Note:</b> system change "Payment Status" automatically</div>`}
          </div>
          <div class="note_new">
            <div>Payment Mode: ${paymentModeRef.current.value}</div>
            ${checkOrder == 'cod' ? '' : `<div>Payment Status: paid</div>`}
          </div>
        `
        finalData.notes = noteMessage
        if(checkOrder != 'cod') finalData.payment_status = 'paid'
      }else{
        var noteMessage = `
          <b>Payment Mode Change</b>
          <div class="note_old">
            <div>Payment Mode: ${orderDetails?.payment_mode}</div>
          </div>
          <div class="note_new">
            <div>Payment Mode: ${paymentModeRef.current.value}</div>
          </div>
        `
        finalData.notes = noteMessage
      }
    }
    
    
    if(confirmIt){
      await toast.promise(
        dispatch(clubbedBuyEditOrder(orderId,finalData)),
        {
          pending: 'Updating...',
          success: 'Successfully! Updated',
          error: 'Not Updated'
        }
      )
      await dispatch(getOrderById(orderId))
      await setAdvanceOptionAllow(false)
      await dispatch(logsList(orderId))
      refreshOrder()
    }else{
      paymentModeRef.current.value   = orderDetails?.payment_mode
      paymentStatusRef.current.value = orderDetails?.payment_status
    }
    setIsUpdatingDropdown(false)
  }


  //SEND INVOICE TO CUSTOMER
  const sendInvoiceToCustomer = async() =>{
    setIsUpdatingDropdown(true)
    var finalData            = submitData
    finalData.send_invoice   = 'yes'
    var noteMessage = `
      <b class="border-bottom mb-2">Send Invoice</b>
      <div class="d-flex align-items-baseline"><i class='bx bxs-check-square text-success'></i> Sent Invoice To Customer</div>
    `
    finalData.notes = noteMessage

    await toast.promise(
      dispatch(clubbedBuyEditOrder(orderId,finalData)),
      {
        pending: 'Sending To Customer...',
        success: 'Successfully! Sent To Customer',
        error: 'Not Sent To Customer'
      }
    )
    await dispatch(getOrderById(orderId))
    await dispatch(logsList(orderId))
    refreshList()
    setIsUpdatingDropdown(false)
  }


  async function refreshOrder(){ //REFRESH ORDER
    document.querySelector('.modal-body').style.minHeight = `${document.querySelector('.modal-body').clientHeight}px`
    setLoader(true)
    setCopyMessage(false)
    await dispatch(getOrderById(orderId))
    await setLoader(false)
    document.querySelector('.modal-body').style.minHeight = `unset`
  }

  
  //DELETE ORDER
  const [deleteCompleteOrder, setDeleteCompleteOrder] = useState(false)
  const [checkOrderDeleted, setCheckOrderDeleted]     = useState(false)
  useEffect(async () => {
    if(!checkOrderDeleted) return
    await refreshList()
    await closePopup()
  },[checkOrderDeleted])


  //DOWNLOAD INVOICE
  function downloadInvoice(){
    toast.loading('Downloading Invoice...', {toastId:'loading'})
    var url      = orderDetails.invoice[0].attachment.path;
    var fileName = `${orderDetails?.user?.first_name || ''} ${orderDetails?.user?.last_name || ''} - ${orderDetails.invoice[0].attachment.name}`
    var req      = new XMLHttpRequest();
    req.open("GET", url, true);
    req.responseType = "blob";
    req.onload = function () {
        var blob = new Blob([req.response], { type: "application/octetstream" })
        var isIE = false || !!document.documentMode;
        if (isIE) {
            window.navigator.msSaveBlob(blob, fileName);
        } else {
            var url = window.URL || window.webkitURL;
            var link = url.createObjectURL(blob);
            var a = document.createElement("a");
            a.setAttribute("download", fileName);
            a.setAttribute("href", link);
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        }
    };
    req.send();
    toast.dismiss('loading')
    setTimeout(toast.success('Successfully! Invoice Downloaded'),500)
  }

  async function invoiceRegenerateF(){
    await toast.promise(
      dispatch(regenerateInvoice(orderDetails._id)),
      {
        pending: 'Regenerating Invoice...',
        success: 'Successfully! Invoce Regenerated',
        error: '👆 👆 👆'
      }
    )
    await toast.loading('Refreshing Order...', {toastId: 'refreshing'})
    await refreshOrder()
    await toast.dismiss('refreshing')
  }


  return(
    <>
      <div className="modal effect-flip-vertical show align-items-end" id="modaldemo8" aria-modal="true" style={{display:"flex"}}>
        <div className="overlayer" onClick={() => closePopup()}></div>
        <div className="modal-dialog modal-dialog-bottom" id="hideMe" role="document">

          {/* Delete Order */}
          {deleteCompleteOrder && <DeleteConfirm response={setDeleteResponse} id={orderDetails._id} other={orderDetails.items[0]._id} title={`#${orderDetails?.order_number}`} componentHandle={setDeleteCompleteOrder} dispatch={dispatch} deleteItem={deleteOrder}/>}


          <div className="modal-content modal-content-demo">
            <div className="modal-header pt-2 pb-2 shadow-base d-flex align-items-center position-relative z-index-100">
              <h6 className="modal-title my-1">Order Edit <span className="text-secondary font-weight-normal ml-2">#{orderDetails?.order_number}</span></h6>
              <div className="center">
                <div className="btn-group mr-2">
                  <button className='btn btn-white title-top p-0 font-size-17 py-2 center px-3' data-title="Refresh Order" onClick={refreshOrder}><i className="bx bx-refresh"></i></button>
                  {orderEditAllow && <button className="btn btn-white title-top p-0 font-size-17 py-2 center px-3" onClick={() => setDeleteCompleteOrder(true)} data-title="Delete Complete Order"><i className='bx bx-trash'></i></button> }
                  <button className="btn btn-white title-top p-0 font-size-17 py-2 center px-3" onClick={() => {navigator.clipboard.writeText(orderListMessage); setCopyMessage(true)}} data-title={copyMessage ? 'Message Copied' : 'Copy Order List'}><i className='bx bxl-whatsapp text-success'/></button>
                </div>
                <div className="btn-group mr-5">
                  <button disabled={orderDetails?.invoice != undefined && orderDetails?.invoice[0]?.attachment?.path ? false : true} onClick={downloadInvoice} className={`btn btn-primary ${orderDetails?.invoice != undefined && orderDetails?.invoice[0]?.attachment?.path ? '' : 'pointer-event-none'}`}><i className='bx bxs-download'></i> Download Invoice</button>
                  <button disabled={orderDetails?.invoice != undefined && orderDetails?.invoice[0]?.attachment?.path ? false : true} onClick={() => invoiceRegenerateF()} className={`btn btn-outline-primary ${orderDetails?.invoice != undefined && orderDetails?.invoice[0]?.attachment?.path ? '' : 'pointer-event-none'}`}><i className="las la-redo-alt"></i> Regenerate Invoice</button>
                  {capability?.clubbed_order.edit ? <button disabled={orderDetails?.invoice != undefined && orderDetails?.invoice != undefined && orderDetails?.invoice[0]?.attachment?.path ? false : true} className={`btn btn-outline-primary ${orderDetails?.invoice != undefined && orderDetails?.invoice[0]?.attachment?.path ? '' : 'pointer-event-none'}`} onClick={sendInvoiceToCustomer}><i className="lab la-telegram-plane mr-1"></i> Send Invoice to Customer</button> : null}
                </div>
                <button aria-label="Close" onClick={() => closePopup()} className="close z-index-100 shadow-sm" data-dismiss="modal" type="button"><span aria-hidden="true">×</span></button>
              </div>
            </div>
            <div className="modal-body d-flex pr-0 pt-0 pb-0">
              {orderDetails && !loader ? <>
                <div className="mt-3 mb-3 w-100 position-relative z-index-10">
                  <div className="d-flex">

                    <div className="col-md-8 pl-0">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group"> 
                            <div>
                              <label className="main-content-label tx-11">Order Status</label>
                              <label className="float-right badge badge-warning-transparent center cursor-pointer user-select-none title-left" data-title="🚫 Stop: Only for Emergency 🚨">
                                <input type="checkbox" className="mr-1" checked={advanceOptionAllow} onChange={e => setAdvanceOptionAllow(!advanceOptionAllow)}/> Advance Edit
                              </label>
                            </div> 
                            {orderStatusOptions.filter(option => option.value == orderDetails.status).length ?
                              <select ref={orderStatusRef} disabled={!capability?.clubbed_order.edit || isUpatingDropdown ? true : false} onChange={e => setConfirmStatus(true)} defaultValue={orderDetails.status} className="form-control text-capitalize">
                                {!advanceOptionAllow ?
                                  orderStatusOptions.filter(option => option.value == orderDetails.status)[0]?.order_status?.map((option,index) => {
                                    var optionData = orderStatusOptions.filter(opData => opData.value == option)
                                    if(optionData.length) return <option key={optionData[0].value+index+Math.random()}  value={optionData[0].value}>{optionData[0].name}</option>
                                  })
                                :
                                  orderStatusOptions.map((option,index) => (
                                    <option key={option.value+index+Math.random()} selected={orderDetails.status == option.value ? true: false}  value={option.value}>{option.name}</option>
                                  ))
                                }
                              </select>
                              : alert(`⚠️ Stop! Please Inform Tech Team That:\n\nThis Status is not Available "${orderDetails.status}"`)
                            }
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group"> 
                            <label className="main-content-label tx-11">Payment Status</label> 
                            {paymentStatusOptions.filter(options => options == orderDetails.payment_status).length ?
                              <select ref={paymentStatusRef} id="payment_status" onChange={updatePaymentStatus} disabled={!capability?.clubbed_order.edit || isUpatingDropdown ? true : false} defaultValue={orderDetails.payment_status} className="form-control text-capitalize">
                                <option value={orderDetails.payment_status}>{orderDetails.payment_status}</option>
                                {!advanceOptionAllow ?
                                  orderStatusOptions.filter(option => option.value == orderDetails.status)[0]?.payment_status?.map((option,index) => {
                                    if(orderDetails.payment_status != option) return <option key={option+index+Math.random()}  value={option}>{option}</option>
                                  })
                                :
                                  paymentStatusOptions?.map((option,index) => {
                                    if(orderDetails.payment_status != option) return <option key={index} value={option}>{option}</option>
                                  })
                                }
                              </select>
                              : alert(`⚠️ Stop! Please Inform Tech Team That:\n\nThis Payment Status is not Available "${orderDetails.payment_status}"`)
                            }
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group"> 
                            <label className="main-content-label tx-11">Payment Mode</label> 
                            {paymentModeOptions.filter(options => options == orderDetails.payment_mode).length ?
                              <select ref={paymentModeRef} onChange={updatePaymentMode} disabled={!capability?.clubbed_order.edit || isUpatingDropdown ? true : false} name="parent" defaultValue={orderDetails?.payment_mode} className="form-control text-capitalize">
                                <option value={orderDetails?.payment_mode}>{orderDetails?.payment_mode}</option>
                                {!advanceOptionAllow ?
                                  orderStatusOptions.filter(option => option.value == orderDetails.status)[0]?.payment_mode?.map((option,index) => {
                                    if(orderDetails.payment_mode != option) return <option key={option+index+Math.random()}  value={option}>{option}</option>
                                  })
                                :
                                  paymentModeOptions?.map((option,index) => {
                                    if(orderDetails.payment_mode != option) return <option key={index} value={option}>{option}</option>
                                  })
                                }
                              </select>
                              : alert(`⚠️ Stop! Please Inform Tech Team That:\n\nThis Payment Mode is not Available "${orderDetails.payment_mode}"`)
                            }
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <div className="card shadow-none p-3 border position-relative mb-4">
                            {capability?.clubbed_order.edit && orderEditAllow ? <i onClick={() => setEditShippingAddress(!editShippingAddress)} className={`bx bx-pencil close border font-size-18 center p-1 shadow-sm`}></i> : null}
                            <h3 className="font-size-14 text-muted text-monospace">Shipping Address:</h3>
                            <div><i className='bx bx-user font-size-16 mr-1'></i>{orderDetails?.shipping_address.name}</div>
                            <div><i className='bx bx-phone font-size-16 mr-1'></i>{orderDetails?.shipping_address.mobile_number}{orderDetails?.shipping_address.alternate_mobile_number != 'null' ? `, Alternate No. ${orderDetails?.shipping_address.alternate_mobile_number}`: null}</div>
                            <div className="d-flex align-items-baseline">
                              <i className='bx bxs-map font-size-16 mr-1'></i> 
                              <div>
                              {`${orderDetails?.shipping_address.locality},
                                ${orderDetails?.shipping_address.landmark ? orderDetails?.shipping_address.landmark + ',': ''}
                                ${orderDetails?.shipping_address.address},
                                ${orderDetails?.shipping_address.city},
                                ${orderDetails?.shipping_address.state} -
                                ${orderDetails?.shipping_address.pincode}
                              `}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">  
                          <div className="card shadow-none p-3 border position-relative mb-4">
                            {capability?.clubbed_order.edit && orderEditAllow ? <i onClick={() => setEditBillingAddress(!editBillingAddress)} className={`bx bx-pencil close border font-size-18 center p-1 shadow-sm`}></i> : null}
                            <h3 className="font-size-14 text-muted text-monospace">Billing Address:</h3>
                            <div><i className='bx bx-user font-size-16 mr-1'></i>{orderDetails?.billing_address.name}</div>
                            <div><i className='bx bx-phone font-size-16 mr-1'></i>{orderDetails?.billing_address.mobile_number}{orderDetails?.billing_address.alternate_mobile_number != 'null' ? `, Alternate No. ${orderDetails?.billing_address.alternate_mobile_number}`: null}</div>
                            <div className="d-flex align-items-baseline">
                              <i className='bx bxs-map font-size-16 mr-1'></i> 
                              <div>
                              {`${orderDetails?.billing_address.locality},
                                ${orderDetails?.billing_address.landmark ? orderDetails?.billing_address.landmark + ',': ''}
                                ${orderDetails?.billing_address.address},
                                ${orderDetails?.billing_address.city},
                                ${orderDetails?.billing_address.state} -
                                ${orderDetails?.billing_address.pincode}
                              `}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 pr-0">
                      <div className="card shadow-none pl-3 pt-2 pr-2  pb-3 border position-absolute t--0 l-15 b--0 r--0 overflow-auto">
                        <h3 className="font-size-14 text-muted text-monospace mb-0 mt-2">Order Info:</h3>
                        <div className="align-items-center d-flex"><i className='bx bx-calendar-plus font-size-16 mr-1'></i>Created:<div className="text-muted w-100 text-right">{moment(orderDetails?.createdAt).utcOffset('+00:00').format('lll')}</div></div>
                        <div className="align-items-center d-flex"><i className='bx bx-calendar-check font-size-16 mr-1'></i>Update:<div className="text-muted w-100 text-right">{moment(orderDetails?.updatedAt).utcOffset('+00:00').format('lll')}</div></div>
                        <div className="align-items-center d-flex"><i className='bx bx-receipt font-size-16 mr-1'></i>Invoice&nbsp;Number:<div className="text-muted w-100 text-right">{orderDetails?.invoice && orderDetails?.invoice?.length ? orderDetails?.invoice[0]?.number : 'n/a'}</div></div>
                        
                        {/* *************************
                              : Week Information :
                        **************************** */}
                        {/* <h3 className="font-size-14 text-muted text-monospace mb-0 mt-3">Week Info:</h3>
                        <div className="align-items-center d-flex"><i className='bx bx-calendar-check font-size-16 mr-1'></i>Start:<div className="text-muted w-100 text-right">{moment(orderDetails?.weeks_detail?.start_date).utcOffset('+00:00').format('lll')}</div></div>
                        <div className="align-items-center d-flex"><i className='bx bx-calendar-x font-size-16 mr-1'></i>End:<div className="text-muted w-100 text-right">{moment(orderDetails?.weeks_detail?.end_date).utcOffset('+00:00').format('lll')}</div></div>
                        <div className="align-items-center d-flex"><i className='bx bx-package font-size-16 mr-1'></i>Delivery&nbsp;Date:<div className="text-muted w-100 text-right">{moment(orderDetails?.weeks_detail?.delivery_date).utcOffset('+00:00').format('lll')}</div></div> */}
                        
                        <h3 className="font-size-14 text-muted text-monospace mb-0 mt-3">User Info:</h3>
                        <div className="align-items-center d-flex"><i className='bx bx-user font-size-16 mr-1'></i>{orderDetails?.user?.first_name} {orderDetails?.user?.last_name}</div>
                        <div className="align-items-center d-flex"><i className='bx bx-mobile-alt font-size-16 mr-1'></i>{orderDetails?.user?.mobile_number}</div>
                        <div className="align-items-center d-flex"><i className='bx bx-buildings font-size-16 mr-1'></i>{orderDetails?.society?.name}</div>
                        <div className="align-items-center d-flex"><i className='bx bx-envelope font-size-16 mr-1'></i>{orderDetails?.user?.email}</div>
                        <div className="align-items-center d-flex"><i className='bx bx-id-card font-size-16 mr-1'></i>{orderDetails?.user?.user_number}</div>
                      </div>
                    </div>
                  </div>

                  <div className="userlist-table order-table">
                    <div className="order-table-helper"></div>
                    <table className="table card-table table-hover mt-2 category_table table-bordered stable-vcenter text-nowrap mb-0 item_table">
                      <thead>
                        <tr>
                          <th className='text-center'>#</th>
                          <th>Product</th>
                          <th className='text-center'><span>Unit</span>Price</th>
                          <th>Qty</th>   
                          <th className='text-center'><span>Net</span>Amount</th>   
                          <th className='text-center'><span>Tax</span>Rate %</th>
                          <th className='text-center'><span>Tax</span>Type</th>
                          <th className='text-center'><span>Tax</span>Amount</th>
                          <th className='text-center'><span>Total</span>Amount</th>
                          <th className='text-center'><span>Total</span>MRP</th>
                          <th className='text-center bg-teal-transparent'><span>Your</span>Saving</th>  
                          {capability?.clubbed_order.edit && orderEditAllow || capability?.clubbed_order.delete && orderEditAllow ? <th className='text-center'>Action</th> :null}  
                        </tr>
                      </thead>
                      <tbody>
                        {orderDetails?.items?.map((item,index) => (
                          <Item number={index} orderEditAllow={orderEditAllow} capability={capability} setDeleteProduct={setDeleteProduct} setEditItemNumber={setEditItemNumber} orderDetails={orderDetails} setShowDelete={setShowDelete} setEditOrderItem={setEditOrderItem} setEditOrderItemDetails={setEditOrderItemDetails}  setDeleteId={setDeleteId} setDeleteName={setDeleteName} key={index} item={item} last={orderDetails?.items.length -1 == index ? true: false}/>
                        ))}
                        <tr name=" ">
                          <td className="count_ignore" colSpan={7}>
                            {capability?.clubbed_order.edit && orderEditAllow ? <button className="btn btn-light" type="button" onClick={() => setAddOrderItem(true)}>+ Add Product</button> : null}
                          </td>
                          <td className="font-weight-600">Rounding Off</td>
                          <td className="text-right">{orderDetails?.prices.rounding_off}</td>
                          <td className="bg-light" colSpan={3}></td>
                        </tr>
                        <tr name=" ">
                          <td className="count_ignore" colSpan={7}></td>
                          <td className="font-weight-600">
                            Delivery Charge
                            {parseFloat(orderDetails?.prices?.delivery_charge) > 0 && 
                              <label className="badge badge-success-transparent center cursor-pointer user-select-none">
                                <input type="checkbox" onChange={updateDeliveryCharge} defaultChecked={deliveryCharge == 'no' ? true : false} className="mr-1"/> Free Delivery
                              </label>
                            }
                          </td>
                          <td className="text-right">{orderDetails?.prices?.delivery_charge}</td>
                          <td className="bg-light" colSpan={3}></td>
                        </tr>
                        <tr name=" ">
                          <td className="count_ignore" colSpan={7}></td>
                          <td className="font-weight-600">Total</td>
                          <td className="text-right">₹{orderDetails?.prices.total_amount}</td>
                          <td className="text-right">₹{orderDetails?.prices.total_mrp_amount}</td>
                          <td className="text-right">₹{orderDetails?.prices.saving_amount}</td>
                        </tr>
                      </tbody>
                    </table>
                    <div className="pb-4"/>
                  </div>
                </div>

                {/* NOTES */}
                <div className="note_box border-left d-flex flex-column">
                  <div className="d-flex align-items-center border-bottom justify-content-between p-2 m-0">
                    <span className="font-size-15 font-weight-600 my-1">Order Notes</span>
                    {capability?.clubbed_order.edit ? <button className="btn font-size-13 btn-outline-primary p-1 pl-2 pr-2" onClick={() => setShowAddLog(true)}>+ Add Note</button> :null}
                  </div>
                  <div className={`note_body ${!clubbedBuyLogsList?.length ? 'center' : ''}`}>
                    {clubbedBuyLogsList?.length > 0 ? 
                      clubbedBuyLogsList?.map((note,i) => (
                        <div key={i} className="note_item">
                          <div className="note_message text-secondary border" dangerouslySetInnerHTML={{__html:note?.notes}}></div>
                          <div className="note_item_footer text-black-50 d-flex align-items-center mt-1">
                            <i className='bx bxs-user-circle mr-1 font-size-21'></i>
                            <div className="text-left d-flex flex-column text-monospace">
                              <span>{note?.updatedBy?.first_name} { note?.updatedBy?.last_name} </span>
                              <span className="text-monospace text-uppercase line-height-11">{moment(note?.createdAt).utcOffset('+00:00').format('lll')}</span>
                            </div>
                          </div>
                        </div>
                      ))
                      :
                      <div className="text-center p-3 bg-white shadow-sm rounded-10">
                        😇 Zero Note
                      </div>
                    }
                  </div>
                </div>
              </>:
                <div className="d-flex mx-auto justify-content-center flex-column mt-4 mb-3 align-items-center">
                  <div>Wait <b>Buddy</b>...</div>
                  <div className="spinner-grow" role="status"/>
                </div>
              }

            </div>
          </div>
        </div>
      </div>

      {/* DELETE */}
      {showDelete && <DeleteConfirm response={setDeleteResponse} id={orderId} other={deleteId} title={deleteName} componentHandle={setShowDelete} dispatch={dispatch} deleteItem={clubbedBuyDeleteSingleOrderProduct}/>}

      {/* EDIT SHIPPING ADDRESS */}
      {editShippingAddress && <EditShippingAddress dispatch={dispatch} orderId={orderId} submitData={submitData} orderDetails={orderDetails} handleComponent={setEditShippingAddress}/>}

      {/* EDIT BILLING ADDRESS */}
      {editBillingAddress && <EditBillingAddress dispatch={dispatch} orderId={orderId} submitData={submitData} orderDetails={orderDetails} handleComponent={setEditBillingAddress}/>}

      {/* ADD ORDER ITEM */}
      {addOrderItem && <AddOrderItem refreshList={refreshList} dispatch={dispatch} orderId={orderId} submitData={submitData} handleComponent={setAddOrderItem}/>}

      {/* EDIT ORDER ITEM */}
      {editOrderItem && <EditOrderItem refreshList={refreshList} dispatch={dispatch} orderId={orderId} submitData={submitData} editItemNumber={editItemNumber} itemDetails={editOrderItemDetails} handleComponent={setEditOrderItem}/>}

      {/* CONFIRM UPDATE */}
      {confirmStatus && <UpdateStatusConfirm setAdvanceOptionAllow={setAdvanceOptionAllow} capability={capability} orderStatusRef={orderStatusRef.current.value} refreshList={refreshList} dispatch={dispatch} orderId={orderId} orderDetails={orderDetails} submitData={submitData} handleComponent={setConfirmStatus}/>}

      {/* ADD LOG */}
      {showAddLog && <AddLog dispatch={dispatch} orderId={orderId} handleComponent={setShowAddLog}/>}
    </>
  )
}


/* -----------------
        ITEM
----------------- */
const Item = ({item, number,setEditItemNumber,capability, orderEditAllow, setDeleteProduct, last, setShowDelete, orderDetails, setEditOrderItemDetails, setEditOrderItem, setDeleteId, setDeleteName}) =>{

  return(
    <tr name=" "  className={`animated position-relative ${item?.variation?.name? '' : 'bg-warning-transparent'}`}>
      <td className="text-center"></td>
      <td className="text-wrap">
        <Link to={capability?.product?.view ? `/products/view/${item?.product?._id}` : '#'} target="_blank" className={`${!capability?.product?.view ? 'pointer-event-none' : ''} d-block`}>
          <div className={`align-items-center ${!item.variation?.name ? 'bg-warning-transparent-2':''} mb-2 last-mb-0 d-flex`}>
            <img 
              className="rounded avatar-md mr-1 border" 
              src={
                item.variation?.images?.filter(data => data.featured == 'yes').length ?
                item.variation?.images?.filter(data => data.featured == 'yes')[0].link:
                item.variation?.images[0]?.link
              }
            /> 
            <div className="text-left">
              <div className="font-weight-500 white-space-normal rounded">{item?.variation?.name ? item?.variation?.name : "Sorry! 😭 Product Not Available"}</div>
              <span className="badge badge-primary-transparent opacity-0-8"><i className="las la-truck-moving"></i> {moment(orderDetails.createdAt).add(parseInt(item?.variation?.logistics?.delivery), 'days').format('Do MMMM YYYY')}</span>
            </div>
          </div>
        </Link>
      </td>
      <td className="text-right">₹{item.prices.unit_price}</td>
      <td className="text-center">{item?.quantity}</td>
      <td className="text-right">₹{item.prices.net_amount}</td>
      <td className="text-center">{item?.variation?.tax.percentage}%</td>
      <td className="font-size-12">{item?.variation?.tax.type}</td>
      <td className="text-right">₹{item.prices.tax_amount}</td>
      <td className="text-right">₹{item.prices.total}</td>
      <td className="text-right">₹{item.prices.mrp}</td>
      <td className={`text-right bg-teal-transparent position-relative ${last ? '': 'border-white-bottom'}`}>
        ₹{parseInt(item?.prices.saving_amount)}
      </td>
      {capability?.clubbed_order.edit && orderEditAllow || capability?.clubbed_order.delete && orderEditAllow ?
        <td className="text-center">
          {capability?.clubbed_order.edit ? <button onClick={ () => { setEditOrderItemDetails(item); setEditItemNumber(number); setEditOrderItem(true)} } className={`title-left btn p-0 ${capability?.clubbed_order.delete ? 'border-right pr-2' : ''}`} data-title="Edit"><i className='bx bx-pencil m-0 text-warning font-size-17'></i></button> :null}
          {capability?.clubbed_order.delete ? <button onClick={ () => { setDeleteProduct(item); setShowDelete(true); setDeleteId(item._id); setDeleteName(orderDetails.items.length == 1 ? "Complete Order" :item?.variation?.name);}} className={`title-right btn p-0 ${capability?.clubbed_order.edit ? 'pl-2' : ''}`} data-title="Delete"><i className='bx bx-trash m-0 text-danger font-size-17'></i></button> :null}
        </td> 
      :null}
    </tr>
  )
}



/* -------------------------
    EDIT SHIPPING ADDRESS   
------------------------- */
const EditShippingAddress = ({orderDetails, handleComponent, orderId, submitData, dispatch}) => {
  const [loader, setLoader] = useState(false)


  async function onSubmitUpdateAddress(e){
    if(!loader){
      setLoader(true)

      const formData = new FormData(e.target)
      const data = {}
      e.preventDefault()
      for (let entry of formData.entries()) data[entry[0]] = entry[1]
      var finalData = submitData
      finalData.shipping_address = data
      var keys = window.Object.keys(data)
      
      var newAddress = []
      var oldAddress = []
      convertToArray(data).map((addressData,i) => {
        if(addressData != orderDetails.shipping_address[keys[i]]){
          newAddress.push(`<div><b>${keys[i]} :</b> ${addressData}</div>`)
          oldAddress.push(`<div><b>${keys[i]} :</b> ${orderDetails.shipping_address[keys[i]]}</div>`)
        }
      })

      var noteMessage = `
        <b>Shipping Address Changes</b>
        <div class="note_old">
          ${oldAddress.map(content => content).join(' ')}
        </div>
        <div class="note_new">
          ${newAddress.map(content => content).join(' ')}
        </div>
      `
      finalData.notes = noteMessage

      await toast.promise(
        dispatch(clubbedBuyEditOrder(orderId,finalData)),
        {
          pending: 'Updating...',
          success: 'Successfully! Updated',
          error: 'Not Updated'
        }
      )
      await dispatch(getOrderById(orderId))
      await dispatch(logsList(orderId))
      // await setLoader(false)
      await document.querySelector('#hideEdit')?.classList?.add('fadeOutDown')
      setTimeout(async () => await handleComponent(false),1000)
      document.querySelector(`.item_table tbody tr:nth-last-child(3)`)?.classList?.add('flash')
    }
  }


  return (
    <div className="modal effect-flip-vertical show align-items-end" id="modaldemo8" aria-modal="true" style={{display:"flex"}}>
        <div className="modal-dialog modal-dialog-centered animated fadeInUpBig" id="hideEdit" role="document">

          <form className="modal-content modal-content-demo" onSubmit={e => onSubmitUpdateAddress(e)}>
            <div className="modal-header d-flex align-items-center">
              <h6 className="modal-title">Edit Shipping Address</h6>
            </div>
            <div className="modal-body">
              <div className={`address_box animated`}>
                <div className="input_wrap w-100 ">
                  <input name="name" type="text" defaultValue={orderDetails?.shipping_address.name} placeholder=" "/>
                  <label>Name</label>
                </div>
                <div className="input_wrap w-100 mt-3">
                  <input name="mobile_number" type="number" defaultValue={orderDetails?.shipping_address.mobile_number} placeholder=" "/>
                  <label>Mobile Number</label>
                </div>
                <div className="input_wrap w-100 mt-3">
                  <input name="alternate_mobile_number" type="number" defaultValue={orderDetails?.shipping_address.alternate_mobile_number} placeholder=" "/>
                  <label>Alternate Mobile No.</label>
                </div>
                <div className="input_wrap w-100 mt-3">
                  <input name="locality" type="text" defaultValue={orderDetails?.shipping_address.locality} placeholder=" "/>
                  <label>Locality</label>
                </div>
                <div className="input_wrap w-100 mt-3">
                  <input name="landmark" type="text" defaultValue={orderDetails?.shipping_address.landmark} placeholder=" "/>
                  <label>Landmark</label>
                </div>
                <div className="input_wrap w-100 mt-3">
                  <input name="address" type="text" defaultValue={orderDetails?.shipping_address.address} placeholder=" "/>
                  <label>Address</label>
                </div>
                <div className="input_wrap w-100 mt-3">
                  <input name="city" type="text" defaultValue={orderDetails?.shipping_address.city} placeholder=" "/>
                  <label>City</label>
                </div>
                <div className="input_wrap w-100 mt-3">
                  <input name="state" type="text" defaultValue={orderDetails?.shipping_address.state} placeholder=" "/>
                  <label>State</label>
                </div>
                <div className="input_wrap w-100 mt-3">
                  <input name="pincode" type="number" defaultValue={orderDetails?.shipping_address.pincode} placeholder=" "/>
                  <label>Pincode</label>
                </div>
                <div className="input_wrap w-100 mt-3">
                  <input name="country" type="text" readOnly defaultValue={orderDetails?.shipping_address.country} placeholder=" "/>
                  <label>Country</label>
                </div>
              </div>
            </div>
            
            <div className="modal-footer">
              <div className="btn ripple btn-outline-primary" data-dismiss="modal" type="button" onClick={() => handleComponent(false)}>Close</div>
              <button disabled={loader} type="submit" className="btn ripple btn-primary" style={{minWidth:83}}>{loader ? <span className="spinner-border spinner-border-sm"></span>: 'Update'}</button>
            </div>
          </form>
        </div>
    </div>
  )
}



/* -------------------------
    EDIT Billing ADDRESS   
------------------------- */
const EditBillingAddress = ({orderDetails, handleComponent, orderId, submitData, dispatch, refreshList}) => {
  const [loader, setLoader] = useState(false)


  async function onSubmitUpdateAddress(e){
    if(!loader){
      setLoader(true)

      const formData = new FormData(e.target)
      const data = {}
      e.preventDefault()
      for (let entry of formData.entries()) data[entry[0]] = entry[1]

      var finalData = submitData
      finalData.billing_address = data
      var keys = window.Object.keys(data)
      
      var newAddress = []
      var oldAddress = []
      convertToArray(data).map((addressData,i) => {
        if(addressData != orderDetails.billing_address[keys[i]]){
          newAddress.push(`<div><b>${keys[i]} :</b> ${addressData}</div>`)
          oldAddress.push(`<div><b>${keys[i]} :</b> ${orderDetails.billing_address[keys[i]]}</div>`)
        }
      })

      var noteMessage = `
        <b>Billing Address Changes</b>
        <div class="note_old">
          ${oldAddress.map(content => content).join(' ')}
        </div>
        <div class="note_new">
          ${newAddress.map(content => content).join(' ')}
        </div>
      `
      finalData.notes = noteMessage
      await toast.promise(
        dispatch(clubbedBuyEditOrder(orderId,finalData)),
        {
          pending: 'Updating...',
          success: 'Successfully! Updated',
          error: 'Not Updated'
        }
      )
      await dispatch(getOrderById(orderId))
      await dispatch(logsList(orderId))
      // await setLoader(false)
      await document.querySelector('#hideEdit')?.classList?.add('fadeOutDown')
      setTimeout(async () => await handleComponent(false),1000)
    }
  }


  return (
    <div className="modal effect-flip-vertical show align-items-end" id="modaldemo8" aria-modal="true" style={{display:"flex"}}>
        <div className="modal-dialog modal-dialog-centered animated fadeInUpBig" id="hideEdit" role="document">

          <form className="modal-content modal-content-demo" onSubmit={e => onSubmitUpdateAddress(e)}>
            <div className="modal-header d-flex align-items-center">
              <h6 className="modal-title">Edit Billing Address</h6>
            </div>
            <div className="modal-body">
              <div className={`address_box animated`}>
                <div className="input_wrap w-100 ">
                  <input name="name" type="text" defaultValue={orderDetails?.billing_address.name} placeholder=" "/>
                  <label>Name</label>
                </div>
                <div className="input_wrap w-100 mt-3">
                  <input name="mobile_number" type="number" defaultValue={orderDetails?.billing_address.mobile_number} placeholder=" "/>
                  <label>Mobile Number</label>
                </div>
                <div className="input_wrap w-100 mt-3">
                  <input name="alternate_mobile_number" type="number" defaultValue={orderDetails?.billing_address.alternate_mobile_number} placeholder=" "/>
                  <label>Alternate Mobile No.</label>
                </div>
                <div className="input_wrap w-100 mt-3">
                  <input name="locality" type="text" defaultValue={orderDetails?.billing_address.locality} placeholder=" "/>
                  <label>Locality</label>
                </div>
                <div className="input_wrap w-100 mt-3">
                  <input name="landmark" type="text" defaultValue={orderDetails?.billing_address.landmark} placeholder=" "/>
                  <label>Landmark</label>
                </div>
                <div className="input_wrap w-100 mt-3">
                  <input name="address" type="text" defaultValue={orderDetails?.billing_address.address} placeholder=" "/>
                  <label>Address</label>
                </div>
                <div className="input_wrap w-100 mt-3">
                  <input name="city" type="text" defaultValue={orderDetails?.billing_address.city} placeholder=" "/>
                  <label>City</label>
                </div>
                <div className="input_wrap w-100 mt-3">
                  <input name="state" type="text" defaultValue={orderDetails?.billing_address.state} placeholder=" "/>
                  <label>State</label>
                </div>
                <div className="input_wrap w-100 mt-3">
                  <input name="pincode" type="number" defaultValue={orderDetails?.billing_address.pincode} placeholder=" "/>
                  <label>Pincode</label>
                </div>
                <div className="input_wrap w-100 mt-3">
                  <input name="country" type="text" readOnly defaultValue={orderDetails?.billing_address.country} placeholder=" "/>
                  <label>Country</label>
                </div>
              </div>
            </div>
            
            <div className="modal-footer">
              <div className="btn ripple btn-outline-primary" data-dismiss="modal" type="button" onClick={() => handleComponent(false)}>Close</div>
              <button type="submit" disabled={loader} className="btn ripple btn-primary" style={{minWidth:83}}>{loader ? <span className="spinner-border spinner-border-sm"></span>: 'Update'}</button>
            </div>
          </form>
        </div>
    </div>
  )
}


/* -------------------------
        ADD ORDER ITEM   
------------------------- */
const AddOrderItem = ({handleComponent,orderId, submitData, dispatch, refreshList}) => {
  const [quantity, setQuantity]         = useState(1)
  const [searchQuery, setSearchQuery]   = useState()
  const [searchResult, setSearchResult] = useState()
  const [showDropdown, setShowDropdown] = useState(false)
  const [loader, setLoader]             = useState(false)
  const [productData, setProductData]   = useState()
  const [productId, setProductId]       = useState()
  const [options, setOptions]           = useState([])
  const [searching, setSearching]       = useState(false)
  const [isExistingProduct, setIsExistingProduct] = useState(null)
  
  function chooseProduct(e){
    setProductData(e.value?.variation)
    setIsExistingProduct(null)
    submitData.items.map((item,i) => {
      if(item?.variation == e.value?.variation.id){
        setIsExistingProduct({
          name: item.name,
          quantity: item.quantity,
          number: i
        })
        setQuantity(item.quantity)
      }
    })
  }


  async function addItem(){
    if(!loader && productData){
      setLoader(true)
      var finalData = submitData

      if(isExistingProduct){
        var noteMessage = `
          <b>Product Updated</b>
          <div class="note_old">
            <div><b>Product : </b> ${productData?.name}</div>
            <div><b>Qty : </b> ${isExistingProduct.quantity}</div>
          </div>
          <div class="note_new">
            <div><b>Product : </b> ${productData?.name}</div>
            <div><b>Qty : </b> ${quantity}</div>
          </div>
        `
        finalData.notes = noteMessage
      }else{
        var noteMessage = `
          <b class="border-bottom mb-2">Product Added</b>
          <div><b>Product : </b> ${productData?.name}</div>
          <div><b>Qty : </b> ${quantity}</div>
        `
        finalData.notes = noteMessage
      }

      finalData.items.push({
        "product"   : productId,
        "variation" : productData.id,
        "quantity"  : quantity
      })
      
      await toast.promise(
        dispatch(clubbedBuyEditOrder(orderId,finalData)),
        {
          pending: 'Adding...',
          success: 'Successfully! Added',
          error: 'Not Added'
        }
      )
      await dispatch(getOrderById(orderId))
      await dispatch(logsList(orderId))
      await document.querySelector('#hideAdd')?.classList?.add('fadeOutDown')
      refreshList()
      setTimeout(async () => {
        await handleComponent(false)
        if(isExistingProduct){
          document.querySelector(`.item_table tbody tr:nth-child(${isExistingProduct.number + 1})`)?.classList?.remove('flash')
          setTimeout(() => document.querySelector(`.item_table tbody tr:nth-child(${isExistingProduct.number + 1})`)?.classList?.add('flash'), 200)
        }else{
          setTimeout(() => document.querySelector(`.item_table tbody tr:nth-last-child(3)`)?.classList?.add('flash'), 200)
        }
      },1000)
    }
  }

  useEffect(async ()=>{
    setSearching(true)
    if(searchQuery?.length) setSearchResult(await axios.get(`/products/search/${searchQuery}?status=published&byType=all`).then(res => res.data))
    await setSearching(false)
  },[searchQuery])


  useEffect(() => {
    if(searchResult?.length){
      var optionList = [];
      var optionData = searchResult?.map(option => (
        option.variation.map((resultData,i) => optionList.push({value: {id: option.id, variation: resultData}, label: resultData.name + `${resultData?.stock_status == 'available' ? '' : ` | Sold Out`}`, isDisabled: resultData?.stock_status == 'available' ? false : true}))
      ))
      setOptions(optionList)
      setOptions(optionList => optionList)
    }
  },[searchResult])

  
  return (
    <div className="modal effect-flip-vertical show align-items-end" id="modaldemo8" aria-modal="true" style={{display:"flex"}}>
        <div className="modal-dialog modal-dialog-centered animated fadeInUpBig" id="hideAdd" role="document">

          <div className="modal-content modal-content-demo">
            <div className="modal-header d-flex align-items-center">
              <h6 className="modal-title">Add Product</h6>
            </div>
            
            <div className="modal-body overflow-unset">
              <div className="position-relative">
                <div className={`address_box animated`}>
                  <Select
                    isMulti={false}
                    isLoading={searching}
                    closeMenuOnSelect={true}
                    onChange={e => {chooseProduct(e); setProductId(e.value.id)}}
                    onInputChange={e => setSearchQuery(e)}
                    autoFocus={true}
                    options={options}
                    className="basic-multi-select mb-3 react-select"
                    placeholder="Search Product..."
                  />
                </div>
              </div>

              {isExistingProduct && <div className="mt-3 alert-warning alert">Product Already Added In List <br/><b>Product: </b>{isExistingProduct.name}<br/><b>Quantity: </b>{isExistingProduct.quantity}</div>}

              <div className="input-group mb-3 d-flex mt-3">
                <button className="input-group-prepend cursor-pointer btn btn-white" onClick={() => setQuantity(quantity > 1 ? quantity - 1: 1)}>-</button>
                <div className="input_wrap text-center" style={{width:130}}>
                  <input name="quantity" readOnly type="number" min={1}  value={quantity} placeholder=" " className="rounded-0 text-center border-right-0"/>
                  <label>Quantity</label>
                </div>
                <button className="input-group-prepend cursor-pointer btn btn-white" onClick={() => setQuantity(quantity + 1)}>+</button>
              </div>
            </div>

            <div className="modal-footer">
              <div className="btn ripple btn-outline-primary" data-dismiss="modal" type="button" onClick={() => handleComponent(false)}>Close</div>
              <button disabled={loader} className="btn ripple btn-primary" style={{minWidth:83}} onClick={addItem}>{loader ? <span className="spinner-border spinner-border-sm"></span>: 'Add'}</button>
            </div>
          </div>
        </div>
    </div>
  )
}


/* -------------------------
       EDIT ORDER ITEM   
------------------------- */
const EditOrderItem = ({itemDetails, editItemNumber, orderId, submitData, handleComponent, dispatch, refreshList}) => {
  const [quantity, setQuantity] = useState(parseInt(itemDetails.quantity))
  const [loader, setLoader] = useState(false)

  async function editItem(){
    setLoader(true)
    var finalData = submitData
    var noteMessage = `
        <b>Product Updated</b>
        <div class="note_old">
          <div><b>Product : </b> ${itemDetails?.variation?.name}</div>
          <div><b>Qty : </b> ${finalData.items[editItemNumber].quantity}</div>
        </div>
        <div class="note_new">
          <div><b>Product : </b> ${itemDetails?.variation?.name}</div>
          <div><b>Qty : </b> ${quantity}</div>
        </div>
      `
    finalData.notes = noteMessage
    finalData.items[editItemNumber].quantity = quantity

    await toast.promise(
      dispatch(clubbedBuyEditOrder(orderId,finalData)),
      {
        pending: 'Updating...',
        success: 'Successfully! Updated',
        error: 'Not Updated'
      }
    )
    await dispatch(getOrderById(orderId))
    await dispatch(logsList(orderId))
    // await setLoader(false)
    await document.querySelector('#hideEdit')?.classList?.add('fadeOutDown')
    refreshList()
    setTimeout(async () => {
      await handleComponent(false)
      document.querySelector(`.item_table tbody tr:nth-child(${editItemNumber+1})`)?.classList?.remove('flash')
      document.querySelector(`.item_table tbody tr:nth-child(${editItemNumber+1})`)?.classList?.add('flash')
    },1000)
  }

  return (
    <div className="modal effect-flip-vertical show align-items-end" id="modaldemo8" aria-modal="true" style={{display:"flex"}}>
        <div className="modal-dialog modal-dialog-centered animated fadeInUpBig" id="hideEdit" role="document">

          <div className="modal-content modal-content-demo">
            <div className="modal-header d-flex align-items-center">
              <h6 className="modal-title overflow-hidden text-overflow-hidden" title={itemDetails?.variation?.name}>Edit <span className="text-secondary font-weight-500">"{itemDetails?.variation?.name}"</span></h6>
            </div>

            <div className="modal-body">
              <div className={`address_box animated`}>
                <div className="input-group mb-3 d-flex mt-3">
                  <button className="input-group-prepend cursor-pointer btn btn-white" onClick={() => setQuantity(quantity > 1 ? quantity - 1: 1)}>-</button>
                  <div className="input_wrap text-center" style={{width:130}}>
                    <input name="quantity" readOnly type="number" min={1} value={quantity} placeholder=" " className="rounded-0 text-center border-right-0"/>
                    <label>Quantity</label>
                  </div>
                  <button className="input-group-prepend cursor-pointer btn btn-white" onClick={() => setQuantity(quantity + 1)}>+</button>
                </div>
              </div>
            </div>
            
            <div className="modal-footer">
              <div className="btn ripple btn-outline-primary" data-dismiss="modal" type="button" onClick={() => handleComponent(false)}>Close</div>
              <button disabled={loader} className="btn ripple btn-primary" style={{minWidth:83}} onClick={editItem}>{loader ? <span className="spinner-border spinner-border-sm"></span>: 'Update'}</button>
            </div>
          </div>
        </div>
    </div>
  )
}


/* -------------------------
       Update Status   
------------------------- */
const UpdateStatusConfirm = ({orderDetails, orderStatusRef, capability, orderId, submitData, handleComponent, dispatch, refreshList, setAdvanceOptionAllow}) => {
  
  const [loader, setLoader] = useState(false)
  const notify              = useRef()
  const send_invoice        = useRef()

  async function updateStatus(){
    if(orderStatusRef == 'cb-order-delivered' && submitData.payment_status != 'paid' ) return alert(`Buddy! 🙂 First Update Payment Mode, From Customer Made Payment : \n\n${JSON.stringify(forChangingPaymentStatusPaymentModeRequired).replaceAll(',',', ').replaceAll('[','').replaceAll(']','')}`)
    if(orderStatusRef == 'cb-order-placed') return alert('Buddy! 😡 What are you doing???? \n\nOrder was already placed')
    setLoader(true)
    var noteMessage = `
        <b>Status Change</b>
        <div class="note_old">
          <div>${orderStatusOptions.filter(data => data.value == orderDetails?.status)[0].name}</div>
        </div>
        <div class="note_new">
          <div>${orderStatusOptions.filter(data => data.value == orderStatusRef)[0].name}</div>
        </div>
        <div class="other">
          ${notify.current.checked       ? `<div class="d-flex align-items-baseline"><i class='bx bxs-check-square text-success'></i> Send Notification to Customer</div>` : `<div class="d-flex align-items-baseline"><i class='bx bxs-x-square text-danger'></i> Send Notification to Customer</div>`}
          ${send_invoice.current.checked ? `<div class="d-flex align-items-baseline"><i class='bx bxs-check-square text-success'></i> Send Invoice to Customer</div>` : `<div class="d-flex align-items-baseline"><i class='bx bxs-x-square text-danger'></i> Send Invoice to Customer</div>`}
        </div>
      `

    var finalData            = submitData
    finalData.status         = orderStatusRef
    finalData.notify         = notify.current.checked ? 'yes' : 'no'
    finalData.send_invoice   = send_invoice.current.checked ? 'yes' : 'no'
    finalData.notes          = noteMessage
    await toast.promise(
			dispatch(clubbedBuyEditOrder(orderId,finalData)),
			{
				pending: 'Updating...',
				success: 'Successfully! Updated',
				error: 'Not Updated'
			}
		)
    await dispatch(getOrderById(orderId))
    await dispatch(logsList(orderId))
    // setLoader(false)
    await document.querySelector('#hideEdit')?.classList?.add('fadeOutDown')
    await setAdvanceOptionAllow(false)
    setTimeout(async () => await handleComponent(false),1000)
    refreshList()
  }

  return (
    <div className="modal effect-flip-vertical show align-items-end" id="modaldemo8" aria-modal="true" style={{display:"flex"}}>
        <div className="modal-dialog modal-dialog-centered animated fadeInUpBig" id="hideEdit" role="document">

          <div className="modal-content modal-content-demo">
            <div className="modal-header d-flex align-items-center">
              <h6 className="modal-title text-dark">Update Order Status</h6>
            </div>

            <div className="modal-body">
              <div className={`address_box animated`}>
                <div className="mb-2 font-size-15 font-italic font-weight-400 text-secondary">Change Status to ↴</div>
                {orderStatusOptions.map((options,i) => {
                  if(options.value == orderStatusRef) 
                    return <span key={i} className={`badge badge-1 
                      ${ orderStatusRef == "cb-order-placed" ? 'badge-warning-transparent' :null }
                      ${ orderStatusRef == "cb-order-confirmation-info-sent" ? 'badge-primary-transparent' :null }
                      ${ orderStatusRef == "cb-order-confirmed-online-payment" ? 'badge-purple-transparent' :null }
                      ${ orderStatusRef == "cb-order-confirmed-cod-payment" ? 'badge-purple-transparent' :null }
                      ${ orderStatusRef == "cb-order-expired" ? 'badge-danger-transparent' :null }
                      ${ orderStatusRef == "cb-order-out-for-delivery-prepaid" ? 'badge-teal-transparent' :null }
                      ${ orderStatusRef == "cb-order-out-for-delivery-cod" ? 'badge-teal-transparent' :null }
                      ${ orderStatusRef == "cb-order-delivered" ? 'badge-success' :null }
                      ${ orderStatusRef == "cb-order-cancelled" ? 'badge-danger' :null }
                    `}>
                      {orderStatusRef == "cb-order-placed" ? <i className='bx bx-shopping-bag mr-1'/>:null}
                      {orderStatusRef == "cb-order-confirmation-info-sent" ? <i className='bx bx-envelope mr-1'/>:null}
                      {orderStatusRef == "cb-order-confirmed-online-payment" ? <i className='bx bx-credit-card mr-1'/>:null}
                      {orderStatusRef == "cb-order-confirmed-cod-payment" ? <i className='bx bx-money mr-1'/>:null}
                      {orderStatusRef == "cb-order-expired" ? <i className='bx bx-calendar-x mr-1'/>:null}
                      {orderStatusRef == "cb-order-out-for-delivery-prepaid" ? <i className='bx bx-package mr-1'/>:null}
                      {orderStatusRef == "cb-order-out-for-delivery-cod" ? <i className='bx bx-package mr-1'/>:null}
                      {orderStatusRef == "cb-order-delivered" ? <i className='bx bxs-check-circle mr-1'/>:null}
                      {orderStatusRef == "cb-order-cancelled" ? <i className='bx bxs-x-circle mr-1'/>:null}
                    {options.name}</span>
                })}
              </div>

              <div className="d-flex mt-3 mb-0 shadow-sm border p-2 pl-3 pr-3 flex-column rounded-5">
                <label className="cursor-pointer d-flex align-items-center">
                  <input ref={notify} type="checkbox" className="mr-2"/>
                  <div className="d-flex align-items-center"><i className='bx bxs-bell-ring mr-2'></i> Send Notification to Customer</div>
                </label>
                <label className="cursor-pointer d-flex align-items-center">
                  <input ref={send_invoice} type="checkbox" className="mr-2"/>
                  <div className="d-flex align-items-center"><i className='bx bx-receipt mr-2'></i> Send Invoice to Customer</div>
                </label>
              </div>
            </div>
            
            <div className="modal-footer">
              <div className="btn ripple btn-outline-primary" data-dismiss="modal" type="button" onClick={() => handleComponent(false)}>Close</div>
              <button disabled={loader} className="btn ripple btn-primary" onClick={updateStatus} style={{minWidth:83}}>{loader ? <span className="spinner-border spinner-border-sm"></span>: 'Yes, Update'}</button>
            </div>
          </div>
        </div>
    </div>
  )
}


/* -------------------------
        ADD LOG   
------------------------- */
const AddLog = ({orderId, handleComponent, dispatch}) => {

  const [loader, setLoader] = useState(false)
  const noteRef             = useRef()
  const notify              = useRef()


  async function onSubmitAddLog(e){
    e.preventDefault()
    var noteMessage = `
        <b class="border-bottom mb-2">Note Added</b>
        ${noteRef.current.value}
        <div class="other mt-2">
          ${notify.current.checked ? `<div class="d-flex align-items-baseline"><i class='bx bxs-check-square text-success'></i> Send Notification to Customer</div>` : `<div class="d-flex align-items-baseline"><i class='bx bxs-x-square text-danger'></i> Send Notification to Customer</div>`}
        </div>
      `

    var data = {
      "orderId" : orderId,
      "notes"   : noteMessage,
      "status"  : 'New Note Added by Admin Panel',
      "notify"  : notify.current.checked ? 'yes' : 'no'
    }
    
    if(!noteRef.current.value?.length){
      alert('Buddy! 🙂 Please Add Note Before Submit.')
      return
    }
    
    setLoader(true)
    await toast.promise(
      dispatch(newLog(data)),
      {
        pending: 'Adding Note...',
        success: 'Successfully! Note Added',
        error: 'Not Added'
      }
    )
    // await setLoader(false)
    await document.querySelector('#hideEdit')?.classList?.add('fadeOutDown')
    setTimeout(async () => await handleComponent(false) ,1000)
  }


  return (
    <div className="modal effect-flip-vertical show align-items-end" id="modaldemo8" aria-modal="true" style={{display:"flex"}}>
        <div className="modal-dialog modal-dialog-centered animated fadeInUpBig" id="hideEdit" role="document">

          <form className="modal-content modal-content-demo" onSubmit={e => onSubmitAddLog(e)}>
            <div className="modal-header d-flex align-items-center">
              <h6 className="modal-title">Add Order Notes</h6>
            </div>

            <div className="modal-body pt-2 mt-1">
              <div className="address_box animated">
                <div className="form-group">
                  <label className="main-content-label tx-11">Note</label>
                  <textarea ref={noteRef} className="form-control" rows={4}></textarea>
                </div>

                <div className="d-flex mt-3 shadow-sm border p-2 pl-3 pr-3 flex-column rounded-5 disabled pointer-event-none">
                  <label className="cursor-pointer d-flex align-items-center mb-0">
                    <input ref={notify} type="checkbox" className="mr-2" disabled/>
                    <div className="d-flex align-items-center"><i className='bx bxs-bell-ring mr-2'></i> Send Notification to Customer</div>
                  </label>
                </div>
              </div>
            </div>
            
            <div className="modal-footer">
              <div className="btn ripple btn-outline-primary" data-dismiss="modal" type="button" onClick={() => handleComponent(false)}>Close</div>
              <button disabled={loader} className="btn ripple btn-primary" type="submit" style={{minWidth:83}}>{loader ? <span className="spinner-border spinner-border-sm"></span>: '+ Add Note'}</button>
            </div>
          </form>
        </div>
    </div>
  )
}


function mapStateToProps(state) {
  const orderDetails             = state.orders.clubbedBuyOrderById;
  const { clubbedBuyLogsList }   = state.orders;
  let capability                 = state?.auth?.currentUser?.capability
  capability                     = capability ? JSON.parse(capability) : null
  return {
    orderDetails,
    clubbedBuyLogsList,
    capability
  };
}

export default connect(mapStateToProps)(ClubbedBuyingEditOrder);