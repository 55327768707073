import React, {useState} from 'react';
import axios from "axios";

const ClubbedBuyingSocietyAdd = () => {
  return (
    <>
      <div className="input_wrap w-100 ">
        <input name="name" type="text" placeholder=" "/>
        <label>Society Name</label>
      </div>

      <div className="input_wrap w-100 mt-3">
        <input name="line1" type="text" placeholder=" "/>
        <label>Address Line 1</label>
      </div>

      <div className="input_wrap w-100 mt-3">
        <input name="line2" type="text" placeholder=" "/>
        <label>Address Line 2</label>
      </div>

      <div className="input_wrap w-100 mt-3">
        <input name="city" type="text" placeholder=" "/>
        <label>City/ District/ Town</label>
      </div>

      <div className="input_wrap w-100 mt-3">
        <input name="pincode" type="number" placeholder=" "/>
        <label>Pin Code</label>
      </div>

      <div className="input_wrap w-100 mt-3">
        <input name="state" disabled value={'Uttar Pradesh'} type="text" placeholder=" "/>
        <label>State</label>
      </div>

      <div className="form-group mt-3"> 
        <label className="main-content-label tx-11">Status</label> 
        <select name="status" className="form-control">
          <option value="active">Active</option>
          <option value="inactive">InActive</option>
        </select>
      </div>
      <button className="btn btn-main-primary pd-x-20 float-right" type="submit">+ Add Society</button>
    </>
  )
}

export default ClubbedBuyingSocietyAdd;
