import { toast } from "react-toastify"
import { useState } from "react"

export default function DeleteConfirm({id,title,other, componentHandle, dispatch, deleteItem, response, description}){

	const [loader, setLoader] = useState(false)
	const deleteProductHandle = async (id) =>{
		await toast.promise(
			dispatch(deleteItem(id,other)),
			{
				pending: 'Deleting...',
				success: 'Successfully! Deleted',
				error: {
					render(){
						setLoader(false)
						return 'Not Deleted'
					}
				}
			}
		)
		await componentHandle(null)
		await response && response(data => !data)
	}

	return(
		<div className="modal show" style={{display:'block'}}>
			<div className="overlayer" onClick={() => componentHandle(null)}></div>
			<div className="modal-dialog modal-dialog-centered animated zoomIn">
				<div className="modal-content tx-size-sm">
					<div className="modal-body simple-modal overflow-unset tx-center pd-y-20 pd-x-20">
						<i className="icon icon bx bx-trash tx-74 text-warning lh-1 d-inline-block"></i>
						<h4 className="tx-warning mg-b-20 text-overflow-hidden font-size-18">Delete <span className="text-secondary">"{title}"</span></h4>
						{ description && 
							<div className="bg-warning-transparent-2 rounded-5 text-left p-3 font-weight-500 mb-2">
								{description}
							</div>
						}
						<p className="mg-b-20 mg-x-20">Are you sure <b>Buddy</b>?</p>
						<button aria-label="Close" className="btn ripple btn-white m-1 pd-x-25" data-dismiss="modal" type="button" onClick={() => componentHandle(null)}>Cancel</button>
						<button disabled={loader} aria-label="Close" className="btn ripple m-1 btn-warning pd-x-25" data-dismiss="modal" type="button" onClick={() => {setLoader(true); deleteProductHandle(id)}}>
							{loader ? <span className="spinner-border spinner-border-sm mx-3"></span>: 'Delete'}
						</button>
					</div>
				</div>
			</div>
		</div>
	)
}
