import axios from "axios"


class OrderServices {
  url = '/admin/clubbed-buying/order';
  async editOrder(id,data){ return axios.put(`${this.url}/`+id, data).then(res => res.data) }
  async searchOrder(query){ return axios.get(`${this.url}/search/${query}`).then(res => res.data) }
  async newOrder(data){     return axios.post(`${this.url}/`, data).then(res => res.data) }
  async getOrderById(id){   return axios.get(`${this.url}/${id}`).then(res => res.data) }
  async newLog(data){       return axios.post(`${this.url}/log`, data).then(res => res.data) }
  async logsList(orderId){  return axios.get(`${this.url}/log/${orderId}`).then(res => res.data) }
  async deleteOrder(orderId,itemId){  return axios.delete(`${this.url}/${orderId}/${itemId}?type=all`).then( res => res.data )  }


  async clubbedBuyDeleteSingleOrderProduct(orderId,itemId){ //DELETE ORDER ITEM
    return axios.delete(`${this.url}/${orderId}/${itemId}`).then( res => res.data ) 
  }
  
  async orderList(page = 1, limit = 10, sortBy = 'desc'){ //ORDER LIST
    return axios.get(`${this.url}?start=${page}&limit=${limit}&sort=${sortBy}`).then(res => res.data) 
  }

  async regenerateInvoice(orderId){
    return axios.get(`admin/invoice/regenerate/${orderId}`).then(res => res.data)
  }
}


export default new OrderServices()