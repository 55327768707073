import React,{useEffect, useState} from 'react'
import MainLayout from '../../layouts/main.component'
import { useHistory } from 'react-router-dom'
import {connect} from 'react-redux'
import {attributesListShow, updateAttribute, addAttribute,deleteAttribute, getAttributeById} from '../../../actions/attributes.action'
import AttributeAdd from "./attribute-add.component"
import { toast } from 'react-toastify'
import AttributeEdit from './attribute-edit.component'
import DeleteConfirm from '../../../helpers/delete.helper'

const AttributesList = ({dispatch, attributesList, attributeById, capability}) => {

  const history                   =  useHistory() //ROUTER
	const [search, setSearch]       = useState('') //SEARCH ATTRIBUTES
	const [btnLoader, setBtnLoader] = useState()
	const [editAttributeForm, setEditAttributeForm] = useState(false)  //ATTRIBUTE EDIT 'show/hide'
  
	useEffect(() => { //ATTRIBUTES LIST SHOW
		document.title = "Attributes List"
		dispatch(attributesListShow())
	},[dispatch])

  //DELETE
  const [showDelete, setShowDelete]  = useState(false)
  const [deleteId, setDeleteId]      = useState()
  const [deleteName, setDeleteName]  = useState()

	// EDIT ATTRIBUTE
  const editAttribute = async (id) => {
    await dispatch(getAttributeById(id));
    await setEditAttributeForm(true);
  }

	// ADD ATTRIBUTE
	const onSubmitAddAttribute = async (e) => {             
		const formData = new FormData(e.target)
		const data = {}
		e.preventDefault()
		for (let entry of formData.entries()) {
				data[entry[0]] = entry[1]
		} 

		await toast.promise(
			dispatch(addAttribute(data)),
			{
				pending: 'Attribute adding...',
				success: 'Successfully! Attribute Added',
				error: {
					render(){
						setBtnLoader(false)
						return 'Attribute not added'
					}
				}
			}
		)
		setBtnLoader(false)
		e.target.reset()
	}

  return(
    <MainLayout>

			{/* EDIT ATTRIBUTE */}
      {editAttributeForm && <AttributeEdit attribute={attributeById} dispatch={dispatch} attributes={attributesList} formHandle={setEditAttributeForm}/>}


      {/* DELETE */}
      {showDelete && <DeleteConfirm  id={deleteId} title={deleteName} componentHandle={setShowDelete} dispatch={dispatch} deleteItem={deleteAttribute}/>}

			{/* BREADCRUMB */} 
			<nav className="breadcrumb mt-3 mb-0"> 
				<div className="cursor-pointer mr-4 text-primary" onClick={history.goBack}><i className='bx bxs-chevron-left'></i> Back</div>
        <div className="mr-auto page_title">Attributes List</div>
				<div className="breadcrumb-item" onClick={()=> history.push('/') }>Dashboard</div>
				<div className="breadcrumb-item active">Attributes</div>
			</nav>


			{/* Header */}
			<div className="d-flex justify-content-between align-items-center">
        <div className="h4 mt-4 mb-3">Attributes <span className="badge badge-primary ml-2">{attributesList?.length}</span></div>
        <div className='d-flex align-items-center'>
          <button className="btn btn-white border-5 mr-2 title-top" data-title='Refresh' onClick={()=> window.location.reload()}><i className="bx bx-refresh pt-1 font-size-16"></i></button>  
          <div className="form-group w-100 mb-0"> 
            <div className="input-icon"> 
              <span className="input-icon-addon"> <i className="fe fe-search" /> </span> 
              <input type="search" onChange={e => setSearch(e.target.value.toLowerCase())} className="form-control ht-40" placeholder="Search attributes" /> 
            </div> 
          </div> 
        </div>
      </div>
			
			<div className="row">
				{/* ADD ATTRIBUTE */}
				{capability?.attribute.add ? 
				<div className="col-md-5">
					<div className="card card-body stick-top">
					<h4 className="font-weight-500 font-size-16 mb-4 text-secondary">Add New Attribute</h4>
						<form onSubmit={ (e) => {setBtnLoader(true); onSubmitAddAttribute(e)}}>   
							<AttributeAdd setBtnLoader={setBtnLoader} btnLoader={btnLoader} attributes={attributesList}/>
						</form> 
					</div>
				</div> :null}

				{/* ATTRIBUTE LIST */}
				<div className={`${capability?.attribute.add ? 'col-md-7': 'col-md-12'}`}>

					<style>{/* ATTRIBUTE SEARCH */}
            {`.category_table tbody tr:not([name${search.length ? `*="${search}"`: ''}]){
                visibility:hidden;
                position:absolute
            }`}
          </style>

					<div className="card card-body userlist-table">
						{search && <div className="mb-2">Search <b className="text-warning">"{search}"</b></div>}

						<table className="table table-bordered category_table border-0 text-nowrap mb-0">
							<thead>
								<tr>
									<th>S.NO</th>
									<th>Name</th>
									{capability?.attribute.delete || capability?.attribute.edit ? <th className="text-center">Action</th> : null}
								</tr>
							</thead>
							<tbody>

								{/* ----- ATTRIBUTE */}
								{attributesList?.length == 0 && <tr><td className="text-center" colSpan="100">No Data Available</td></tr>}
								
								{attributesList?.filter(res => res.parent=='').map((catData,index) => (
									<Attribute setShowDelete={setShowDelete} capability={capability}  setDeleteId={setDeleteId} setDeleteName={setDeleteName} key={catData.id} catData={catData} attributesList={attributesList} editAttribute={editAttribute}/>
								))}

							</tbody>
						</table>

						{/* LOADER */}
            {!attributesList && 
            <div className="d-flex justify-content-center flex-column mt-4 mb-3 align-items-center">
              <div>Wait <b>Buddy</b>...</div>
              <div className="spinner-grow" role="status"/>
            </div>}

					</div>
				</div>
			</div>
    </MainLayout>
  )
}



/* -------------------------------
					 ATTRIBUTE
----------------------------------*/
const Attribute = ({ catData, attributesList, editAttribute, setShowDelete,  setDeleteId, setDeleteName, capability}) =>{

	return (
    <> 
    {catData.parent ? <tr></tr>: 
    <> 
		<tr name={catData.name.toLowerCase()}>
			{/* NAME */}
			<td className="text-center"></td>
			<td className=" left t_1 title-left" data-title={"Buddy! No Parent"}>{ catData.name }</td>
			{capability?.attribute.delete || capability?.attribute.edit ?
			<td  className="text-center">
				<div className="btn-group align-top">
					{/* EDIT & DELETE */}
					{capability?.attribute.edit  ? 
					<button className="btn btn-sm btn-white" data-title="Edit Attribute" onClick={() => editAttribute(catData.id)}>
						<i className='bx bx-pencil h4 m-0 text-primary'></i>
					</button> :null}

					{capability?.attribute.delete  ?
					<button className="btn btn-sm btn-white" data-title="Delete Attribute" onClick={() => {setShowDelete(true); setDeleteId(catData.id); setDeleteName(catData.name);}}>
						<i className='bx bx-trash h4 m-0 text-danger'></i>
					</button> :null}
				</div>
			</td> :null}
		</tr>      
    </>
      }
      <SubAttribute setShowDelete={setShowDelete} capability={capability}  setDeleteId={setDeleteId} setDeleteName={setDeleteName} editAttribute={editAttribute}  parent={catData.name} attributesList={attributesList} id={catData.id} ar={1}/> 
    </>
  )
}


/* -------------------------------
					SUB-ATTRIBUTE
----------------------------------*/
const SubAttribute = ({ attributesList, id , ar, editAttribute , parent, setShowDelete,  setDeleteId, setDeleteName, capability}) => {

  var arrowRows = [];
  for (var i = 1; i <= ar; i++) {
      arrowRows.push(<i className="ion-md-remove font-size-13"> </i> );
  }

  return (
    <React.Fragment key={id}>
      {attributesList && attributesList.filter(res=> res.parent===id).map( (catData2, index) => (
          <React.Fragment key={catData2.id}>
            <tr name={catData2.name.toLowerCase()}>
							<td className="text-center"></td>
							<td className="left t_1 title-left" data-title={'Parent: ' + parent} >{arrowRows} { catData2.name }</td>
							{capability?.attribute.delete || capability?.attribute.edit ?
              <td  className="text-center">
                <div className="btn-group align-top">
									{capability?.attribute.edit  ? 
									<button className="btn btn-sm btn-white" data-title="Edit Attribute" onClick={() => editAttribute(catData2.id)}>
										<i className='bx bx-pencil h4 m-0 text-primary'></i>
									</button> :null}

									{capability?.attribute.delete  ? 
									<button className="btn btn-sm btn-white" data-title="Delete Attribute" onClick={() => {setShowDelete(true); setDeleteId(catData2.id); setDeleteName(catData2.name);}}>
										<i className='bx bx-trash h4 m-0 text-danger'></i>
									</button> :null}
                </div>
              </td> :null}
            </tr>
            <SubAttribute setShowDelete={setShowDelete}  setDeleteId={setDeleteId} setDeleteName={setDeleteName} key={catData2.id + 1} editAttribute={editAttribute} attributesList={attributesList} parent={`${parent} -> ${catData2.name}`} id={catData2.id} ar={ar+1}/> 
          </React.Fragment>
        ))
      }
    </React.Fragment>
  )
}



function mapStateToProps(state) {
  const { message }       = state.message
	const {attributesList}  = state.attributes
	const {attributeById}  = state.attributes
	let capability   = state?.auth?.currentUser?.capability
  capability       = capability ? JSON.parse(capability) : null

  return {
    message,
		attributesList,
		attributeById,
		capability
  };
}

export default connect(mapStateToProps)(AttributesList)