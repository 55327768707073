import { useState, useEffect } from "react"
import { connect } from "react-redux"
import { productStatus } from "../../helpers/product.helper"
import { exportProduct } from "../../actions/product.action"
import exportFromJSON from 'export-from-json'
import { EXPORT_PRODUCT } from "../../actions/types"
import { categoryList } from "../../actions/category.action"
import moment from 'moment'


const ExportProduct = ({handlePopup, categories, exportProductList, dispatch}) => {
	const [loader, setLoader]           = useState(false)
  const [page, setPage]               = useState(1)
	const [limit, setLimit]             = useState(100)
	const [sortBy, setSortBy]           = useState('desc')
  const [status, setStatus]           = useState('all')
  const [exportData, setExportData]   = useState([])
  const [isExported, setIsExported]   = useState(false)
  const [totalPageShow, setTotalPageShow] = useState(0)

  function loadProductList(nextPage){
    setLoader(true); 
    dispatch(exportProduct(nextPage || page, limit, sortBy, status))
  }

  useEffect(async () => {
    if(!categories) await dispatch(categoryList())
    if(!exportProductList) return

    let formateList      = []
    let totalPage        = Math.ceil(exportProductList?.total / limit)
    setTotalPageShow(totalPage)
    
    exportProductList.products.map((pData, index) => (
      pData.variation.map((vData, i) => {

        let categoryList    = []
        let subCategoryList = []
        let leafList        = []
        let priceRange      = {}
        let finalData       = {}

        pData.categories.filter(cData => {
          categories.filter(category => {
            if(category.id == cData.category_id){
              switch(category.type){
                case 'category'    : return categoryList.push(category.name)
                case 'subcategory' : return subCategoryList.push(category.name)
                case 'leaf'        : return leafList.push(category.name)
                default            : return null
              }
            }
          })
        })

        finalData = {
          'S. No.'                 : exportData.length + formateList.length + 1,
          'Status'                 : pData.status,
          'Category'               : categoryList.join(','),
          'Sub-Category'           : subCategoryList.join(','),
          'Leaf'                   : leafList.join(','),
          'Product Name'           : vData.name,
          'SKU ID'                 : vData.sku_id,
          'Brand Name'             : pData.brand,
          'MRP (INR) of 1'         : vData.price.mrp,
          'Cost Price for #bazaar' : vData.price.purchase_price,
          'Final Listing Price'    : vData.price.listing_price,
          'GST Rate'               : vData.tax.percentage+'%',
          'HSN Code'               : pData.hsn_code,
          'Delivery Time'          : vData?.logistics?.delivery ? `${vData.logistics.delivery} Day` : '',
          'Product Link'           : `https://admin.hashtagbazaar.com/products/view/${pData.id}`,
        }

        vData.clubbed_buying.price_window?.map((pRange, pIndex) => {
          finalData[`Range - ${pIndex + 1}`] = pRange.range
          finalData[`Range - ${pIndex + 1} Price`] = pRange.price
        })

        formateList.push(finalData)
      }) 
    ))

    setExportData(data => data.concat(formateList))
    if(page + 1 <= totalPage){
      setPage(page => page + 1)
      await loadProductList(page + 1)
    }else{
      setLoader(false);
      setIsExported(true)
    }
  },[exportProductList])


  function exportToCSV(downloadType){
    const fileName   = `Product List - ${moment(Date.now()).utcOffset('+05:30').format('lll')}`
    const exportType = exportFromJSON.types[downloadType]
    let data         = exportData
    exportFromJSON({ data, fileName, exportType })
  }


  async function refreshComponent(close){
    loadProductList = null
    setIsExported(false)
    setPage(1)
    setLoader(false)
    setExportData([])
    await dispatch({
      type: EXPORT_PRODUCT,
      payload: null
    })
    loader && !isExported && window.location.reload()
    close && await handlePopup(false)
  }


  return (
    <>
    <div className="modal effect-flip-vertical show" id="modaldemo8" aria-modal="true" style={{display:"block"}}>
      <div className="overlayer" onClick={() => {refreshComponent(true)}}></div>
      <div className="modal-dialog modal-dialog-right" id="hideMe" role="document">

        <div className="modal-content modal-content-demo">
          <div className="modal-header px-3 py-2 shadow-base d-flex align-items-center position-relative z-index-100">
            <h6 className="modal-title py-1"><i className='bx bxs-file-export mr-1'></i> Export Products</h6>
          </div>
          <div className="modal-body d-flex pt-4 pb-4">
            <div className="w-100 d-flex flex-column justify-center">
              <div className="center w-100">
                <div className="mr-2">Export&nbsp;Product:</div>
                <select onChange={e => {refreshComponent(); setStatus(e.target.value)}} className='form-control text-capitalize w-100' style={{width:'115px'}}>
                  {productStatus.map((status,i) => {
                    return <option key={i} value={status.name}>{status.label}</option>
                  })}
                </select>
              </div>

              {!loader && !isExported &&
              <div className="card border mt-3 center px-3 py-5">
                <button onClick={() => loadProductList()} className={`btn btn-warning ${!categories ? 'pointer-event-none disabled' : null}`}> 
                  {loader ? <span className="spinner-border spinner-border-sm mr-1"></span> : <i className='bx bxs-download mr-1'></i>}
                  Export Product
                </button>
                <p className="mt-2 text-secondary">🙂 Export <b className="text-capitalize">{status}</b> Product</p>
              </div>}

              {loader && !isExported &&
                <div className="card border mt-3 px-3 py-5 animated-1s fadeIn">
                  <div className="text-center mb-1"><b>{exportData.length} Product Exported</b></div>
                  <div className="progress rounded-50">
                    <div className="progress-bar progress-bar-striped progress-bar-animated" style={{width: `${(page/ totalPageShow) * 100}%`, height:15}}></div>
                  </div>
                </div>
              } 

              {isExported &&
                <div className="card border mt-3 center px-3 py-5 animated-1s fadeIn">
                  <div className="btn-group">
                    <button onClick={() => exportToCSV('csv')} className="btn btn-outline-primary"> 
                      <i className='bx bxs-download mr-1'></i>
                      Download CSV
                    </button>
                    
                    <button onClick={() => exportToCSV('xls')} className="btn btn-primary"> 
                      <i className='bx bxs-download mr-1'></i>
                      Download XLS
                    </button>
                  </div>
                  <p className="mt-2 text-center">🙂 Now, you can download</p>
                </div>
              } 

            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  )
}


function mapStateToProps(state){
  const { exportProductList }   = state.products.products
  const { categories }    = state.categories
  return {
    exportProductList,
    categories
  }
}

export default connect(mapStateToProps)(ExportProduct)