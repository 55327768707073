import { useRef, useState, useEffect } from 'react'
import { connect } from 'react-redux'
import MainLayout from '../../layouts/main.component'
import { useHistory } from "react-router-dom"
import { centerInput } from '../../../helpers/common-function.helper'
import { customerSearch } from '../../../actions/users.action'
import Select from 'react-select'
import { toast } from 'react-toastify'
import axios from 'axios'
import { SET_MESSAGE } from '../../../actions/types'
import { sendNotification } from '../../../actions/message-templates.action'

//IMAGE - "FilePond"
import { FilePond, File, registerPlugin } from 'react-filepond'
import "filepond/dist/filepond.min.css"
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation"
import FilePondPluginImagePreview from "filepond-plugin-image-preview"
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css"
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview, FilePondPluginFileValidateType, FilePondPluginFileValidateSize)


const SendNotification = ({customerSearchResult, dispatch}) => {
  const history                                         = useHistory()
  const [sendTo, setSendTo]                             = useState('selected')
  const [imageUrl, setImageUrl]                         = useState([])
  const [btnLoader, setBtnLoader]                       = useState(false)
  const [searching, setSearching]                       = useState(false)
  const [searchCustomer, setSearchCustomer]             = useState('')
  const [customerSeachOption, setCustomerSearchOption]  = useState([])
  const linRef                                          = useRef()
  const [collectFcmToken, setCollectFcmToken]           = useState()

  useEffect(() => {
    var formateCustomerOptions = customerSearchResult?.map((option,index) => ({
      value: option,
      label: `${option.first_name} ${option.last_name} "${option.mobile_number}" ${option?.fcm_token ? '✔️' : '❌'}`
    }))
    setCustomerSearchOption(formateCustomerOptions)
  },[customerSearchResult])
  
  useEffect(async () =>{
    document.title = "Send Notification"
    if(!searchCustomer.length) return 
    setSearching(true)
    await dispatch(customerSearch(searchCustomer, 'customer'))
    await setSearching(false)
  }, [searchCustomer] )


  async function onSubmit(e){
    e.preventDefault()
    
    //IMAGE UPLOADING
    if(imageUrl?.length){
      toast.loading('Image Uploading ...', {toastId: 'imgUploading'})
      var imgLink;
      var imgFormData = new FormData()
      imgFormData.append('file', imageUrl[0].file)
      imgFormData.append('foldername', 'marketing/firebase-notification')
      await axios.post("/file/upload", imgFormData)      
      .then((response) => {
        imgLink = response.data
      }).catch(err =>{
        setBtnLoader(false)
        dispatch({ type:SET_MESSAGE, payload:err.response.data })
      })
      toast.dismiss('imgUploading')
    }

    const formData  = new FormData(e.target)
    const data      = {}
    const fcm_token = collectFcmToken?.map(token => token.value)?.filter(token => token?.fcm_token)?.map(token => token?.fcm_token)

    if(!fcm_token?.length) return toast.error('Add User')
    for (let entry of formData.entries()) data[entry[0]] = entry[1]

    data['fcm_token'] = fcm_token
    data['data']      = {link: linRef.current.value}
    data['for']       = 'null'
    imageUrl?.length ? data['imageUrl'] = imgLink : data['imageUrl'] = null 
    await toast.promise( dispatch(sendNotification(data)),
      {
        pending: `Notification Sending....`,
        success: `Notification Sent`,
        error:   {
          render(){
            setBtnLoader(false)
            return `👆👆👆👆👆👆`
          }
        }
      }
    )
    setBtnLoader(false)
    toast.loading('Refreshing...')
    setTimeout(() => window.location.reload(), 500)
  }


  return(
    <MainLayout>
      {/* BREADCRUMB */}             
      <nav className="breadcrumb mt-3 mb-0"> 
        <div className="cursor-pointer mr-4 text-primary" onClick={history.goBack}><i className='bx bxs-chevron-left'></i> Back</div>
        <div className="mr-auto page_title">Send Notification</div>
        <div className="breadcrumb-item" onClick={()=> history.push('/')}>Dashboard</div>
        <div className="breadcrumb-item active">Send Notification</div>
      </nav>


      {/* HEADER */}
      <div className="d-flex justify-content-between align-items-center">
        <div className="h4 mt-4 mb-3 d-flex align-items-center">
          Send Notification
          <div className='ml-2'>
            <select className='form-control ml-auto' onChange={e => setSendTo(e.target.value)}>
              <option value={'selected'}>Selected Customers</option>
              <option value={'all'} disabled>All Customers</option>
            </select>
          </div>
        </div>
        <button disabled={btnLoader} className="btn btn-primary" type="submit" onClick={() => document.querySelector('.submit_form').click()}>
          {!btnLoader ? <><i className="lab la-telegram-plane"></i> Send Notification</> : <span className="spinner-border spinner-border-sm mx-5"></span>}
        </button>
      </div>

      <div className='card card-body animated fadeInUp animated-1s flex-unset'>
        <form onSubmit={e => {setBtnLoader(true); onSubmit(e)}}>

          <div className="input_wrap w-100">
            <input onFocus={centerInput} autoFocus={true} name='title' type="text"  placeholder=" " required/>
            <label>Title</label>
          </div>

          <div className='mt-3 text-right font-size-13'><span className='mr-2'>Token Present: ✔️</span>  | <span className='ml-2'>Token Absent: ❌</span></div>
          <div className='position-relative z-index-10 mb-4'>
            <Select
              isMulti={true}
              isLoading={searching}
              closeMenuOnSelect={false}
              onInputChange={e => {setSearchCustomer(e);}}
              onChange={e => setCollectFcmToken(e)}
              options={customerSeachOption}
              className="basic-multi-select"
              placeholder="Search User..."
            />
          </div>

          <div className="select-box mt-3">
            <label>Body</label>
            <textarea rows={5} onFocus={centerInput} className="form-control" name='body' required/>
          </div>

          <div className="input-group mt-3 flex-nowrap">
            <div className="input-group-prepend">
              <span className="input-group-text"><i className='bx bx-link font-size-18'></i></span>
            </div>
            <div className="input_wrap w-100">
              <input onFocus={centerInput} ref={linRef} type="url"  placeholder=" "/>
              <label>Destination URL</label>
            </div>
          </div>

          <div className='mt-3'>
            <FilePond
              onupdatefiles={(e) => setImageUrl(e)}
              maxFiles={1}
              name='imageUrl'
              maxFileSize={'1MB'}
              acceptedFileTypes={['image/png', 'image/jpeg', 'image/jpg']}
              labelIdle='Drag & Drop Image or <span class="filepond--label-action">Browse</span>'
            />
          </div>
          <button type='submit' className='d-none submit_form'>dfasd</button>

        </form>
      </div>
    </MainLayout>
  )
}

function mapStateToProps(state) {
  let customerSearchResult       = state.users.users.customerSearch
  return {
    customerSearchResult
  };
}

export default connect(mapStateToProps)(SendNotification);