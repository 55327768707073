import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from "react-redux";
import store from "./store";
import axios from 'axios';
import Cookies from 'js-cookie';

//axios.defaults.withCredentials = true
axios.defaults.baseURL=process.env.REACT_APP_API_URL;

axios.defaults.headers.common = {
  "signature": process.env.REACT_APP_SIGNATURE,
  "Content-Type": "application/json" ,
  "authorization" : Cookies.get('htb:sess')
};

axios.interceptors.request.use(request => {
    return request;
}, error => {
    return Promise.reject(error);
});

axios.interceptors.response.use(response => {
    // Edit response config
    return response;
}, error => {
    return Promise.reject(error);
});

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(coonsole.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();