import { DPL_LIST,DPL_BY_ID } from "../actions/types"

const initialState = {
  dpl:{ dplList:null, dplById:null }
}

export default function reducer(state = initialState, action) {
  const {type, payload} = action

  switch(type){
    case DPL_LIST  :{ return { ... state, dpl:{ ... state.dpl, dplList: payload } }}
    case DPL_BY_ID :{ return { ... state, dpl:{ ... state.dpl, dplById: payload } }}
    default: return state
  }
}
