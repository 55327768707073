import React,{useState} from "react";
import axios from "axios";
import { clubbedBuyingBannerAdd } from "../../../actions/clubbed-buying.action";
import { SET_MESSAGE } from "../../../actions/types";
import { toast } from "react-toastify";

const BannerAdd = (props) =>{

  const {formHandle, dispatch}   = props// PROPS
  const [imageUrl, setImageUrl]  = useState()// IMAGE
  const [btnLoader, setBtnLoader] = useState(false)

  // IMAGE UPLOAD
  const uploadF = (selectorFiles) => {
    var data = new FormData()
    data.append('file', selectorFiles[0])
    data.append('foldername', 'banner')
    axios.post("/file/upload", data)      
      .then((response) => {
        setImageUrl(response.data)
      }).catch(err => {
        setBtnLoader(false)
        dispatch({
          type:SET_MESSAGE,
          payload:err.response.data
        })
      })
  }

  // FORM SUBMIT
  const onSubmitAddBanner = async (e) => { 
    const formData = new FormData(e.target)
    const data = {}
    e.preventDefault()
    for (let entry of formData.entries()) {
        data[entry[0]] = entry[1]
    } 
    
    await toast.promise(
			dispatch(clubbedBuyingBannerAdd(data)),
			{
				pending: 'Banner adding...',
				success: 'Successfully! Banner Added',
				error: {
          render(){
            setBtnLoader(false)
            return 'Banner not added'
          }
        }
			}
		)

    await e.target.reset()
    await setImageUrl(null)
    await formHandle(false)
  }

  return(
    <div className="modal effect-flip-vertical show" id="modaldemo8" aria-modal="true" style={{display:"block"}}>
      <div className="modal-dialog modal-dialog-right" role="document">
        <form onSubmit={ (e) => {setBtnLoader(true); onSubmitAddBanner(e)}} className="modal-content modal-content-demo">
          <div className="modal-header">
            <h6 className="modal-title">Add Banner</h6>
            <button aria-label="Close" onClick={() => formHandle(false)} className="close" data-dismiss="modal" type="button"><span aria-hidden="true">×</span></button>
          </div>
          <div className="modal-body">

            <div className="row">
              <div className="form-group col-md-6"> 
                <label className="main-content-label tx-11">Device</label> 
                <select name="device" className="form-control">
                  <option value="web">Web</option>
                  <option value="mobile">Mobile</option>
                </select>
              </div>

              <div className="form-group col-md-6"> 
                <label className="main-content-label tx-11">Position <i className='bx bx-info-circle tooltip_custom tooltip_bottom font-size-15 text-primary' data-tooltip="Position in slider"></i></label> 
                <select name="in_order" className="form-control" required>
                  <option value="-">Select...</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10">10</option>
                </select>
              </div>

              <div className="form-group col-md-6"> 
                <label className="main-content-label tx-11">Status</label> 
                <select name="status" className="form-control">
                  <option value="active">Active</option>
                  <option value="inactive">Inactive</option>
                </select>
              </div>

              <div className="form-group col-md-6"> 
                <label className="main-content-label tx-11">Element Type</label> 
                <select name="type" className="form-control">
                  <option value="image">Image</option>
                  <option value="video">Video</option>
                </select>
              </div>
            </div>

            <div className="form-group"> 
              <label className="main-content-label tx-11">Link Page</label> 
              <div className="input-group">
                <input type="text" name="link" className="form-control" placeholder="URL" required/>
                <div className="input-group-append">
                  <div className="input-group-text" id="btnGroupAddon"><i className='bx bx-link' ></i></div>
                </div>
              </div>
            </div>

              {/* IMAGE */}
              <div className="form-group"> 
              <label className="main-content-label tx-11">Image</label> 
              <input name="image"  defaultValue={imageUrl} type="hidden" />
              <input className="form-control" type="file" onChange={(e)=> uploadF(e.target.files)} /> 
              {imageUrl ? <><hr/> <img src={imageUrl} alt="hashtag bazaar" className="rounded wd-100p wd-sm-200" /></> : ''}
            </div>

          </div>

          <div className="modal-footer">
            <div className="btn ripple btn-outline-primary" data-dismiss="modal" type="button" onClick={() => formHandle(false)}>Close</div>
            <button disabled={btnLoader} className="btn ripple btn-primary" type='submit'>
              {!btnLoader ? 'Add Banner' : <span className="spinner-border spinner-border-sm mx-5"></span>}
            </button>
          </div>

        </form>
      </div>
    </div>
  )
}

export default BannerAdd;