import React, {useEffect} from 'react'
import { connect } from "react-redux"
import { useHistory } from "react-router-dom"
import { getSmsByName } from '../../../actions/message-templates.action'
import MainLayout from '../../layouts/main.component'
import { Link } from 'react-router-dom'


const ViewSMS = ({dispatch, messageTemplate, ...props}) => {
  const history = useHistory();

  useEffect(() => {
    document.title  = `View SMS | ${messageTemplate?.smsByName?.name}`
  },[messageTemplate])
  
  useEffect(()=>{
    dispatch(getSmsByName(props.match.params.name))
    document.title = 'SMS Templates';
  }, [dispatch])

  return (
    <MainLayout>

      {/* BREADCRUMB */}             
      <nav className="breadcrumb mt-3 mb-0"> 
				<div className="cursor-pointer mr-4 text-primary" onClick={history.goBack}><i className='bx bxs-chevron-left'></i> Back</div>
        <div className="mr-auto page_title">View SMS Template</div>
				<div className="breadcrumb-item" onClick={()=> history.push('/') }>Dashboard</div>
				<div className="breadcrumb-item" onClick={()=> history.push('/message-template/sms/list') }>SMS Template</div>
				<div className="breadcrumb-item active">View</div>
			</nav>

      {/* HEADER */}
      <div className="d-flex justify-content-between align-items-center">
        <div className="h4 mt-4 mb-3">View SMS Template</div>
        <div className="d-flex align-items-center">
          <Link to={'/message-template/sms/edit/'+messageTemplate?.smsByName?.name} className="btn btn-warning"><i className='bx bx-pencil'></i> Edit</Link>  
          <Link to={'/message-template/sms/addnew'} className="btn btn-primary mx-2"><i className='bx bx-plus'/> Add</Link>  
          <Link to={'/message-template/sms/list'}  className="btn btn-secondary"><i className="fe fe-list" /> Go To List</Link> 
        </div>
      </div>

      {messageTemplate.smsByName ? 
        <div className="card  animated fadeIn p-3">
          <table className="table m-0">
            <tbody>
              <tr>
                <th className='text-wrap border-0'><span>Name</span></th>
                <td className='text-wrap border-0'>{messageTemplate.smsByName.name}</td>
              </tr>  
              <tr>
                <th className='text-wrap'><span>Peid</span></th>
                <td className='text-wrap'>{messageTemplate.smsByName.peid}</td>
              </tr>
              <tr>
                <th className='text-wrap'><span>DLT Template ID</span></th>
                <td className='text-wrap'>{messageTemplate.smsByName.dlttemplateid}</td>
              </tr>
              <tr>
                <th className='text-wrap'><span>Content</span></th>
                <td className='text-wrap'>
                  <div dangerouslySetInnerHTML={{__html: messageTemplate.smsByName.content}} />
                </td>
              </tr>
            </tbody>
          </table>  
        </div>
      :                                     
        <div className="d-flex justify-content-center flex-column mt-5 mb-3 align-items-center">
          <div>Wait <b>Buddy</b>...</div>
          <div className="spinner-grow" role="status"/>
        </div>     
      }  
    </MainLayout>
  )
}

function mapStateToProps(state) {
  const { messageTemplate }     = state.messageTemplate

  return {
    messageTemplate
  };
}

export default connect(mapStateToProps)(ViewSMS);