import { CUSTOMER_LIST, SELLER_LIST, SELLER_ADD, USER_BY_ID, REFERENCE_SEARCH, CUSTOMER_SEARCH, CUSTOMER_ADDRESS, NEW_CUSTOMER, EXPORT_CUSTOMER_LIST } from "../actions/types";

const initialState = { 
  users: {
    newCustomer         : null,
    customerList        : null,
    exportUserList      : null,
    userById            : null,   
    sellerList          : null,
    customerSearch      : null,
    customerAddressById : null,
    referenceSearch     : null,
    addSeller     : {
      basicDetails      : null,
      pickUpAddress     : null,
      businessDetails   : null,
      bankDetails       : null
    }    
  }
}

export default function foo(state = initialState, action) {
  const { type, payload } = action;
  
  switch (type) {
    case CUSTOMER_SEARCH:
      return {
        users: {
          ...state.users,
          customerSearch: payload,
          customerList: {
            ... state.users.customerList,
            users: payload
          }
        }
      }
    case REFERENCE_SEARCH:
      return {
        users:{
          ... state.users,
          referenceSearch:payload,
        }
      } 
    case CUSTOMER_LIST:
      return {
        users:{
          ... state.users,
          customerList:payload,
        }
      } 
    case EXPORT_CUSTOMER_LIST:
      return {
        users:{
          ... state.users,
          exportUserList:payload,
        }
      } 
    case USER_BY_ID:
      return {
        users:{
          ... state.users,
          userById:payload,
        }
      }
    case SELLER_LIST:
      return {
        users:{
          ... state.users,
          sellerList:payload,
        }
      }
    case SELLER_ADD:
      return {
        users : {
          ... state.users,
          addSeller : {
            basicDetails : payload,
          }
        } 
      }    
    case CUSTOMER_ADDRESS:
      return{
        users:{
          ...state.users,
          customerAddressById: payload
        }
      }
    case NEW_CUSTOMER:
      return{
        users:{
          ...state.users,
          newCustomer: payload
        }
      }
    default:
      return state;
  }
}