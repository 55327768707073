import { CATEGORY_LIST, CATEGORY_BY_ID, CATEGORY_EDIT, CLEAR_CATEGORY } from "../actions/types";

const initialState = { 
  categories:null,
  categoryById:null
}

export default function foo(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CATEGORY_LIST:
      return {
        ...state,
        categories: payload,
      }; 
    case CLEAR_CATEGORY:
      return {
        ...state,
        categories: null,
      };   
    case CATEGORY_BY_ID:
      return{
        ... state,
        categoryById:payload
      }
    default:
      return state;
  }
}