import React, {useEffect, useState, useRef} from 'react'
import { connect } from "react-redux"
import { useHistory } from "react-router-dom"
import MainLayout from '../layouts/main.component'
import { dplDelete, dplList, dplUpdate } from '../../actions/edge.action' 
import { convertToArray } from '../../helpers/common-function.helper'
import { toast } from 'react-toastify'
import { clubbedBuyingRange } from '../../helpers/product.helper'
import DeleteConfirm from '../../helpers/delete.helper'
import moment from 'moment'


const Dpl = ({dispatch, dpl, capability}) => {

  const [showPopup, setShowPopup] = useState(false)
  const [popupContent, setPopupContent] = useState()
  const [search, setSearch] = useState('')

  //CLUBBED BUYING FUNCTIONALITY
	const [clubbedBuying, setClubbedBuying] = useState();// CLUBBED BUYING "yes" - "no"
	const [clubbedBuyingField, setClubbedBuyingField] = useState([]); //PRICE RANGE FIELD
	const [priceWindow, setPriceWindow] = useState([]);//STORE PRICE RANGE VALUE ON CHANGE
  var clubbedBuyingFieldTag = <ClubbedBuyingField deleteClubbedBuyingField={deleteClubbedBuyingField} key={clubbedBuyingField[clubbedBuyingField?.length - 1]?.key ? clubbedBuyingField[clubbedBuyingField?.length - 1]?.key + 1: clubbedBuyingField.length} id={clubbedBuyingField[clubbedBuyingField?.length - 1]?.key ? clubbedBuyingField[clubbedBuyingField?.length - 1]?.key + 1: clubbedBuyingField.length} defaultData='' priceWindow={setPriceWindow} clubbedBuyingRange={clubbedBuyingRange}/>
  const addclubbedBuyingField = () => { // ADD PRICE RANGE FIELD
		setClubbedBuyingField(clubbedBuyingField.concat(clubbedBuyingFieldTag));
	}

  function deleteClubbedBuyingField(id){
		setTimeout(() => {
			var clubbedBuyingFieldLength = null;
			setClubbedBuyingField(data => data?.filter((d,i) => id !== d.props.id))
			setClubbedBuyingField(data => { clubbedBuyingFieldLength = data.length; return data })
      setPriceWindow(data => {delete data[id]; return data})
			setClubbedBuyingField(data => { clubbedBuyingFieldLength = data.length; return data })
			if(clubbedBuyingFieldLength == 0) setClubbedBuyingField([clubbedBuyingFieldTag])
		},100)
	}

  useEffect(() => {
    if(popupContent?.variation){
      setClubbedBuyingField([]) //SET DEFAULT CLUBBED BUYINIG PRICE RANGE 
      {popupContent?.variation?.clubbed_buying.price_window.length?
        popupContent?.variation?.clubbed_buying.price_window.map((data,index) => (	
          setClubbedBuyingField(cbField => cbField.concat(<ClubbedBuyingField deleteClubbedBuyingField={deleteClubbedBuyingField} key={index+99} id={index+99} defaultData={data} priceWindow={setPriceWindow} clubbedBuyingRange={clubbedBuyingRange}/>))
        ))
      :
        setClubbedBuyingField([clubbedBuyingFieldTag])
      }
    }
  }, [popupContent])

  const history = useHistory()
  useEffect(()=>{
    document.title = 'DPL - Daily Price Logger | HashTagBazaar'
    dispatch(dplList())
  }, [])

  //DELETE
  const [showDelete, setShowDelete]  = useState(false)
  const [deleteId, setDeleteId]      = useState()
  const [deleteName, setDeleteName]  = useState()


  return (
    <MainLayout>

      {/* BREADCRUMB */} 
			<nav className="breadcrumb mt-3 mb-0"> 
				<div className="cursor-pointer mr-4 text-primary" onClick={history.goBack}><i className='bx bxs-chevron-left'></i> Back</div>
        <div className="mr-auto page_title">DPL List</div>
				<div className="breadcrumb-item" onClick={()=> history.push('/') }>Dashboard</div>
				<div className="breadcrumb-item active">DPL</div>
			</nav>

      {/* HEADER */}
			<div className="d-flex justify-content-between align-items-center">
        <div className="h4 mt-4 mb-3 d-flex align-items-center">
          DPL <span className="badge badge-primary ml-2">{dpl?.dplList?.length}</span>
        </div>

        <div className="d-flex align-items-center">
          <button className="btn btn-white border-5 mr-2 title-top" data-title='Refresh' onClick={()=> window.location.reload()}><i className="bx bx-refresh pt-1 font-size-16"></i></button>
          <div className="form-group w-100 mb-0 mr-2"> 
            <div className="input-icon"> 
              <span className="input-icon-addon"> <i className="fe fe-search" /> </span> 
              <input type="search" onChange={e => setSearch(e.target.value.toLowerCase())} className="form-control ht-40" placeholder="Search..." /> 
            </div> 
          </div>   
        </div>
      </div>

      {/* EDIT */}
      {showPopup && <DplApprovePopup dispatch={dispatch}  popupContent={popupContent} setShowPopup={setShowPopup} clubbedBuyingRange={clubbedBuyingRange} addclubbedBuyingField={addclubbedBuyingField} clubbedBuyingField={clubbedBuyingField} priceWindow={priceWindow} setPriceWindow={setPriceWindow}/>}

      {/* DELETE */}
      {showDelete && <DeleteConfirm  id={deleteId} title={deleteName} componentHandle={setShowDelete} dispatch={dispatch} deleteItem={dplDelete}/>}

      {/* USER - LIST */}
      <div className="card">
				<div className="card-body"> 
          <style>{/* SEARCH */}
            {`.category_table tbody tr:not([name${search.length ? `*="${search}"`: ''}]){
                visibility:hidden;
                position:absolute
            }`}
          </style>
          <div className="userlist-table"> 
            {search && <div className="mb-2">Search <b className="text-warning">"{search}"</b></div>}
            <table className="table card-table category_table table-bordered stable-vcenter text-nowrap mb-0">
              <thead>
                <tr>
                  <th className="text-center"><span>#</span></th>                                   
                  <th className="text-center"><span>Product</span></th>                                   
                  <th className="text-center"><del>MRP</del></th>
                  <th className="text-center tooltip_custom"  data-tooltip="Cost to company for this product"><span>Cost Price <i className="bx bx-info-circle font-size-15 text-primary"></i></span></th>
                  <th className="text-center"><span>Created By</span></th>
                  <th className="text-center"><span>Updated By</span></th>
                  <th className="text-center">Action</th>
                </tr>
              </thead>
              <tbody>

                {dpl?.dplList?.length == 0 ? 
                  <tr name=""><td className="text-center count_ignore" colSpan="7">No Data Available</td></tr>
                :                                     
                  <>
                  {dpl?.dplList?.map((data, index) => (
                    <tr key={index} name={data?.variation?.name.toLowerCase()}>
                      <td className="text-center"></td>
                      <td>
                        <div className="d-flex align-items-center mb-2 last-mb-0">
                          <img 
                            className="rounded avatar-md mr-2 border" 
                            src={
                              data.variation?.images?.filter(data => data.featured == 'yes').length ?
                              data.variation?.images?.filter(data => data.featured == 'yes')[0].link:
                              data.variation?.images[0]?.link
                            }
                          /> 
                          <div className="text-left">
                            <div className="font-weight-500 white-space-normal text-wrap">{data.variation?.name ? data.variation?.name : "Product Removed"}</div>
                            <div className="mr-3 d-flex justify-content-start align-items-center">
                              {!data.variation?.name ?
                                <span className="badge badge-secondary text-capitalize">Removed</span>
                              :
                                <>
                                  {data?.status === 'pending' ? <span className="badge badge-warning text-capitalize">{data.status}</span>:''}
                                  {data?.status === 'published' || data.status === 'approved' ? <span className="badge badge-success text-capitalize">{data.status}</span>:''} 
                                  {data?.status === 'drafts' ? <span className="badge badge-secondary text-capitalize">{data.status}</span>:''}
                                  {data?.status === 'review' ? <span className="badge badge-primary text-capitalize">{data.status}</span>:''}
                                </>
                              }
                              {data?.variation?.deals?.is_exclusive == 'yes' > 0 && <div className="badge badge-teal-transparent text-capitalize ml-1">Exclusive</div>}
                              <span className="text-secondary ml-1">SKU: {data.variation?.sku_id ? data.variation?.sku_id : "Removed" }</span> 
                            </div>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className='text-dark d-flex justify-content-between'><b className='font-weight-600 mr-3'>New:</b> <span className='ml-4'>₹{data.prices.new_mrp}</span></div>
                        <div className='border-bottom border-dash position-relative'><i className={`bx price_flow ${parseInt(data.prices.mrp) > parseInt(data.prices.new_mrp) ? 'bx-trending-down bg-teal' : 'bx-trending-up bg-warning'}`}></i></div>
                        <div className='text-secondary d-flex justify-content-between'><b className='font-weight-600 mr-3'>Old:</b> <span className='ml-4'>₹{data.prices.mrp}</span></div>
                      </td>
                      <td>
                        <div className='text-dark d-flex justify-content-between'><b className='font-weight-600 mr-3'>New:</b> <span className='ml-4'>₹{data.prices.new_purchase_price}</span></div>
                        <div className='border-bottom border-dash position-relative'><i className={`bx price_flow ${parseInt(data.prices.purchase_price) > parseInt(data.prices.new_purchase_price) ? 'bx-trending-down bg-teal' : 'bx-trending-up bg-warning'}`}></i></div>
                        <div className='text-secondary d-flex justify-content-between'><b className='font-weight-600 mr-3'>Old:</b> <span className='ml-4'>₹{data.prices.purchase_price}</span></div>
                      </td>
                      <td>
                        <div className='text-secondary text-wrap'><i className='bx bx-user font-size-16 mr-2'></i>{data.created_by.first_name} {data.created_by.last_name}</div>
                        <div className='text-secondary'><i className='bx bx-calendar-check font-size-16 mr-2'></i>{moment(data.createdAt).utcOffset('+00:00').format('lll')}</div>
                      </td>
                      <td>
                        <div className='text-secondary text-wrap'><i className='bx bx-user font-size-16 mr-2'></i>{data.updated_by.first_name == data.created_by.first_name && data.createdAt == data.updatedAt ? '-' :  data.updated_by.first_name} {data.updated_by.last_name == data.created_by.last_name && data.createdAt == data.updatedAt ? '' : data.updated_by.last_name}</div>
                        <div className='text-secondary'><i className='bx bx-calendar-check font-size-16 mr-2'></i>{data.createdAt == data.updatedAt ? '-' : moment(data.updatedAt).utcOffset('+00:00').format('lll')}</div>
                      </td>
                      <td className='text-right'>
                        {data.status == "pending" || data.status == "review" ? 
                          <>{capability?.dpl.edit ? <button onClick={() => {setShowPopup(true); setPopupContent(data)}} className='btn btn-primary'>Approve</button> :null}</>
                        :
                          <button className='btn btn-secondary disabled text-capitalize' disabled>{data.status}</button>
                        }
                        {capability?.dpl.delete ? <button onClick={() => {setShowDelete(true); setDeleteId(data.id); setDeleteName(data.variation?.name ? data.variation?.name : "Product Removed");}} className="btn btn-sm btn-white tooltip_custom right ml-2" data-title="Delete Item"><i className='bx bx-trash h4 m-0 text-danger'></i></button> :null}
                      </td>
                    </tr>
                  ))} 
                  </>
                }
              </tbody>
            </table>

            {/* LOADER */}
            {!dpl?.dplList && 
            <div className="d-flex justify-content-center flex-column mt-5 mb-3 align-items-center">
              <div>Wait <b>Buddy</b>...</div>
              <div className="spinner-grow" role="status"/>
            </div>}

          </div>
        </div>
      </div>
    </MainLayout>
  )
}



/*----------------------------
        DPL APPROVE POUP
----------------------------*/
const DplApprovePopup = ({clubbedBuyingRange, dispatch, priceWindow, popupContent, setShowPopup, setPriceWindow, clubbedBuyingField, addclubbedBuyingField}) => {

  async function onSubmitUpdateDpl(e){
    e.preventDefault()
    const formData = new FormData(e.target)
    const data = {}
    for (let entry of formData.entries()) {
      data[entry[0]] = entry[1]
    } 

    data['clubbed_buying'] = {
      price_window: convertToArray(priceWindow),
      price: {
        min: convertToArray(priceWindow)[convertToArray(priceWindow).length - 1].price,
        max: data.listing_price
      } 
    }


    if(data.status !== popupContent.status){
      await toast.promise(
        dispatch(dplUpdate(data)),
        {
          pending: 'Updating...',
          success: 'Successfully! Updated',
          error: 'Not Updated'
        }
      )
      setShowPopup(false)
    }else{
      alert('Please! updated Status')
    }
  }

  return (
    <div className="modal" aria-hidden="true" style={{display:'block'}}> 
      <div className="modal-dialog modal-dialog-right" role="document"> 
        <div className="modal-content modal-content-demo"> 
          <div className="modal-header"> 
            <h6 className="modal-title d-flex align-items-center"><i className='bx bx-check-circle font-size-22 mr-2'></i> Approve Price</h6>
            <button className="close" onClick={() => setShowPopup(false)}><span aria-hidden="true">×</span></button> 
          </div> 
          <form className="h-100 d-flex flex-column justify-content-between" onSubmit={e => onSubmitUpdateDpl(e)}>
            <input type="hidden" name="product" value={popupContent?.product?.id}/>
            <input type="hidden" name="variation" value={popupContent?.variation?.id}/>
            <input type="hidden" name="price_log_id" value={popupContent?.id}/>

            <div className="modal-body">                                                   
              <div className="row">
                <div className="col-md-4">
                  <div className="input_wrap w-100">
                    <input defaultValue={popupContent.prices.new_mrp} name="mrp" type="number" placeholder=" "/>
                    <label>MRP: {popupContent.prices.mrp}</label>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="input_wrap w-100">
                    <input defaultValue={popupContent.prices.listing_price} name="listing_price" type="number" placeholder=" "/>
                    <label>Listing Price: {popupContent.prices.listing_price}</label>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="input_wrap w-100">
                    <input defaultValue={popupContent.prices.new_purchase_price} name="purchase_price" type="number" placeholder=" "/>
                    <label>Cost Price: {popupContent.prices.purchase_price}</label>
                  </div>
                </div>
                <div className="col-md-12 mt-2">
                  <div className="form-group">
                    <label className="main-content-label tx-11">Status</label> 
                    <select defaultValue={popupContent.status} className="form-control" name="status">
                      <option value="">Select Status...</option>
                      <option value="approved">Approved</option>
                      <option value="reject">Reject</option>
                      <option value="review">Review</option>
                      <option value="pending">Pending</option>
                    </select>
                  </div>
                </div>

                {/* ClUBBED BY */}
                <div className="col-md-12">
                  <div className="position-relative z-index-1 pt-4">
                    <div className="align-items-center d-flex justify-content-between heading">
                        <div className="form-check pl-0 d-flex align-items-center">
                          <label className="form-check-label cursor-pointer" htmlFor="clubbed_buying_status">Clubbed Buying</label>
                          <div onClick={() => addclubbedBuyingField()} className="btn btn-outline-primary ml-3"><i className="bx bx-plus"></i> Add More Price Range</div>
                        </div>
                    </div>

                    {/* PRICE RANGE */}
                    {clubbedBuyingField}

                  </div>
                </div>
                
              </div>
            </div> 
            <div className="modal-footer">
                <div onClick={() => setShowPopup(false)} className="btn ripple btn-outline-primary" data-dismiss="modal" type="button">Close</div>
                <button type='submit' className="btn ripple btn-primary">Submit</button>
            </div>
          </form>   
        </div> 
      </div> 
    </div>
  )
}



/* -------------------------------
				 CLUBBED BUYING
----------------------------------*/
const ClubbedBuyingField = ({clubbedBuyingRange, priceWindow, deleteClubbedBuyingField, id, defaultData}) =>{

	const [priceRange, setPriceRange] = useState();
	const [price, setPrice] =  useState();

	const priceRangeRef = useRef();
	const priceRef = useRef();

	useEffect(() =>{
		priceWindow(prevValue => ({
			...prevValue,
			[id]: {
				"range":priceRangeRef.current.value,
				"price":priceRef.current.value
			}
		}))
	},[priceRangeRef.current?.value,priceRef.current?.value]);

	return(
		<div className="mt-4 mb-3 ml-0 mr-0 pt-3 pb-2 pl-3 pr-3 custom-legend">
			<div className="row">
				<div className="col-12 d-flex">
					<div className="font-size-a14 text-secondary custom-legend-heading">Price Range</div>
					<button type='button' onClick={() => deleteClubbedBuyingField(id)} className="btn btn-outline-light custom-legend-heading ml-auto bx bxs-x-circle"></button>
				</div>

				{/* PRICE RANGE */}
				<div className="col-md-6 mb-2">
					<select defaultValue={defaultData.range}  ref={priceRangeRef} onChange={e => setPriceRange(e.target.value)} className="form-control">
						{clubbedBuyingRange.map((range,index) => (
							<option key={index} value={range}>{range}</option>
						))}
					</select>
				</div>

				{/* PRICE */}
				<div className="col-md-6 mb-2">
					<input ref={priceRef} defaultValue={defaultData.price} name="price" type="number" onChange={e => setPrice(e.target.value)} className="form-control" placeholder="Price"/>
				</div>
			</div>
		</div>
	)
}




function mapStateToProps(state) {
  const { dpl }     = state.edge
  let capability   = state.auth.currentUser?.capability
  capability       = capability ? JSON.parse(capability) : null
  return {
    dpl,
    capability
  }
}

export default connect(mapStateToProps)(Dpl)