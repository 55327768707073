import React from 'react';

const errors = () => {

  return(
    <React.Fragment>
      <div className="main-error-wrapper page page-h error-background">
        <h1 className="text-white">404<span className="tx-20">error</span></h1>
        <h2 className="tx-white-8">Oopps. The page you were looking for doesn't exist.</h2>
        <h6 className="tx-white-6">You may have mistyped the address or the page may have moved.</h6>
        <a className="btn btn-success" href="/">Back to Home</a>
      </div>

    </React.Fragment>
  )
}

export default errors;