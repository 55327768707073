import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import MainLayout from '../../layouts/main.component';
import { getFirebaseNotificationByName, updateFirebaseNotificationTemplate } from '../../../actions/message-templates.action';
import axios from 'axios';
import { centerInput } from '../../../helpers/common-function.helper';

//IMAGE - "FilePond"
import { FilePond, File, registerPlugin } from 'react-filepond'
import "filepond/dist/filepond.min.css"
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation"
import FilePondPluginImagePreview from "filepond-plugin-image-preview"
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css"
import { toast } from 'react-toastify';
import { SET_MESSAGE } from '../../../actions/types';
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview, FilePondPluginFileValidateType, FilePondPluginFileValidateSize)


const EditNotification = ({dispatch, message, messageTemplate, ...props}) => {
  const history                   = useHistory()
  const [image, setImage]         = useState()
  const [btnLoader, setBtnLoader] = useState(false)


  //SET DEFAULT IMAGE
  useEffect(() => {
    document.title  = `Edit Firebase Notification | ${messageTemplate?.firebaseNotificationByName?.name}`
    messageTemplate && setImage(messageTemplate?.firebaseNotificationByName?.image)
  },[messageTemplate])

  
  useEffect(() => {
    //MANAGE TAB INDEX
		setInterval(() => document.querySelectorAll('.no-tab-index *').forEach(e => e.tabIndex = -1), 2000)
    dispatch(getFirebaseNotificationByName(props.match.params.name))
  },[dispatch])


  const onSubmit = async (id, e) => { 
    e.preventDefault()
    if(!image) return toast.error('Buddy! 🙂 Upload image')

    var imgLink
    if(typeof image[0]?.source != 'object'){
      imgLink = image[0]?.source
    }else{
      toast.loading('Uploading Image...', {toastId:'loading'})
      var imgInfo = new FormData();
      imgInfo.append('file', image[0]?.file);
      imgInfo.append('foldername', 'firebase');
      
      await axios.post("/file/upload", imgInfo)      
      .then((response) => {
        imgLink = response.data
      }).catch(err => {
        setBtnLoader(false)
        dispatch({ type:SET_MESSAGE, payload:err.response.data })
      })
      toast.dismiss('loading')
    }

    //SUBMIT DATA
    if(imgLink){
      const formData = new FormData(e.target)
      formData.append('image', imgLink)
      const data = {}
      e.preventDefault()
      for (let entry of formData.entries()) {
          data[entry[0]] = entry[1]
      } 

      var isValide;
      Object.values(data).map((formField,index) => {
        if(!formField){ setBtnLoader(false); toast.error(<><b>{Object.keys(data)[index]}</b> is mandatory</>); isValide = false }
      })
      if(isValide == false) return
      
      await toast.promise(dispatch(updateFirebaseNotificationTemplate(id, data)),{
        pending: 'Updating Firebase Notification...',
        success: 'Successfully! Updated Firebase Notification',
        error: {
          render(){
            setBtnLoader(false)
            return 'Firebase Notification not updated'
          }
        }
      })  
      history.push('/message-template/firebase-notification/view/'+encodeURI(data.name))
    }else{
      setBtnLoader(false)
      return toast.error('Something wrong with image upload')
    }
  }

  
  return (
    <MainLayout>
      {/* BREADCRUMB */}             
      <nav className="breadcrumb mt-3 mb-0"> 
        <div className="cursor-pointer mr-4 text-primary" onClick={history.goBack}><i className='bx bxs-chevron-left'></i> Back</div>
        <div className="mr-auto page_title">Edit Firebase Notification</div>
        <div className="breadcrumb-item" onClick={()=> history.push('/')}>Dashboard</div>
        <div className="breadcrumb-item" onClick={()=> history.push('/message-template/firebase-notification/list')}>Firebase Notification</div>
        <div className="breadcrumb-item active">Edit</div>
      </nav>


      {/* HEADER */}
      <div className="d-flex justify-content-between align-items-center">
        <div className="h4 mt-4 mb-3 d-flex align-items-center">Edit Firebase Notification</div>
        <button disabled={btnLoader} className="btn btn-primary" type="submit" onClick={() => document.querySelector('.edit_notification').click()}>
          {!btnLoader ? '+ Update Notification' : <span className="spinner-border spinner-border-sm mx-5"></span>}
        </button>
      </div>

      {messageTemplate?.firebaseNotificationByName ?
        <div className='card card-body animated fadeIn flex-unset'>
          <form onSubmit={ (e) => {setBtnLoader(true); onSubmit(messageTemplate.firebaseNotificationByName.id, e)}}>  

            <button className='d-none edit_notification' type='sumbit'>Submit Form</button> 

            <div className="row">
              <div className="col-md-3">
                <div className="input_wrap w-100" data-title='Sorry! 🙂 I am not editable'>
                  <input readOnly onFocus={centerInput} className='disabled' defaultValue={messageTemplate?.firebaseNotificationByName?.name} name='name' type="text"  placeholder=" "/>
                  <label>Name</label>
                </div>
              </div>

              <div className="col-md-9">
                <div className="input_wrap w-100">
                  <input autoFocus onFocus={centerInput} defaultValue={messageTemplate?.firebaseNotificationByName?.subject} name="subject" type="text"  placeholder=" "/>
                  <label>Subject</label>
                </div>
              </div>

              <div className='col-md-12 mt-3 no-tab-index'>
                <FilePond
                  files={image}
                  onupdatefiles={(e) => setImage(e)}
                  maxFiles={1}
                  storeAsFile={true}
                  maxFileSize={'1MB'}
                  acceptedFileTypes={['image/png', 'image/jpeg', 'image/jpg']}
                  name="test111" 
                  labelIdle='Drag & Drop Image or <span class="filepond--label-action">Browse</span>'
                />
              </div>

              <div className="col-md-12 mt-3">
                <div className="form-group mb-0"> 
                  <label className="main-content-label tx-11 mb-0">Content</label> 
                  <textarea onFocus={centerInput} className="form-control" name="content" rows="9" defaultValue={messageTemplate?.firebaseNotificationByName?.content} />
                </div>
              </div>

            </div>
          </form> 
        </div>
      :
        <div className="d-flex justify-content-center flex-column mt-5 mb-3 align-items-center">
          <div>Wait <b>Buddy</b>...</div>
          <div className="spinner-grow" role="status"/>
        </div>
      }
    </MainLayout>
  )
}

function mapStateToProps(state) {
  const { message }             = state.message;
  const { messageTemplate }     = state.messageTemplate;

  return {
    message,
    messageTemplate
  };
}

export default connect(mapStateToProps)(EditNotification);