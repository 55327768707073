import axios from "axios";

class AttributesServices{

  // ATTRIBUTE LIST
  async attributesList(){
    return axios
    .get('/products/attributes/list',{
      params:{
        sort: "ASC"
      }
    })
    .then(response => {
      return response.data;
    })
  }

  // ADD ATTRIBUTE
  async addAttribute(data){
    return axios
    .post('/products/attributes',data)
    .then(response => {
      return response.data;
    })
  }

  // DELETE ATTRIBUTE
  async deleteAttribute(attrId) {
    return axios
    .delete("products/attributes/"+attrId)      
    .then((response) => {
      return response.data;
    });
  }

  // GET ATTRIBUTE BY ID
  async getAttributeById(id) { 
    return axios
    .get("products/attributes/"+id)      
    .then((response) => {
      return response.data;
    });
  }

  // EDIT ATTRIBUTE
  async editAttribute(id,data){ 
    return axios
      .put("products/attributes/update/"+id, data)      
      .then((response) => {
        return response.data;
      });
  }

}

export default new AttributesServices();