import { CUSTOMER_LIST, SELLER_LIST, SELLER_ADD, SET_MESSAGE, REFERENCE_SEARCH, USER_BY_ID, CUSTOMER_SEARCH, CUSTOMER_ADDRESS, NEW_CUSTOMER, EXPORT_CUSTOMER_LIST } from "./types"
import usersService from "../services/users.service"
import { handleErrors } from "../helpers/common-function.helper"



//USER LIST
export const userList = (page, limit, sortBy, roleShow) => async (dispatch) => {
  return usersService.userList(page, limit, sortBy, roleShow).then(
    (data)  => { dispatch({ type: CUSTOMER_LIST, payload: data }) },
    (error) => handleErrors(error, dispatch)
  )
}


//EXPORT USER LIST
export const exportUserListF = (page, limit, sortBy, roleShow) => async (dispatch) => {
  return usersService.userList(page, limit, sortBy, roleShow).then(
    (data)  => { dispatch({ type: EXPORT_CUSTOMER_LIST, payload: data }) },
    (error) => handleErrors(error, dispatch)
  )
}


//USER BY ID
export const userById = (id) => async (dispatch) => {
  return usersService.userById(id).then(
    (data)  => { dispatch({ type: USER_BY_ID, payload: data }) },
    (error) => handleErrors(error, dispatch)
  )
}

//USER ADD
export const addUser = (data, role) => async (dispatch) => {
  return usersService.addUser(data).then(
    (data)  => { dispatch({ type:NEW_CUSTOMER, payload:data.success[0].data }) },
    (error) => handleErrors(error, dispatch)
  )
}


//USER UPDATE
export const updateUser = (id, data, role) => async (dispatch) => {
  return usersService.updateUser(id, data).then(
    (data)  => { },
    (error) => handleErrors(error, dispatch)
  )
}


//USER DELETE
export const deleteUser = (catId, role) => async (dispatch) => {
  return usersService.deleteUser(catId).then(
    (data)  => {},
    (error) => handleErrors(error, dispatch)
  )
}


//USER SEARCH
export const customerSearch = (q, role, reference) => async (dispatch) => {
  return usersService.searchCustomer(q, role).then(
    (data)  => { 
      if(reference){
        dispatch({ type: REFERENCE_SEARCH, payload: data })
      }else{
        dispatch({ type: CUSTOMER_SEARCH, payload: data }) 
      }
    },
    (error) => handleErrors(error, dispatch)
  )
}


//USER ADDRESS
export const customerAddress = (id) => async (dispatch) => {
  return usersService.customerAddress(id).then(
    (data)  => { dispatch({ type: CUSTOMER_ADDRESS, payload: data }) },
    (error) => handleErrors(error, dispatch)
  )
}


// ------------------------------------------------- FOR SELLER
export const sellerList = () => async (dispatch) => {
  return usersService.sellerList().then(
    (data) => { dispatch({ type: SELLER_LIST, payload: data}) },
    (error) => handleErrors(error, dispatch)
  );
}; 

export const addSeller = (data) => async (dispatch) => {
  return usersService.addSeller(data).then(
    (data) => { dispatch({ type: SELLER_ADD, payload: data, }) },
    (error) => handleErrors(error, dispatch)
  );
}

export const updateSeller = (id, data) => async (dispatch) => {
  return usersService.updateSeller(id, data).then(
    (data) => { dispatch(sellerList()); dispatch({ type: SET_MESSAGE, payload: data, }) },
    (error) => handleErrors(error, dispatch)
  );
}

export const deleteSeller = (id) => async (dispatch) => {
  return usersService.deleteSeller(id).then(
    (data) => { dispatch(sellerList()); dispatch({ type: SET_MESSAGE, payload: data, }) },
    (error) => handleErrors(error, dispatch)
  );
}
