import React, { useState, useEffect } from 'react'
import MainLayout from '../layouts/main.component'
import {useHistory} from 'react-router-dom'
import Api2Pdf from 'api2pdf';
import numWords from 'num-words';
import { addPaisa } from '../../helpers/common-function.helper';
import { toast } from 'react-toastify';
import axios from 'axios';


function InvoiceGenerator() {
  const history                       = useHistory()
  const [search, setSearch]           = useState('')
  const [loader, setLoader]           = useState()
  const [csvData, setCsvData]         = useState()
  const [saveSheet, setSaveSheet]     = useState(true)
  var a2pClient = new Api2Pdf('07bf7fe0-982e-413f-8717-2d3a3b76d494')

  useEffect(() => {
    document.title = 'Invoice Generator'
  }, [])

  function removeComma(price){
    price = price.toString()
    price = price?.replaceAll(',', '')
    price = price?.replaceAll(' ', '')
    return parseFloat(price)
  }

  /* PDF Generator
  ========================================*/
  async function generatePDF(invoice, shipping){
    let allOrders           = csvData.filter(data => data['Invoice No.'] == invoice['Invoice No.'])
    var totalAmount         = allOrders.length == 1 ? removeComma(allOrders[0]['Total Invoice Amount']) :  allOrders.reduce((total,current) => isNaN(total) ? removeComma(total['Total Invoice Amount']) + removeComma(current['Total Invoice Amount']) :  removeComma(total) + removeComma(current['Total Invoice Amount']))
    var confirmShipping     = shipping ? window.confirm('😯 Budddy! are you sure?\n\nCreate Invoice without "Shipping Charge"') : null
    if(!confirmShipping) shipping = false
    var shippingCharges     = shipping ? 0 : removeComma(totalAmount) < 500 ? 25 : 0
    totalAmount             = removeComma(totalAmount) + shippingCharges
    let totalMRP            = allOrders.length == 1 ? removeComma(allOrders[0]['MRP']) : allOrders.reduce((total,current) => isNaN(total) ? removeComma(total['MRP']) + removeComma(current['MRP']) :  removeComma(total) + removeComma(current['MRP']))
    let totalSaving         = allOrders.length == 1 ? removeComma(allOrders[0]['Customer Saved (In INR)']) : allOrders.reduce((total,current) => isNaN(total) ? removeComma(total['Customer Saved (In INR)']) + removeComma(current['Customer Saved (In INR)']) :  removeComma(total) + removeComma(current['Customer Saved (In INR)']))
    let totalSavingP        = ((removeComma(totalSaving) / removeComma(totalMRP)) * 100).toFixed()
    // console.log(totalAmount)
    // let splitAmount         = (totalAmount.toString()).indexOf('.') != -1 ? totalAmount.split(".") : [totalAmount, 0];
    let splitAmount         = (typeof totalAmount === 'string' ? totalAmount : totalAmount.toString()).split('.');
    let roundingOff         = 0;

    if( Number(splitAmount[1]) < 50 && Number(splitAmount[1]) != 0 ) { //ROUND OFF
      roundingOff="-0."+ (splitAmount[1]);
    }else if(Number(splitAmount[1]) != 0){
        roundingOff="+0." + (100-splitAmount[1])
    }

    let customerOrderListData = '';
    const customerOrderList   = allOrders.map((item, index) => (
      customerOrderListData +=
      `<tr style="border:1px solid #ccc;">
        <td style="border:1px solid #ccc; text-align: center;">${index+1}</td>
        <td style="border:1px solid #ccc; text-align: left; font-family: 'Mukta';font-size:20px;">${item['Product']}</td>
        <td style="border:1px solid #ccc; text-align: right;">${addPaisa(item['Unit Price'])}</td>
        <td style="border:1px solid #ccc; text-align: center;">${item['Quantity'].toString().replace('.00','')}</td>
        <td style="border:1px solid #ccc; text-align: right;">${addPaisa(item['Total Amount'])}</td>
        <td style="border:1px solid #ccc; text-align: right;">${addPaisa(item['Product GST %'])}</td>
        <td style="border:1px solid #ccc; text-align: right;">${`CGST/SGST`}</td>
        <td style="border:1px solid #ccc; text-align: right;">${addPaisa(item['Total GST Amount'])}</td>
        <td style="border:1px solid #ccc; text-align: right;">${addPaisa(item['Total Invoice Amount'])}</td>
        <td style="border:1px solid #ccc; text-align: right;">${addPaisa(item['MRP'])}</td>
        <td style="border:1px solid #ccc; border-right:unset;background: #e0ead9;text-align: right;">${addPaisa(item['Customer Saved (In INR)'])}</td>
      </tr>
      `
    ))


    let htmlContent = `
    <!DOCTYPE html>
    <html lang="en">

    <head>
      <meta charset="UTF-8">
      <meta http-equiv="X-UA-Compatible" content="IE=edge" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <link href="https://fonts.googleapis.com/css2?family=Karla:wght@300;400;500;600;700&family=Mukta:wght@300;400&display=swap" rel="stylesheet" />
      <style>
      * {
        box-sizing: border-box;
      }
      
      table,
      td {
        border-collapse: collapse;
      }
      
      td,
      th {
        padding: 10px;
        vertical-align: top;
      }
      /* auto is the initial value */
      /* this affects the margin in the printer settings */
      
      @page {
        size: auto;
        margin: 0mm;
      }
      </style>
    </head>
        
    <body style="font-family:karla;display:flex;justify-content: center;align-items: center;margin:unset;font-size:18px;color: #444;">
      <main style="width:100%;max-width:100%;min-height:100%;margin:auto;padding:30px 15px;">
      <table style="width:100%;border:unset;border-collapse:collapse;">
        <tr style="border:unset">
          <td style="border:unset">
            <a spellcheck="false" href="https://www.hashtagbazaar.com/" style="display:flex;flex-flow:column;text-decoration: unset;color: black;font-weight: 500;color: #bc242c;"> 
              <img style="width:200px;" src="https://htbazaar.s3.ap-south-1.amazonaws.com/new/wp-content/uploads/2021/06/17073450/Final-Bazaar-1.png"/>
            </a>
            <p spellcheck="false" style="color: #787878;font-style: italic;font-weight: 900;margin-top:0;border:none;text-decoration: none; background:white;margin-bottom: 0;font-family: serif;">मिलकर खरीदारी में ही है समझदारी &nbsp;!!</p>
          </td>
          <td style="border:unset">
            <div style="display: flex;flex-flow: column; justify-content:center; align-items:center;text-align:center; margin-top:10px">
              <img src="https://htbazaar.s3.ap-south-1.amazonaws.com/wp-content/uploads/2021/11/10182632/fssai-logo-freelogovectors.net_.png" style="width: 100px;margin-bottom: 5px;margin:auto;display:block;text-align:center;"/>
              <span style=" font-weight: 500; margin-left: 5px; ">License&nbsp;No.:&nbsp;10021051000492</span>
            </div>
          </td>
          <td style="border:unset">
            <h2 style="text-align: left;margin-top: unset; font-size:21px;">Tax&nbsp;Invoice/&nbsp;Bill&nbsp;of&nbsp;Supply/&nbsp;Cash&nbsp;Memo</h2>
            <div style="margin-bottom: 10px; display: flex; align-items: center;">
              <label for="invoice_number" style="min-width:89px; display:inline-block;">Invoice No:</label> <b>${invoice['Invoice No.']}</b> </div>
            <div style="display: flex;align-items: center;">
              <label for="invoice_date" style="min-width: 90px;">Invoice Date:</label> <b>${invoice['Order Date']}</b> </div>
          </td>
        </tr>
      </table>
        <div style="margin-top:30px;">
          <table style="width:100%;">
            <tbody>
              <tr>
                <td style="border:1px solid #ccc;">
                  <div style="display: flex;align-items:center;"> <b>Order&nbsp;#:&nbsp;</b> <span>${invoice['Order Number']}</span> </div>
                </td>
                <td style="border:1px solid #ccc;">
                  <div style="display: flex;align-items:center;"> <b>Order&nbsp;Date:&nbsp;</b> ${invoice['Order Date']} </div>
                </td>
              </tr>
              <tr>
                <td style="border:1px solid #ccc;">
                  <div style="display: flex;align-items:center;"> <b>Sold&nbsp;By: &nbsp;</b> &nbsp;<span>HashTagBazaar <br/>5/68, Vishal Khand, Gomti Nagar, Lucknow. 226024 </span> </div>
                </td>
                <td style="border:1px solid #ccc;">
                  <div style="display: flex;align-items:center;"> <b>Customer&nbsp;Name:&nbsp;</b> <span>${invoice['Customer Name']}</span> </div>
                  <div style="display: flex;align-items:center;margin-top:5px;"> <b>Customer&nbsp;Phone:&nbsp;</b> <span>${invoice['Customer Mobile Number']}</span> </div>
                  <div style="display: flex;align-items:center;margin-top:5px;"> <b>Billing&nbsp;Address:&nbsp;</b> <span>
                          ${invoice['Customer Billing Address']}
                        </span> </div>
                </td>
              </tr>
              <tr>
                <td style="border:1px solid #ccc;border-bottom:unset;">
                  <div style="display: flex;align-items:center;"> <b>PAN&nbsp;:</b> <span>${invoice['Billing Supplier PAN'] ? invoice['Billing Supplier PAN'] :'n/a'}</span> </div>
                  <div style="display: flex;align-items:center;margin-top:10px;"> <b>GST&nbsp;#:&nbsp;</b> <span>${invoice['Billing Supplier GST'] ? invoice['Billing Supplier GST'] :'n/a'}</span> </div>
                </td>
                <td style="border:1px solid #ccc;border-bottom:unset;">
                  <div style="display: flex;align-items:center;"> <b>Shipping&nbsp;Address:&nbsp;</b> <span>${invoice['Customer Shipping Address']}</span> </div>
                </td>
              </tr>
            </tbody>
          </table>
          <div style="max-width:100%">
            <table style="width:100%;border:unset">
              <tbody>
                <tr>
                  <th style="border: 1px solid #ccc;">S.NO.</th>
                  <th style="border: 1px solid #ccc;min-width:180px;">Description</th>
                  <th style="border: 1px solid #ccc;">Unit<br/>Price</th>
                  <th style="border: 1px solid #ccc;width:40px;">Qty</th>
                  <th style="border: 1px solid #ccc;">Net<br/>Amount</th>
                  <th style="border: 1px solid #ccc;width:50px;">Tax<br/>Rate%</th>
                  <th style="border: 1px solid #ccc;">Tax<br/>Type</th>
                  <th style="border: 1px solid #ccc;width:50px;">Tax<br/>Amount</th>
                  <th style="border: 1px solid #ccc;"><b style=" font-weight: 800;">Total<br/>Amount</b></th>
                  <th style="border: 1px solid #ccc;"><b style=" font-weight: 800;">Total<br/>MRP</b></th>
                  <th style=" background: #e0ead9; color: #558b2f;border: 1px solid #ccc;"><b style=" font-weight:800;">Your<br/>Saving</b></th>
                </tr> 
                ${customerOrderListData}
                <tr style="border:1px solid #ccc;">
                  <td colspan="8" style="text-align:right; border:1px solid #ccc;"><b style=" font-weight:800;">Rounding Off</b></td>
                  <td style="text-align: right; border:1px solid #ccc;">${roundingOff} </td>
                  <td style="background-color: #cccccc;border:1px solid #ccc;"></td>
                  <td style="background-color: #cccccc;border-right:unset;border:1px solid #ccc;"></td>
                </tr>
                <tr style="border:1px solid #ccc;">
                  <td colspan="8" style="text-align:right; border:1px solid #ccc;"><b style=" font-weight:800;">Shipping Charge</b></td>
                  <td style="text-align: right; border:1px solid #ccc;">${addPaisa(shippingCharges)} </td>
                  <td style="background-color: #cccccc;border:1px solid #ccc;"></td>
                  <td style="background-color: #cccccc;border-right:unset;border:1px solid #ccc;"></td>
                </tr>
                <tr style="border:1px solid #ccc;">
                  <td colspan="8" style="text-align:right;border:1px solid #ccc;"><b style=" font-weight:800;">Total</b></td>
                  <td style="text-align: right;border:1px solid #ccc;">${addPaisa(totalAmount)}</td>
                  <td style="text-align: right;border:1px solid #ccc;">${addPaisa(totalMRP)}</td>
                  <td style="border-right:unset;text-align: right;border:1px solid #ccc;">${addPaisa(totalSaving)}</td>
                </tr>
              </tbody>
            </table>
          </div>

          <table style="width:100%;border:unset">
            <tbody>
              <tr>
                <td colspan="2" style="border:1px solid #ccc;border-top:unset;">
                  <div style="display:flex; align-items:center;"> <b for="for" style="margin-right:10px;">Amount&nbsp;(In&nbsp;Words):&nbsp;Rupees</b> <span style="text-transform: capitalize;">${numWords(Number(totalAmount).toFixed())} Only</span> </div>
                </td>
              </tr>
              <tr>
                <td colspan="2" style="border:1px solid #ccc;">
                  <div style="display:flex; align-items:center;"> <b for="for" style="margin-right:10px;">For</b> <span>HashTagBazar</span> </div>
                  <div style="display:flex; align-items:center; margin-top:5px;"> <b for="for" style="margin-right:10px;">Mode&nbsp;of&nbsp;Payment</b> <span>
                        ${invoice['Payment Mode']}                    
                        </span> </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div style="margin-top: 30px;"> <b style="font-size: 20px; color: #fb8c00;">
                Congratulations!!!</b> On this order with HashTagBazaar, you saved ₹ <span style="text-align: center;font-weight: 600;color: #43a047;">${totalSaving}</span> or made savings of <span style="text-align: center;font-weight: 600;color: #43a047;">${totalSavingP}</span> % on MRP.
          <br/> For any query, contact Customer Care on:<span style="margin-left:15px;font-weight: 600;">+91 9151777401 / 9151777101</span> </div>
        <p style="text-align:center;font-size: 18px;font-weight:600;margin-top:30px">Thank You! for choosing HashTagBazaar (#Bazaar)</p>
      </main>
    </body>

    </html>
    `;  
    
    toast.loading('PDF Generating...',{toastId:9})
    const convertApi2Pdf  = await a2pClient.wkHtmlToPdf(htmlContent, { inline: true});
    if(convertApi2Pdf.Success==true) {
      toast.dismiss(9);
      toast.success('Successfully! PDF Genereated', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      //DOWNLOAD PD
      const download = (path, fileName) => {
        //Set the File URL.
        var url = path;
           // GENERATE ACCESS TOKEN
           // if(router.query.linkedin_access_token && router.query.profileID) {
           //   cookie.set("linkedin_access_token", router.query.linkedin_access_token);              
           //   cookie.set("profileID", router.query.profileID);  
           //   window.location.href="/certificate"            
           // }
    
        //Create XMLHTTP Request.
        var req = new XMLHttpRequest();
        req.open("GET", url, true);
        req.responseType = "blob";
        req.onload = function () {
            //Convert the Byte Data to BLOB object.
            var blob = new Blob([req.response], { type: "application/octetstream" });
   
            //Check the Browser type and download the File.
            var isIE = false || !!document.documentMode;
            if (isIE) {
                window.navigator.msSaveBlob(blob, fileName);
            } else {
                var url = window.URL || window.webkitURL;
                var link = url.createObjectURL(blob);
                var a = document.createElement("a");
                a.setAttribute("download", fileName);
                a.setAttribute("href", link);
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            }
        };
        req.send();
       }

       download(convertApi2Pdf.FileUrl, `${invoice['Customer Name']} - ${invoice['Date of Invoice']} - #${invoice['Invoice No.']}.pdf`)
    }else{
      toast.error('Sorrry! PDF not genereated', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }


  useEffect(() => {
    if(localStorage.getItem('invoiceList') && localStorage.getItem('saveImportInvoiceList') == 'true'){
      setCsvData(JSON.parse(localStorage.getItem('invoiceList')))
      setLoader(false)
    }else{
      setLoader(false)
    }
  },[])
  

  /* -------------------------------
				CONVERT🙂 "CSV to JSON"
  ----------------------------------*/
  function handleFiles(files) {
    setLoader(true)
    if (window.FileReader) {
      getAsText(files[0]); 
      document.getElementById('uploadFile').value = ''
    } else {
      setLoader(false)
      alert('FileReader are not supported in this browser.');
    }
  }

  function getAsText(fileToRead) {
    var reader = new FileReader()
    reader.onload = loadHandler
    reader.onerror = errorHandler
    fileToRead !== undefined && reader.readAsText(fileToRead); 
  }

  function errorHandler(evt) {
    if (evt.target.error.name == "NotReadableError") {
      alert("Cannot read file !");
      setLoader(false)
    }
  }
    
  function loadHandler(event) {
    var csv = event.target.result;
    CSVToArray(csv);
  }

  function CSVToArray(strData, strDelimiter) {
    strDelimiter = (strDelimiter || ",")
    var objPattern = new RegExp((
    "(\\" + strDelimiter + "|\\r?\\n|\\r|^)" + 
    "(?:\"([^\"]*(?:\"\"[^\"]*)*)\"|" +       
    "([^\"\\" + strDelimiter + "\\r\\n]*))"), "gi") 
    var arrData = [[]]
    var arrMatches = null

    while (arrMatches = objPattern.exec(strData)) {
      var strMatchedDelimiter = arrMatches[1] 
      if (strMatchedDelimiter.length && (strMatchedDelimiter != strDelimiter)) {
          arrData.push([]);
      }
      if (arrMatches[2]) {
          var strMatchedValue = arrMatches[2].replace(
          new RegExp("\"\"", "g"), "\"");
      } else {
          var strMatchedValue = arrMatches[3]
      }
      arrData[arrData.length - 1].push(strMatchedValue)
    }

    var csvHeadings = arrData[0]
    arrData.splice(0,1)

    var csvfinalList = arrData.map((iData,index) => {
      var dataCollect = {};
      iData.map((data,i) => (
        dataCollect[csvHeadings[i]] = data
      ))
      return dataCollect
    })

    csvfinalList = csvfinalList.filter((data,index) => data['in'] !== '')
    
    csvfinalList.map((data,index) => {
      var isDublicate;
      csvfinalList.map((iData,i) => {
        if(data['Invoice No.'] == iData['Invoice No.'] && isDublicate){
          if(!iData['Total Invoice Amount']?.length || typeof iData['Total Invoice Amount'] == 'number' && iData['Total Invoice Amount'] <= 0 || !iData['Total Invoice Amount']) csvfinalList[i]['Total Invoice Amount'] = 0
          csvfinalList[i]['duplicate'] = true
        }
        if(data['Invoice No.'] == iData['Invoice No.']) isDublicate = true
      })  
    })

    setLoader(false)
    if(typeof csvfinalList[0]['Invoice No.'] == 'undefined' || typeof csvfinalList[0]['Customer Name'] == 'undefined'){
      return alert('Buddy! 🙂 Upload Right Invoice File')
    }

    setCsvData(csvfinalList)
    if(saveSheet){
      localStorage.setItem('invoiceList', JSON.stringify(csvfinalList))
      localStorage.setItem('saveImportInvoiceList', 'true')
    }
  }

  //CLEAR SAVED LIST
  function clearInvoiceList(){
    localStorage.removeItem('invoiceList')
    setCsvData('')
    document.getElementById('uploadFile').value = ''
  }


  //PAYMENT LINK GENERATE
  async function createLink(invoice, shipping){
    let allOrders       = csvData.filter(data => data['Invoice No.'] == invoice['Invoice No.'])
    let totalAmount     = allOrders.length == 1 ? removeComma(allOrders[0]['Total Invoice Amount']) :  allOrders.reduce((total,current) => isNaN(total) ? removeComma(total['Total Invoice Amount']) + removeComma(current['Total Invoice Amount']) :  removeComma(total) + removeComma(current['Total Invoice Amount']))
    var confirmShipping = shipping ? window.confirm('😯 Budddy! are you sure?\n\nCreate Payment Link without "Shipping Charge"')  : null
    if(!confirmShipping) shipping = false
    totalAmount         = shipping ? totalAmount : removeComma(totalAmount) < 500 ? removeComma(totalAmount) + 25 : removeComma(totalAmount)

    var bodyData = {
     "orderId"      : `${invoice["Invoice No."]} - r${Math.random().toFixed(3)}`,
     "orderAmount"  : totalAmount,
     "orderCurrency": "INR",
     "customerEmail": invoice["Customer Email"] ? invoice["Customer Email"] : "order@hashtagbazaar.com",
     "customerName" :  invoice["Customer Name"] ? invoice["Customer Name"] : 'HTB Customer',
     "customerPhone": invoice["Customer Mobile Number"] ? invoice["Customer Mobile Number"] : "01234567890",
    }
    
    var response = await axios.post('payments/cashfree/web/create-order', bodyData)
                    .then(res => res.data)
                    .catch(e => toast.error(e?.response?.data?.errors[0]?.message))
    if(response?.success){
      if(!response.success[0]?.genToken?.paymentLink?.length) return toast.error('Sorry! 😭 Link already generated')
      var invoiceList = JSON.parse(localStorage.getItem('invoiceList'))
      invoiceList.map((uData,i) => {
        if(uData['Invoice No.'] != invoice['Invoice No.']) return
        invoiceList[i]['payment_link'] = response.success[0]?.genToken?.paymentLink
      })
      localStorage.setItem('invoiceList', JSON.stringify(invoiceList))
      toast.success('Successfully! Link Generated')
      setCsvData(invoiceList)
    } 
  }


  return (
    <MainLayout>

      {/* BREADCRUMB */} 
			<nav className="breadcrumb mt-3 mb-0"> 
				<div className="cursor-pointer mr-4 text-primary" onClick={history.goBack}><i className='bx bxs-chevron-left'></i> Back</div>
        <div className="mr-auto page_title">Invoice Generator</div>
				<div className="breadcrumb-item" onClick={()=> history.push('/') }>Dashboard</div>
				<div className="breadcrumb-item active">Invoice Generator</div>
			</nav>

      {/* HEADER */}
			<div className="d-flex justify-content-between align-items-center">
        <div className="h4 mt-4 mb-3">Invoice {csvData?.length ? <span className="badge badge-primary ml-2">{csvData.length}</span> : null} </div>
        <div className="d-flex align-items-center">
          <button className="btn btn-white border-5 mr-2 title-top" data-title='Refresh' onClick={()=> window.location.reload()}><i className="bx bx-refresh pt-1 font-size-16"></i></button>
          <div className="form-group w-100 mb-0 d-flex"> 
            <div className="input-icon"> 
              <span className="input-icon-addon"> <i className="fe fe-search" /> </span> 
              <input type="search" onChange={e => setSearch(e.target.value.toLowerCase())} className="form-control ht-40" placeholder="Search Product" /> 
            </div> 

            {csvData && <>
              <button className="btn btn-warning ml-2 tooltip_custom" data-tooltip="Import Another Product CSV" onClick={() => document.getElementById('uploadFile').click()}><i className='bx bx-import font-size-17'></i> Import</button>
              <button className="btn btn-light ml-2" onClick={clearInvoiceList}><i className='bx bxs-trash font-size-16 mr-1'></i> Clear</button>
            </>}
          </div>   
        </div>
      </div>

      <input type="file" className="d-none" id="uploadFile" onChange={e => handleFiles(e.target.files)} accept="text/csv"/>
      <a className='d-none download_pdf_btn' href='#' target='_blank'>download PDF</a>
      <div className="row">
        <div className="col-md-12">
          <div className="card card-body userlist-table">
            
            {!loader && !csvData &&
            <div className='text-center p-5'>
              <button className="btn btn-warning" onClick={() => document.getElementById('uploadFile').click()}><i className='bx bx-import font-size-17'></i> Import Data Sheet</button>
              <label className='mt-3 text-secondary d-block cursor-pointer'><input type='checkbox' defaultChecked={saveSheet} onChange={e => setSaveSheet(e.target.checked == true ? true : false)}/> Save Sheet</label>
              <div className='mt-1 h6 text-secondary'>Hello, <b>Buddy</b> 🙂 I only accept .csv file</div>
            </div>}
            
            {loader && 
            <div className="d-flex justify-content-center flex-column mt-5 mb-3 align-items-center">
              <div>Wait <b>Buddy</b>...</div>
              <div className="spinner-grow" role="status"/>
            </div>}

            {csvData && !loader && 
              <>
                <style>{/* SEARCH */}
                  {`.category_table tbody tr:not([name${search.length ? `*="${search}"`: ''}]){
                      visibility:hidden;
                      position:absolute !important
                  }`}
                </style>
                {search && <div className="mb-2">Search <b className="text-warning">"{search}"</b></div>}

                <table className="table category_table table-hover table-bordered text-center border-0 text-nowrap mb-0">
                  <thead>
                    <tr>
                      <th className='text-center'>#</th>
                      <th>Order Number</th>
                      <th>Order Date</th>
                      <th>Invoice No</th>
                      <th>Customer Id</th>
                      <th>Customer Name</th>
                      <th>Action</th>
                    </tr>
                  </thead>

                  <tbody>
                    {csvData?.map( (pData,i) => <ProductRow  number={i}  createLink={createLink} generatePDF={generatePDF} key={i} id={i} pData={pData}/>)}
                  </tbody>
                </table>
              </>
            }
            
          </div>
        </div>
      </div>
    </MainLayout>
  )
}


function ProductRow({pData, generatePDF, createLink, number}){
  const [paymentLink, setPaymentLink] = useState()
  const [noShipping, setNoShipping]   = useState(false)

  useEffect(() => {
    if(!paymentLink) return
    var copyText = document.getElementById(`payment_link_input${number}`);
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    navigator.clipboard.writeText(copyText.value);
  },[paymentLink])


  return(
    pData?.duplicate ? null :
    <tr name={pData['Customer Name'].toLowerCase()}>
      <td></td>
      <td>{pData['in']}</td>
      <td>{pData['Date of Invoice']}</td>
      <td>{pData['Invoice No.']}</td>
      <td>{pData['Customer ID']}</td>
      <td>{pData['Customer Name']}</td>
      <td>
        <div className='d-flex'>
          <label className='text-left text-secondary tag tag-section mb-2 center c-p'><input type='checkbox' onChange={e => setNoShipping(e.target.checked ? true : false)} className='mr-1'/> <span>No Shipping Charge</span></label></div>
        <div className='text-left'>
          <input type='text' className='d-none' id={`payment_link_input${number}`} defaultValue={paymentLink}/>
          <button onClick={() => generatePDF(pData,noShipping)} className='btn btn-outline-indigo mr-2' data-title='Download Invoice'><i className='bx bxs-download'></i> Invoice</button>
          {!pData?.payment_link ? <button onClick={() => createLink(pData,noShipping)} className='btn btn-outline-warning' data-title='Generate Payment Link'><i className='bx bx-link'></i> Generate Pay Link</button> :null}
          {pData?.payment_link ? <button onClick={e => {setPaymentLink(pData?.payment_link); e.target.innerHTML = `<i class='bx bx-check'></i> Link  Copied`}} className='btn btn-outline-success' data-title='Click to copy link'><i className='bx bx-link'></i> Pay Link</button> :null}
        </div>
      </td>
    </tr>
  )
}


export default InvoiceGenerator