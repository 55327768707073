import axios from "axios";
class CategoryService {

/* -------------------------------
					  CATEGORY
----------------------------------*/
  async categoryList() { //CATEGORY LIST
    return axios
      .get("/category", {
        params: {
          sort: "ASC",
          status:"all"
        }
      })
      .then((response) => {          
        return response.data;
      });
  }

  async addCategory(data) { //ADD CATEGORY
    return axios
      .post("/category", data)      
      .then((response) => {
        return response.data;
      });
  }

  async editCategory(id,data){ //EDIT CATEGORY
    return axios
      .put("/category/"+id, data)      
      .then((response) => {
        return response.data;
      });
  }

  async getCategoryById(id) { //CATEGORY BY ID
    return axios
    .get(`/category/${id}`)      
    .then((response) => {
      return response.data;
    });
  }

  async deleteCategory(catId) { //DELETE CATEGORY
    return axios
      .delete("/category/"+catId)      
      .then((response) => {
        return response.data;
      });
  }

  
/* -------------------------------
					  IMAGE
----------------------------------*/
  async uploadImage(data) { //UPLOAD IMAGE
    return axios
      .post("/file/upload", data)      
      .then((response) => {
        return response.data;
      });
  }

}
export default new CategoryService();
