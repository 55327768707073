import { DPL_LIST,DPL_BY_ID, SET_MESSAGE } from "./types"
import edge from '../services/edge.services'
import { handleErrors } from "../helpers/common-function.helper"



//DPL LIST
export const dplList = () => async (dispatch) => {
  return edge.dplList().then(
    (data) => { dispatch({ type: DPL_LIST, payload: data }) },
    (error) => handleErrors(error, dispatch)
  )
}


//DPL BY ID
export const dplById = (id) => async (dispatch) => {
  return edge.dplById(id).then(
    (data) => { dispatch({ type: DPL_BY_ID, payload: data }) },
    (error) => handleErrors(error, dispatch)
  )
}


//DPL UPDATE
export const dplUpdate = (data) => async (dispatch) => {
  return edge.dplUpdate(data).then(
    (data)  => { dispatch(dplList()) },
    (error) => handleErrors(error, dispatch)
  )
}


//DPL DELETE
export const dplDelete = (id) => async (dispatch) => {
  return edge.dplDelete(id).then(
    (data)  => { dispatch(dplList()) },
    (error) => handleErrors(error, dispatch)
  )
}

