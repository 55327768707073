import React from 'react';

const Footer = () => {    
  return(
    <React.Fragment>
      <div className="main-footer ht-40"> 
        <div className="container-fluid pd-t-0-f ht-100p"> 
          <span>Copyright © {new Date().getFullYear()} <a href="https://www.hashtagbazaar.com/" tabIndex={-1} target="_blank" rel="noreferrer">HashTag Bazaar</a>. All rights reserved.</span>
        </div> 
      </div>

    </React.Fragment>
  )
}

export default Footer;