import { ATTRIBUTES_LIST,ATTRIBUTES_ADD, SET_MESSAGE, ATTRIBUTE_BY_ID, ATTRIBUTE_EDIT } from "./types"
import AttributesServices from '../services/attributes.service'
import { handleErrors } from "../helpers/common-function.helper"



// SHOW ATTRIBUTE
export const attributesListShow = () => async (dispatch) => {
  return AttributesServices.attributesList().then(
    (data)  => { dispatch({ type:ATTRIBUTES_LIST, payload:data }) },
    (error) => handleErrors(error, dispatch)
  )
}


// ADD ATTRIBUTE
export const addAttribute = (data) => async (dispatch) => {
  return AttributesServices.addAttribute(data).then(
    (data)  => { dispatch(attributesListShow());  },
    (error) => handleErrors(error, dispatch)
  )
}


// DELETE ATTRIBUTE
export const deleteAttribute = (id) => async (dispatch) => {
  return AttributesServices.deleteAttribute(id).then(
    (data)  => { dispatch(attributesListShow()); },
    (error) => handleErrors(error, dispatch)
  );
}


// ATTRIBUTE BY ID
export const getAttributeById = (id) => async (dispatch) => {
  return AttributesServices.getAttributeById(id).then(
    (data)  => { dispatch({ type: ATTRIBUTE_BY_ID, payload: data, }) },
    (error) => handleErrors(error, dispatch)
  )
}


// ATTRIBUTE EDIT
export const updateAttribute = (id, data) => async (dispatch) => {
  return AttributesServices.editAttribute(id, data).then(
    (res)   => { dispatch({ type:SET_MESSAGE,payload:res }); dispatch(attributesListShow()) },
    (error) => handleErrors(error, dispatch)
  );
};
