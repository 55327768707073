import { 
  CLUBBED_BUYING_CATEGORY_LIST, 
  CLUBBED_BUYING_CLEAR_CATEGORY, 
  CLUBBED_BUYING_CATEGORY_BY_ID,

  CLUBBED_BUYING_ORDERS,

  CLUBBED_BUYING_SOCIETY_LIST,
  CLUBBED_BUYING_SOCIETY_BY_ID,
  
  CLUBBED_BUYING_BANNER_LIST, 
  CLUBBED_BUYING_BANNER_BY_ID
} from "../actions/types";

const initialState = {
  clubbedBuying:{
    categoriesList:null,
    categoryById:null,

    societyList:null,
    societyById:null,

    bannerList:null,
    bannerById:null,

    orders:null,
  }
}

export default function foo(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CLUBBED_BUYING_CATEGORY_LIST:
      return {
        clubbedBuying:{
          ... state.clubbedBuying,
          categoriesList:payload,
        },
    }; 
    case CLUBBED_BUYING_CLEAR_CATEGORY:
      return {
        clubbedBuying:{
          ... state.clubbedBuying,
          categoriesList:null
        }
    }; 
    case CLUBBED_BUYING_CATEGORY_BY_ID:
      return {
        clubbedBuying:{
          ... state.clubbedBuying,
          categoryById:payload,
        }
    }; 
    case CLUBBED_BUYING_ORDERS:
      return {
        clubbedBuying:{
          ... state.clubbedBuying,
          orders:payload,
        }
    };   
    case CLUBBED_BUYING_SOCIETY_LIST:
      return {
        clubbedBuying:{
          ... state.clubbedBuying,
          societyList:payload,
        },
    }; 
    case CLUBBED_BUYING_SOCIETY_BY_ID:
      return {
        clubbedBuying:{
          ... state.clubbedBuying,
          societyById:payload,
        }
    }; 

    case CLUBBED_BUYING_BANNER_LIST:
      return {
        clubbedBuying:{
          ... state.clubbedBuying,
          bannerList:payload,
        }
    }; 

    case CLUBBED_BUYING_BANNER_BY_ID:
      return {
        clubbedBuying:{
          ... state.clubbedBuying,
          bannerById:payload,
        }
    }; 
    default:
      return state;
  }
}