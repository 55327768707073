import axios from "axios"


class edge{

  //-------- DPL
  async dplList(){ return axios.get('/edge/dpl/product/price-log').then(r => r.data) }
  async dplById(id){ return axios.get('/edge/dpl/product/price-log/'+id).then(r => r.data) } 
  async dplUpdate(data){ return axios.put('/edge/dpl/product/update-price?role=superadmin', data).then(r => r.data) } 
  async dplDelete(Id){ return axios.delete("/edge/dpl/product/price-log/"+Id).then(r => r.data) }

}
export default new edge()