import React, { useEffect, useState } from 'react'
import { connect } from "react-redux"
import { useHistory } from "react-router-dom"
import SunEditor from 'suneditor-react'
import 'suneditor/dist/css/suneditor.min.css' 
import { getEmailByName, updateEmailTemplate } from '../../../actions/message-templates.action'
import MainLayout from '../../layouts/main.component'
import { toast } from 'react-toastify'

const EditEmail = ({dispatch, emailByName, ...props}) => {

  const history                   = useHistory()
  const [btnLoader, setBtnLoader] = useState(false)

  useEffect(() => {
    document.title  = `Edit Email | ${emailByName?.name}`
  },[emailByName])

  useEffect(() => {
    dispatch(getEmailByName(props.match.params.name))
  },[dispatch])

  const onSubmit = async (id, e) => { 
    e.preventDefault()
    const formData = new FormData(e.target)
    const data = {}
    for (let entry of formData.entries()) {
        data[entry[0]] = entry[1]
    } 
    await toast.promise(dispatch(updateEmailTemplate(id, data)),{
      pending: 'Updating Template...',
      success: 'Successfully! Template Updated',
      error: {
        render(){
          setBtnLoader(false)
          return 'Template not updated'
        }
      }
    })  
    setTimeout(async () => await history.push('/message-template/email/view/'+encodeURI(data.name)), 1000)
  }

  return (
    <React.Fragment>
      <MainLayout>

        {/* BREADCRUMB */}             
        <nav className="breadcrumb mt-3 mb-0"> 
          <div className="cursor-pointer mr-4 text-primary" onClick={history.goBack}><i className='bx bxs-chevron-left'></i> Back</div>
          <div className="mr-auto page_title">Email Template</div>
          <div className="breadcrumb-item" onClick={()=> history.push('/')}>Dashboard</div>
          <div className="breadcrumb-item" onClick={()=> history.push('/message-template/email/list')}>Email Templates</div>
          <div className="breadcrumb-item active">Edit</div>
        </nav>


        {/* HEADER */}
        <div className="d-flex justify-content-between align-items-center">
          <div className="h4 mt-4 mb-3 d-flex align-items-center">Edit Template</div>
          <button disabled={btnLoader} className="btn btn-primary" type="submit" onClick={() => document.querySelector('.update_templete_btn').click()}>
            {!btnLoader ? 'Update Template' : <span className="spinner-border spinner-border-sm mx-5"></span>}
          </button>
        </div>

        {emailByName ?
          <div className='card card-body animated fadeIn'>
            <form onSubmit={ (e) => {setBtnLoader(true); onSubmit(emailByName.id, e)}}>  

              <button className='d-none update_templete_btn' type='sumbit'>Submit Form</button> 

              <div className="row">
                <div className="col-md-4">
                  <div className="input_wrap w-100" data-title='Sorry! 🙂 I am not editable'>
                    <input name='name' readOnly={true} defaultValue={emailByName.name} type="text" className='disabled'  placeholder=" "/>
                    <label>Name</label>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="input_wrap w-100">
                    <input name='sender_name' defaultValue={emailByName.sender_name} type="text"  placeholder=" "/>
                    <label>Sender Name</label>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="input_wrap w-100">
                    <input name='sender_email' defaultValue={emailByName.sender_email} type="text"  placeholder=" "/>
                    <label>Sender Email</label>
                  </div>
                </div>

                <div className="col-md-12 mt-3">
                  <div className="input_wrap w-100">
                    <input name='subject' defaultValue={emailByName.subject} type="text"  placeholder=" "/>
                    <label>Subject</label>
                  </div>
                </div>

                <div className="col-md-12 mt-3">
                  <div className="form-group mb-0"> 
                    <label className="main-content-label tx-11 m-0">Content</label> 
                  </div>
                  <SunEditor name="content"  defaultValue={emailByName.content} lang="en" height="200" />
                </div>

              </div>
            </form> 
          </div> 
        :
          <div className="d-flex justify-content-center flex-column mt-5 mb-3 align-items-center">
            <div>Wait <b>Buddy</b>...</div>
            <div className="spinner-grow" role="status"/>
          </div>
        }
      </MainLayout>
    </React.Fragment>
  )
}

function mapStateToProps(state) {
  const { emailByName }     = state.messageTemplate.messageTemplate;

  return {
    emailByName
  };
}

export default connect(mapStateToProps)(EditEmail);