import axios from "axios"


class usersService {

  //------------- USER
  async userList(start = 0, limit = 25, sortBy = 'desc', roleShow = 'customer'){       
    return axios.get(`/users/list/${roleShow}?limit=${limit}&sort=${sortBy}&start=${start}`).then(r => r.data) 
  }

  async userById(id){         return axios.get("/users/"+id).then(r => r.data)        }
  async addUser(data){        return axios.post("/users", data).then(r => r.data)     }
  async updateUser(id, data){ return axios.put("/users/"+id, data).then(r => r.data)  }
  async deleteUser(Id){       return axios.delete("/users/"+Id).then(r => r.data)     }
  async searchCustomer(q,r){  return axios.get(`/users/search/${q}?role=${r}`).then(r => r.data)}
  async customerAddress(id){  return axios.get("/users/address/"+id).then(r => r.data)}


  //---------------- FOR SELLER
  async sellerList() {         return axios.get("/users/seller").then(r => r.data)           }
  async addSeller(data){       return axios.post("/users", data).then(r => r.data)           }
  async updateSeller(id,data){ return axios.put("/users/seller/"+id, data).then(r => r.data) }
  async deleteSeller(id){      return axios.delete("/users/seller/"+id).then(r => r.data)    }

}
export default new usersService()
