import {LOGIN_SUCCESS, CLEAR_CATEGORY, LOGIN_FAIL, LOGOUT, SET_MESSAGE, CURRENT_USER, NOT_AUTHORISED } from "./types"
import AuthService from "../services/auth.service"
import { handleErrors, encryptWithAES } from "../helpers/common-function.helper"


//LOGIN
export const login = (email, password) => async (dispatch) => {
  return AuthService.login(email, password).then(
    (data)  => {
      /* dispatch({ type: LOGIN_SUCCESS, payload: data, })*/ 
      localStorage.setItem('utc', encryptWithAES(password))
    },
    (error) => { dispatch({ type: LOGIN_FAIL}); handleErrors(error, dispatch) }
  );
};


//USER INFO
export const currentUser = () => async (dispatch) => {
  return AuthService.currentUser().then(
    (data)  => { dispatch({ type: CURRENT_USER, payload: data, }) },
    (error) => { dispatch({ type: NOT_AUTHORISED}); handleErrors(error, dispatch)}
  )
}


//LOGOUT
export const logout = () => (dispatch) => {
  // AuthService.logout(); dispatch({ type: LOGOUT, payload: data });
  return AuthService.logout().then(
    (data)  => { dispatch({ type: LOGOUT, payload: data }); dispatch({ type: CLEAR_CATEGORY, })},
    (error) => handleErrors(error, dispatch)
  );
};