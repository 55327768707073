import {useEffect, useState} from 'react'
import MainLayout from '../layouts/main.component'
import {useHistory} from 'react-router-dom'
import { toast } from 'react-toastify'
import axios from 'axios'
import {handleErrors} from '../../helpers/common-function.helper'
import { connect } from 'react-redux'

//IMAGE - "FilePond"
import { FilePond, File, registerPlugin } from 'react-filepond'
import "filepond/dist/filepond.min.css"
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation"
import FilePondPluginImagePreview from "filepond-plugin-image-preview"
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css"
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview, FilePondPluginFileValidateType, FilePondPluginFileValidateSize)


function WhatsappTool({dispatch}) {
  const key = '2c895c59ac5b4816a4b5fbdfed72f327'
  const [loader, setLoader] = useState()
  const history = useHistory()
  const [csvData, setCsvData] = useState([])
  const [isMounted, setIsMounted] = useState(false)
  const [customerList, setcustomerList] = useState([])
  const [msgText, setMsgText] = useState('')
  const [finalCustomerList, setFinalCustomerList] = useState([])
  const [msgReport, setMsgReport] = useState([])
  const [ignore, setIgnore] = useState([])
  const [imageList, setImageList] = useState([])
  const [pdfList, setPdfList] = useState([])
  const [imageUrl, setImageUrl] = useState([])
  const [pdfUrl, setPdfUrl] = useState([])


  // FUNCTION ==> UPLODED IMAGE TO SERVER TO GET URL
	async function uploadImage(data, type){
		toast.loading(<>Wait! Image Uploading ...</>, { toastId:'uploading' })
		
		data.map(async (iData, index) => {
			var imgInfo = new FormData();
			imgInfo.append('file', iData.file);
			imgInfo.append('foldername', 'marketing');

			if(iData.fileSize > 1000000){ //CHECK SIZE
				return toast.error(<>😢 Image size greater than 1MP </>)
			}
			
		  await axios.post("/file/upload", imgInfo) //UPLOAD TO SERVER 
			.then((response) => {
				if(type == 'image') setImageUrl(prevValue => ([...prevValue, response.data]))
        else if(type == 'pdf') setPdfUrl(prevValue => ([...prevValue, response.data]))
			}).catch(err =>{	//CATCH ERROR
				if(typeof err?.response?.data != 'string'){
					return handleErrors(err, dispatch)
				}else{
					return toast.error(<>Something went wrong with image.</>)
				}
			})
		})
		setTimeout(() => toast.dismiss('uploading'),1000)
	}


  /* -------------------------------
				CONVERT🙂 "CSV to JSON"
  ----------------------------------*/
  function handleFiles(files) {
    setLoader(true)
    if (window.FileReader) {
      getAsText(files[0]); 
      document.getElementById('uploadFile').value = ''
    } else {
      setLoader(false)
      alert('FileReader are not supported in this browser.');
    }
  }

  function getAsText(fileToRead) {
    var reader = new FileReader()
    reader.onload = loadHandler
    reader.onerror = errorHandler
    fileToRead !== undefined && reader.readAsText(fileToRead); 
  }

  function errorHandler(evt) {
    if (evt.target.error.name == "NotReadableError") {
      alert("Cannot read file !");
      setLoader(false)
    }
  }
    
  function loadHandler(event) {
    var csv = event.target.result;
    CSVToArray(csv);
  }

  function CSVToArray(strData, strDelimiter) {
    strDelimiter = (strDelimiter || ",")
    var objPattern = new RegExp((
    "(\\" + strDelimiter + "|\\r?\\n|\\r|^)" + 
    "(?:\"([^\"]*(?:\"\"[^\"]*)*)\"|" +       
    "([^\"\\" + strDelimiter + "\\r\\n]*))"), "gi") 
    var arrData = [[]]
    var arrMatches = null

    while (arrMatches = objPattern.exec(strData)) {
      var strMatchedDelimiter = arrMatches[1] 
      if (strMatchedDelimiter.length && (strMatchedDelimiter != strDelimiter)) {
          arrData.push([]);
      }
      if (arrMatches[2]) {
          var strMatchedValue = arrMatches[2].replace(
          new RegExp("\"\"", "g"), "\"");
      } else {
          var strMatchedValue = arrMatches[3]
      }
      arrData[arrData.length - 1].push(strMatchedValue)
    }

    var csvHeadings = arrData[0]
    arrData.splice(0,1)

    var csvfinalList = arrData.map((iData,index) => {
      var dataCollect = {};
      iData.map((data,i) => (
        dataCollect[csvHeadings[i]] = data
      ))
      return dataCollect
    })

    setLoader(false)
    setCsvData(csvfinalList)
  }


  // SENT MESSAGE
  function sendMessage(){
    let finalList = document.querySelector('#number_list_textarea').value
    if(!finalList) return toast.error('Add Mobile Number')
    finalList = finalList.split(',')
    finalList = finalList.map(data => ({
      message_sent: false,
      mobile: data.replaceAll(' ', '')
    }))
    setFinalCustomerList(finalList)
  }


  useEffect(() => {
    setInterval(() => setIgnore(Math.random()), 1000)
    if(!isMounted) return
    if(!csvData.length) return 
    document.querySelector('[href="#message_box"]').click()
    const formateCustomerList = csvData.map(data => ({
      name: data['CUSTOMER NAME'],
      message_sent: false,
      mobile: data['MOBILE NUMBER']
    }))
    setcustomerList(formateCustomerList)
  }, [csvData])


  useEffect(async() => {
    if(!isMounted) return
    if(!msgText.length) return toast.error('Add Message')
    toast.loading('Sending Message', {toastId:'sendingMessage'})
    document.querySelector('.send_message_btn').remove()
    var sendTo = 0;
    var customerLenth = finalCustomerList.length
    localStorage.setItem('whatsapp_customer_list', JSON.stringify(finalCustomerList))
    localStorage.setItem('whatsapp_message', msgText)
    setMsgReport(finalCustomerList)
    if(imageList.length) await uploadImage(imageList, 'image')
    if(pdfList.length) await uploadImage(pdfList, 'pdf')
    

    var checkFileUpload = setInterval(() => {
      let imageUrlLength, pdfUrlLength, imageUrlData, pdfUrlData;
      setImageUrl(data => {imageUrlLength = data.length; imageUrlData = data; return data})
      setPdfUrl(data => {pdfUrlLength = data.length; pdfUrlData = data; return data})

      if(imageUrlLength == imageList.length && pdfUrlLength == pdfList.length){
        clearInterval(checkFileUpload)
        async function messageSend(number, message, duration){
          if(number.toString().length > 9 && number.toString().length < 14){
            const response = await fetch(`http://wapi.tagdigitalsolutions.com/wapp/api/send?apikey=${key}&mobile=${number}&msg=${encodeURI(message)}${imageUrlData.length ? `&${imageUrlData.map((data,i) => `img${i+1}=${data}`).join('&')}`: ''}${pdfUrlData.length ? `&${pdfUrlData.map((data,i) => `pdf=${data}`).join('&')}`: ''}`)
            const data = await response.json()
            if(data.statuscode === 200){
              let updatedList;
              setMsgReport(msg => {msg[sendTo].message_sent = true; updatedList = msg; return msg})
              localStorage.setItem('whatsapp_customer_list', JSON.stringify(updatedList))
            }else{
              toast.error(<>Message not sent: <b>{number}</b></>,{autoClose: false})
            }
          }else{
            toast.error(<>Message not sent: <b>{number}</b></>,{autoClose: false})
          }
          
          duration = parseInt((`${duration}000`))
          sendTo++
          if(sendTo < customerLenth){
            setTimeout(() => {
              messageSend(finalCustomerList[sendTo].mobile, msgText, parseInt(Math.random() * (10 - 1) + 1))
            }, duration);
          }else{
            toast.success('Successfully! task completed.', {
              toastId:'message',
              position: "top-right",
              autoClose: false,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
              progress: undefined,
            });
            toast.dismiss('sendingMessage')
          }
        } 
    
        messageSend(finalCustomerList[sendTo].mobile, msgText, 2)
      }
    }, 500)
  }, [finalCustomerList])

  useEffect(() => {
    if(!localStorage.getItem('whatsapp_customer_list')) return
    let list = JSON.parse(localStorage.getItem('whatsapp_customer_list'))
    if(!list.length) return
    let pendingCustomer = list.filter(customer => customer.message_sent == false)
    if(!pendingCustomer.length) return
    document.querySelector('[href="#message_box"]').click()
    setcustomerList(pendingCustomer)
    setMsgText(localStorage.getItem('whatsapp_message'))
    setMsgReport(list)
    setTimeout(()=> window.scrollTo(0, document.body.scrollHeight), 500)
  }, [])


  useEffect(() => setIsMounted(true), [])
  return (
    <MainLayout>
      {/* BREADCRUMB */} 
			<nav className="breadcrumb mt-3 mb-0"> 
				<div className="cursor-pointer mr-4 text-primary" onClick={history.goBack}><i className='bx bxs-chevron-left'></i> Back</div>
        <div className="mr-auto page_title">Whatsapp Tool</div>
				<div className="breadcrumb-item" onClick={()=> history.push('/') }>Dashboard</div>
				<div className="breadcrumb-item active">Whatsapp Tool</div>
			</nav>


      {/* HEADER */}
			<div className="d-flex justify-content-between align-items-center">
        <div className="h4 mt-4 mb-3">Whatsapp</div>
        <div className="d-flex align-items-center">
          <button className="btn btn-white border-5 mr-2 title-top" data-title='Refresh' onClick={()=> window.location.reload()}><i className="bx bx-refresh pt-1 font-size-16"></i></button>
          <button className='btn btn-primary center send_message_btn'  onClick={sendMessage}><i className='bx bxl-whatsapp font-size-18 mr-1'></i> Send Message</button> 
        </div>
      </div>

      <div className="row mb-4">
        <div className="col-md-12">
          <div className="panel panel-primary p-0 product_tab card_layout" id="product_info_box">
            <div className=" tab-menu-heading  tab-border border-bottom shadow-base position-relative z-index-1">
              <div className="tabs-menu1">
                <ul className="nav panel-tabs main-nav-line">
                  <li><div href="#import_box" className="nav-link pl-4 pr-4 active cursor-pointer" data-toggle="tab">Import Customer</div></li>
                  <li><div href="#message_box" className="nav-link pl-4 pr-4 cursor-pointer" data-toggle="tab">Direct Message</div></li>
                </ul>
              </div>
            </div>

            <div className="panel-body p-0 border-0 mt-0 tabs-menu-body main-content-body-right border">
              <div className="tab-content variation_tab overflow-unset">
                <div className="tab-pane active" id="import_box">
                  <div className='p-5 my-3 center flex-column'>
                    {loader ? 
                      <div className="d-flex justify-content-center flex-column mt-5 mb-3 align-items-center">
                        <div>Wait <b>Buddy</b>...</div>
                        <div className="spinner-grow" role="status"/>
                      </div> 
                    :
                      <>
                        <input type="file" className="d-none" id="uploadFile" onChange={e => handleFiles(e.target.files)} accept="text/csv"/>
                        <button className="btn btn-warning" onClick={() => document.getElementById('uploadFile').click()}><i className='bx bx-import font-size-17'></i> Import Customer </button>
                        <div className='mt-3 h6 text-secondary'><b>Buddy</b> 🙂 I only accept .csv file</div>
                      </>
                    }
                  </div>
                </div>
                <div className="tab-pane p-5" id="message_box">
                  <div className='row'>
                    <div className='col-md-6'>
                      <span className='text-secondary font-size-14'>Customer List {customerList.length > 0 && <span className='badge badge-primary-transparent ml-2 mb-2 d-inline position-relative t--5'>{customerList.length}</span>}</span>
                      <textarea className='form-control' id='number_list_textarea' rows={6}  defaultValue={customerList.map((customer, index) => customer.mobile).join(', ')}></textarea>
                    </div>
                    <div className='col-md-6'>
                      <div>
                        <span className='text-secondary font-size-14'>Message</span>
                        <textarea className='form-control' defaultValue={msgText} rows={6} onChange={e => setMsgText(e.target.value)}/>
                      </div>
                    </div>

                    <div className="col-md-6 other-images mt-3">
                      <div className="text-center font-size-16 text-black-50"><small>Add Image</small></div>
                      <FilePond
                        files={imageList}
                        allowReorder={true}
                        onupdatefiles={(e) => setImageList(e)}
                        allowMultiple={true}
                        maxFiles={4}
                        maxFileSize={'1MB'}
                        acceptedFileTypes={['image/png', 'image/jpeg', 'image/jpg']}
                        labelIdle='Drag & Drop Image or <span class="filepond--label-action">Browse</span>'
                      />
                    </div>
                    <div className="col-md-6 other-images mt-3">
                      <div className="text-center font-size-16 text-black-50"><small>Add PDF</small></div>
                      <FilePond
                        files={pdfList}
                        allowReorder={true}
                        onupdatefiles={(e) => setPdfList(e)}
                        allowMultiple={true}
                        maxFiles={4}
                        maxFileSize={'1MB'}
                        acceptedFileTypes={['application/pdf']}
                        labelIdle='Drag & Drop Image or <span class="filepond--label-action">Browse</span>'
                      />
                    </div>

                  </div>
                  {msgReport.length > 0 && 
                    <table className="table table-hover mt-5 table-bordered text-center category_table border-0 text-nowrap mb-0">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Number</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {msgReport.map((message, index) => (
                          <tr key={index}>
                            <td></td>
                            <td>{message.mobile}</td>
                            <td>{message.message_sent ? <span className='tag tag-azure'>success</span> : <span className='tag tag-gray'>pending</span>}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  }
                </div>
              </div>
            </div>

          </div>
        </div>
      </div> 
    </MainLayout>
  )
}

function mapStateToProps(state){
  return{}
}

export default connect(mapStateToProps)(WhatsappTool)