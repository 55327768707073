import { CLUBBED_BUYING_ORDER_LIST, EXPORT_CLUBBED_BUYING_ORDER_LIST, CLEAR_ORDER_BY_ID, SEARCH_ORDER, CLUBBED_BUYING_ORDER_EDIT, CLUBBED_BUYING_ORDER_DELETE, SET_MESSAGE, CLUBBED_BUYING_ORDER_BY_ID, LOGS_LIST } from "../actions/types"
 
const initialValue = {
  clubbedBuyOrderList:null,
  clubbedBuyOrderById:null,
  clubbedBuyOrderSearch:null,
  clubbedBuyLogsList:null,
  exportOrderList:null
}

export default function foo(state = initialValue, action){
  const {type, payload} = action
  
  switch(type){
    case CLUBBED_BUYING_ORDER_LIST:
      return{
        ... state,
        clubbedBuyOrderList: payload
      }
    case EXPORT_CLUBBED_BUYING_ORDER_LIST:
      return{
        ... state,
        exportOrderList: payload
      }
    case CLUBBED_BUYING_ORDER_BY_ID:
      return{
        ...state,
        clubbedBuyOrderById: payload
      }
    case LOGS_LIST:
      return{
        ...state,
        clubbedBuyLogsList: payload
      }
    case CLEAR_ORDER_BY_ID:
      return{
        ... state,
        clubbedBuyOrderById: null
      }
    case SEARCH_ORDER:
      return{
        ... state,
        clubbedBuyOrderSearch: payload
      }
    default:
      return state
  }
}