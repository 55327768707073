import {LOGIN_SUCCESS, LOGIN_FAIL, LOGOUT, CURRENT_USER, NOT_AUTHORISED } from "../actions/types";
import Cookies from "js-cookie";
const initialState = { currentUser:null }

export default function foo(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        currentUser: payload,
      };
    case LOGIN_FAIL:
      return {
        ...state,
        currentUser: null,
      };    
    case CURRENT_USER:
      return {
        ...state,
        currentUser: payload,
      }; 
    case LOGOUT:
      return {
        ...state,
        currentUser: null,
      }; 
    case NOT_AUTHORISED:
      return {
        ...state,
        currentUser: false,
      }; 
    default:
      return state;
  }
}