import axios from "axios";
class ClubbedBuyingService {

/* -------------------------------
					  CATEGORY
----------------------------------*/
  async clubbedBuyingCategoryList() { //CATEGORY LIST
    return axios
      .get("clubbed-buying/category", {
        params: {
          sort: "ASC"
        }
      })
      .then((response) => {          
        return response.data;
      });
  }

  async clubbedBuyingAddCategory(data) { //CATEGORY ADD
    return axios
      .post("clubbed-buying/category", data)      
      .then((response) => {
        return response.data;
      });
  }

  async clubbedBuyingEditCategory(data,catId) { //CATEGORY EDIT
    return axios
      .put("clubbed-buying/category/"+catId, data)      
      .then((response) => {
        return response.data;
      });
  }

  async clubbedBuyingCategoryById(catId) { //CATEGORY BY ID
    return axios
      .get("clubbed-buying/category/"+catId)      
      .then((response) => {
        return response.data;
      });
  }

  async clubbedBuyingDeleteCategory(catId) { //CATEGORY DELETE
    return axios
      .delete("/clubbed-buying/category/"+catId)      
      .then((response) => {
        return response.data;
      });
  }


/* -------------------------------
					  ORDERS
----------------------------------*/
  async clubbedBuyingOrders() { //ORDERS LIST
    return axios
      .get('clubbed-buying/admin/order')      
      .then((response) => {
        return response.data;
      });
  }


/* -------------------------------
					  IMAGE
----------------------------------*/
  async uploadImage(data) { //IMAGE UPLOAD
    return axios
      .post("/file/upload", data)      
      .then((response) => {
        return response.data;
      });
  }


/* -------------------------------
					  SOCIETY
----------------------------------*/
  async clubbedBuyingSocietyList() { //SOCIETY LIST
    return axios
      .get("clubbed-buying/society", {
        params: {
          sort: "asc",
          status:'all'
        }
      })
      .then((response) => {          
        return response.data;
      });
  }

  async clubbedBuyingSocietyById(id) { //SOCIETY BY ID
    return axios
      .get("clubbed-buying/society/"+id)      
      .then((response) => {
        return response.data;
      });
  }

  async clubbedBuyingAddSociety(data) { //SOCIETY ADD
    return axios
      .post("clubbed-buying/society", data)      
      .then((response) => {
        return response.data;
      });
  }

  async clubbedBuyingEditSociety(data,id) { //SOCIETY EDIT
    return axios
      .put("clubbed-buying/society/"+id, data)      
      .then((response) => {
        return response.data;
      });
  }

  async clubbedBuyingDeleteSociety(id) { //SOCIETY DELETE
    return axios
      .delete("/clubbed-buying/society/"+id)      
      .then((response) => {
        return response.data;
      });
  }


/* -------------------------------
					  BANNER
----------------------------------*/
  async clubbedBuyingBannerList(){ //BANNER LIST
    return axios
      .get("/clubbed-buying/banner?status=all")
      .then(response => {
        return response.data;
      })
  }

  async clubbedBuyingBannerById(id) { //SOCIETY BY ID
    return axios
      .get("/clubbed-buying/banner/"+id)      
      .then((response) => {
        return response.data;
      });
  }

  async clubbedBuyingBannerAdd(data) { //BANNER ADD
    return axios
      .post("clubbed-buying/banner", data)      
      .then((response) => {
        return response.data;
      });
  }

  async clubbedBuyingBannerEdit(data,id) { //BANNER EDIT
    return axios
      .put("clubbed-buying/banner/"+id, data)      
      .then((response) => {
        return response.data;
      });
  }

  async clubbedBuyingBannerDelete(id) { //BANNER DELETE
    return axios
      .delete("/clubbed-buying/banner/"+id)      
      .then((response) => {
        return response.data;
      });
  }

}

export default new ClubbedBuyingService();
