export const paginationLimit = [25, 50, 75, 100]
export const roles = [
  {name:'customer', label:'Customer'},
  {name:'admin', label:'Admin'},
  {name:'operation', label:'Operation'},
  {name:'marketing', label:'Marketing'},
  {name:'finance', label:'Finance'},
  {name:'hr', label:'HR'},
  {name:'logistics', label:'Logistics'},
  {name:'technology', label:'Technology'},
  {name:'affiliate', label:'Affiliate'}
]


export const modulesList = [
  {
    module_label: <><i className='bx bx-store'></i> <span>Product</span></>,
    module_name:  'product',
    moduel_enable: false,
    components: [
        {name:'add',       default:['admin', 'technology'], label:<>Add</>},
        {name:'edit',      default:['admin', 'technology'], label:<>Edit</>},
        {name:'delete',    default:['admin', 'technology'], label:<>Delete</>},
        {name:'view',      default:['admin', 'technology', 'operation'], label:<>View</>, disabled: true},
        {name:'import',    default:['admin', 'technology'], label:<>Import</>},
        {name:'all',       default:['admin', 'technology'], label:<>View All</>},
        {name:'pending',   default:['admin', 'technology'], label:<>View Pending</>},
        {name:'published', default:['admin', 'technology'], label:<>View Published</>},
        {name:'review',    default:['admin', 'technology'], label:<>View Review</>},
        {name:'drafts',    default:['admin', 'technology'], label:<>View Drafts</>},
      ]
  },
  {
    module_label: <><i className='bx bx-cart'></i> <span>Clubbed Order</span></>,
    module_name:  'clubbed_order',
    moduel_enable: false,
    components: [
        {name:'add',    default:['admin', 'technology'], label:<>Add</>},
        {name:'edit',   default:['admin', 'technology', 'operation'], label:<>Edit</>},
        {name:'delete', default:['admin', 'technology', 'operation'], label:<>Delete</>},
        {name:'view',   default:['admin', 'technology', 'operation'], label:<>View</>},
        {name:'invoice_generator',   default:['admin', 'technology', 'operation'], label:<>Invoice Generator</>},
      ]
  },
  {
    module_label: <><i className='bx bx-user'></i> <span>User</span></>,
    module_name:  'user',
    moduel_enable: false,
    components: [
        {name:'add',    default:['admin', 'technology'], label:<>Add</>},
        {name:'edit',   default:['admin', 'technology'], label:<>Edit</>},
        {name:'delete', default:['admin', 'technology'], label:<>Delete</>},
        {name:'view',   default:['admin', 'technology'], label:<>View All</>},
        {name:'view_customer',   default:['admin', 'technology', 'operation'], label:<>View Customer</>},
      ]
  },
  {
    module_label: <><i className='bx bxs-network-chart' ></i> <span>Attribute</span></>,
    module_name:  'attribute',
    moduel_enable: false,
    components: [
        {name:'add',    default:['admin', 'technology'], label:<>Add</>},
        {name:'edit',   default:['admin', 'technology'], label:<>Edit</>},
        {name:'delete', default:['admin', 'technology'], label:<>Delete</>},
        {name:'view',   default:['admin', 'technology', 'operation'], label:<>View</>},
      ]
  },
  {
    module_label: <><i className="fab fa-buffer"></i> <span>Category</span></>,
    module_name:  'category',
    moduel_enable: false,
    components: [
        {name:'add',    default:['admin', 'technology'], label:<>Add</>},
        {name:'edit',   default:['admin', 'technology'], label:<>Edit</>},
        {name:'delete', default:['admin', 'technology'], label:<>Delete</>},
        {name:'view',   default:['admin', 'technology', 'operation'], label:<>View</>},
      ]
  },
  {
    module_label: <><i className='bx bx-image'></i> <span>Banner</span></>,
    module_name:  'banner',
    moduel_enable: false,
    components: [
        {name:'add',    default:['admin', 'technology'], label:<>Add</>},
        {name:'edit',   default:['admin', 'technology'], label:<>Edit</>},
        {name:'delete', default:['admin', 'technology'], label:<>Delete</>},
        {name:'view',   default:['admin', 'technology'], label:<>View</>},
      ]
  },
  {
    module_label: <><i className='bx bx-layout'></i> <span>Email Template</span></>,
    module_name:  'email_template',
    moduel_enable: false,
    components: [
        {name:'add',    default:[''], label:<>Add</>},
        {name:'edit',   default:[''], label:<>Edit</>},
        {name:'delete', default:[''], label:<>Delete</>},
        {name:'view',   default:[''], label:<>View</>},
      ]
  },
  {
    module_label: <><i className='bx bx-message-square-detail'></i> <span>SMS Template</span></>,
    module_name:  'sms_template',
    moduel_enable: false,
    components: [
        {name:'add',    default:[''], label:<>Add</>},
        {name:'edit',   default:[''], label:<>Edit</>},
        {name:'delete', default:[''], label:<>Delete</>},
        {name:'view',   default:[''], label:<>View</>},
      ]
  },
  {
    module_label: <><i className='bx bx-bell'></i> <span>Firebase Notification Template</span></>,
    module_name:  'firebase_notification_template',
    moduel_enable: false,
    components: [
        {name:'add',    default:[''], label:<>Add</>},
        {name:'edit',   default:[''], label:<>Edit</>},
        {name:'delete', default:[''], label:<>Delete</>},
        {name:'view',   default:[''], label:<>View</>},
      ]
  },
  {
    module_label: <><i className='bx bx-buildings'></i> <span>Society</span></>,
    module_name:  'society',
    moduel_enable: false,
    components: [
        {name:'add',    default:['admin', 'technology'], label:<>Add</>},
        {name:'edit',   default:['admin', 'technology'], label:<>Edit</>},
        {name:'delete', default:['admin', 'technology'], label:<>Delete</>},
        {name:'view',   default:['admin', 'technology'], label:<>View</>},
      ]
  },  
  {
    module_label: <><i className='bx bx-cog'></i> <span>Edge - DPL</span></>,
    module_name:  'dpl',
    moduel_enable: false,
    components: [
        {name:'edit',   default:['admin', 'technology'], label:<>Edit</>},
        {name:'delete', default:['admin', 'technology'], label:<>Delete</>},
        {name:'view',   default:['admin', 'technology', 'operation'], label:<>View</>},
      ]
  },
  {
    module_label: <><i className='bx bx-check-double'></i> <span>Roles &amp; Capabilites</span></>,
    module_name:  'capability',
    moduel_enable: false,
    components: [
        {name:'module_enable', default:['admin'], label:<span onClick={() => alert(`Alert! 😲 are you sure?`)}>Enable Module</span>},
      ]
  }
]