import {useEffect, useState} from 'react'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import {convertToArray, inputFirstLetterCapital} from '../../helpers/common-function.helper'
import {updateUser, userById} from '../../actions/users.action'
import { roles } from '../../helpers/user.helper'
import Select from 'react-select'
import { customerSearch } from '../../actions/users.action'

function UserEdit({handleComponent, dispatch, id,userInfo, role, loadAndRefreshList, customerSearchResult}) {
  const [userRole, setUserRole]   = useState()
  const [changePsd, setChangePsd] = useState(false)
  const [btnLoader, setBtnLoader] = useState(false)
  const [userInfoData, setUserInfoData]    = useState()
  const [searching, setSearching]  = useState(false)
  const [searchCustomer, setSearchCustomer]             = useState('')
  const [customerSeachOption, setCustomerSearchOption]  = useState([])

  useEffect(async () =>{
    if(!searchCustomer.length) return 
    setSearching(true)
    await dispatch(customerSearch(searchCustomer,'customer'))
    await setSearching(false)
  }, [searchCustomer])


  useEffect(() => {
    var formateCustomerOptions = customerSearchResult?.map((option,index) => ({
      value: option,
      label: `${option.first_name} ${option.last_name} "${option.mobile_number}" -  #${option.user_number}`
    }))
    setCustomerSearchOption(formateCustomerOptions)
  },[customerSearchResult])

  useEffect(() => dispatch(userById(id)), [])
  useEffect(() => setUserRole(userInfo?.role), [userInfo])

  const onSubmitEditUser = async (e) => {
    e.preventDefault()

    const formData = new FormData(e.target)
    const data = {}
    for (let entry of formData.entries()) {
      data[entry[0]] = entry[1]
    } 

    var checkfield = convertToArray(data).filter(data => data == '')
    await toast.promise(
      dispatch(updateUser(id,data,role)),
      {
        pending: 'User Updating...',
        success: 'Successfully! User Updated',
        error: {
          render(){
            setBtnLoader(false)
            return 'User not Updated'
          }
        }
      }
    )
    await loadAndRefreshList()
    await handleComponent(false)
  }

  return (
    <>
      {id == userInfo?.id &&
        <div className="modal" aria-hidden="true" style={{display:'block'}}> 
          <div className="overlayer" onClick={() => handleComponent(false)}></div>
          <div className="modal-dialog modal-dialog-right" role="document"> 
            <div className="modal-content modal-content-demo"> 
              <div className="modal-header"> 
                <h6 className="modal-title d-flex align-items-center"><i className='bx bx-edit font-size-22 mr-2'></i> Edit User</h6> 
              </div> 
              <form onSubmit={ (e) => {setBtnLoader(true); onSubmitEditUser(e)}}>
                <div className="modal-body">                                                   
                    <div className="row">
                      <div className="col-md-6">
                        <div className="input_wrap w-100">
                          <input defaultValue={userInfo.first_name} className="text-capitalize" onBeforeInput={inputFirstLetterCapital} name="first_name" type="text" placeholder=" "/>
                          <label>First Name</label>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input_wrap w-100">
                          <input defaultValue={userInfo.last_name} className="text-capitalize" onChange={inputFirstLetterCapital} name="last_name" type="text" placeholder=" "/>
                          <label>Last Name</label>
                        </div>
                      </div>
                      <div className="col-md-6 mt-3">
                        <div className="input_wrap w-100">
                          <input defaultValue={userInfo.email} name="email" type="email" placeholder=" "/>
                          <label>Email</label>
                        </div>
                      </div>
                      <div className="col-md-6 mt-3">
                        <div className="input_wrap w-100">
                          <input defaultValue={userInfo.mobile_number} name="mobile_number" type="number" placeholder=" "/>
                          <label>Mobile Number</label>
                        </div>
                      </div>
                      <div className="col-md-6 mt-2">
                        <div className="form-group">
                          <label className="main-content-label tx-11">Role</label> 
                          <select onChange={e => setUserRole(e.target.value)} defaultValue={userInfo.role} className="form-control" name="role">
                              {roles.map((role,i) => (
                                <option key={i} value={role.name}>{role.label}</option>
                              ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-6 mt-2">
                        <div className="form-group">
                          <label className="main-content-label tx-11">Status</label>
                          <select defaultValue={userInfo.status} className="form-control" name="status">
                            <option value="">Select Status...</option>
                            <option value="pending">Pending</option>
                            <option value="active">Active</option>
                            <option value="inactive">InActive</option>
                            <option value="banned">Banned</option>
                          </select>
                        </div>
                      </div>
                      
                      {userRole != 'customer' && userRole && 
                      <div className="col-md-12 mt-2 mb-3">
                        <div className="input_wrap w-100">
                          <input  name="user_number" defaultValue={userInfo?.user_number} type="text" placeholder=" " autoComplete='new-password'/>
                          <label>HTB Employee Number</label>
                        </div>
                      </div>}

                      {userRole == 'customer' && userRole && 
                      <>
                        <div className="col-md-12 mt-2 mb-3">
                          <div className="input_wrap w-100">
                            <input  name="user_number" defaultValue={userInfo?.user_number} type="text" placeholder=" " autoComplete='new-password'/>
                            <label>HTB Customer Number</label>
                          </div>
                        </div>
                        
                        {!userInfo.reference &&
                          <div className='col-md-12 mb-3'>
                            <Select
                              isMulti={false}
                              isLoading={searching}
                              closeMenuOnSelect={true}
                              onInputChange={e => {setSearchCustomer(e);}}
                              onChange={e => {setUserInfoData(e?.value);}}
                              autoFocus={true}
                              options={customerSeachOption}
                              className="basic-multi-select react-select"
                              placeholder="Add Reference"
                            />
                          </div>
                        }
                      </>
                      }

                      <div className='col-md-12'>
                        <div className='bg-gray-100 mb-3 p-3 card border'>
                          <div className='text-primary font-weight-500 cursor-pointer' onClick={() => setChangePsd(!changePsd)}>Change Password</div>
                          {changePsd ?
                          <div className="input_wrap w-100 mt-3">
                            <input  name="password" type="password" placeholder=" " autoComplete='new-password'/>
                            <label>Password</label>
                          </div> :null}
                        </div>
                      </div>
                    </div>
                </div> 
                <div className="modal-footer">
                    <div className="btn ripple btn-outline-primary" data-dismiss="modal" type="button" onClick={() => handleComponent(false)}>Close</div>
                    <button type='submit' disabled={btnLoader} className="btn ripple btn-primary">
                      {!btnLoader ? 'Update User' : <span className="spinner-border spinner-border-sm mx-4"></span>}
                    </button>
                </div>
              </form>   
            </div> 
          </div> 
        </div>
      }
    </>
  )
}


function mapStateToProps(state) {
  const userInfo   = state.users.users.userById;
  let { customerAddressById }    = state.users.users
  let customerSearchResult       = state.users.users.customerSearch
  return {
    customerSearchResult,
    userInfo
  }
}

export default connect(mapStateToProps)(UserEdit);