//--------AUTH--------
export const LOGIN_SUCCESS    = "LOGIN_SUCCESS"
export const LOGIN_FAIL       = "LOGIN_FAIL"
export const LOGOUT           = "LOGOUT"

export const CURRENT_USER     = "CURRENT_USER"
export const NOT_AUTHORISED   = "NOT_AUTHORISED"


//--------ERROR & MESSAGE--------
export const SET_MESSAGE      = "SET_MESSAGE"
export const CLEAR_MESSAGE    = "CLEAR_MESSAGE"


//--------CATEGORIES--------
export const CATEGORY_LIST      = "CATEGORY_LIST"
export const CATEGORY_EDIT      = "CATEGORY_EDIT"
export const CATEGORY_BY_ID     = "CATEGORY_BY_ID"
export const CATEGORY_IMAGE     = "CATEGORY_IMAGE"
export const CLEAR_CATEGORY     = "CLEAR_CATEGORY"


//--------CLUBBED BUYING --------
export const CLUBBED_BUYING_CATEGORY_LIST      = "CLUBBED_BUYING_CATEGORY_LIST"
export const CLUBBED_BUYING_CATEGORY_ADD       = "CLUBBED_BUYING_CATEGORY_ADD"
export const CLUBBED_BUYING_CATEGORY_BY_ID     = "CLUBBED_BUYING_CATEGORY_BY_ID"
export const CLUBBED_BUYING_CLEAR_CATEGORY     = "CLUBBED_BUYING_CLEAR_CATEGORY"

export const CLUBBED_BUYING_ORDERS             = "CLUBBED_BUYING_ORDERS"

export const CLUBBED_BUYING_SOCIETY_LIST       = "CLUBBED_BUYING_SOCIETY_LIST"
export const CLUBBED_BUYING_SOCIETY_BY_ID      = "CLUBBED_BUYING_SOCIETY_BY_ID"
export const CLUBBED_BUYING_SOCIETY_ADD        = "CLUBBED_BUYING_SOCIETY_ADD"

export const CLUBBED_BUYING_BANNER_LIST        = "CLUBBED_BUYING_BANNER_LIST"
export const CLUBBED_BUYING_BANNER_BY_ID       = "CLUBBED_BUYING_BANNER_BY_ID"
export const CLUBBED_BUYING_BANNER_ADD         = "CLUBBED_BUYING_BANNER_ADD"
export const CLUBBED_BUYING_BANNER_EDIT        = "CLUBBED_BUYING_BANNER_ADD"


//--------MESSAGE TEMPLATES--------
export const EMAIL_LIST         = "EMAIL_LIST"
export const EMAIL_ADD          = "EMAIL_ADD"
export const EMAIL_EDIT         = "EMAIL_EDIT"
export const EMAIL_BY_NAME      = "EMAIL_BY_NAME"

export const SMS_LIST           = "SMS_LIST"
export const SMS_ADD            = "SMS_ADD"
export const SMS_EDIT           = "SMS_EDIT"
export const SMS_BY_NAME        = "SMS_BY_NAME"

export const FIREBASE_NOTIFICATION_LIST     = "FIREBASE_NOTIFICATION_LIST"
export const FIREBASE_NOTIFICATION_ADD      = "FIREBASE_NOTIFICATION_ADD"
export const FIREBASE_NOTIFICATION_EDIT     = "FIREBASE_NOTIFICATION_EDIT"
export const FIREBASE_NOTIFICATION_BY_NAME  = "FIREBASE_NOTIFICATION_BY_NAME"

export const SEND_NOTIFICATION              = "SEND_NOTIFICATION"


//--------CUSTOMER USERS--------
export const CUSTOMER_LIST        = "CUSTOMER_LIST"
export const CUSTOMER_ADD         = "CUSTOMER_ADD"
export const NEW_CUSTOMER         = "NEW_CUSTOMER"
export const CUSTOMER_SEARCH      = "CUSTOMER_SEARCH"
export const CUSTOMER_EDIT        = "CUSTOMER_EDIT"
export const CUSTOMER_ADDRESS     = "CUSTOMER_ADDRESS"
export const USER_BY_ID           = "USER_BY_ID"
export const EXPORT_CUSTOMER_LIST = "EXPORT_CUSTOMER_LIST"
export const REFERENCE_SEARCH     = "REFERENCE_SEARCH"



//--------SELLER USERS--------
export const SELLER_LIST        = "SELLER_LIST"
export const SELLER_ADD         = "SELLER_ADD"
export const SELLER_EDIT        = "SELLER_EDIT"


//--------PRODUCTS--------
export const PRODUCT_LIST       = "PRODUCT_LIST"
export const PRODUCT_ADD        = "PRODUCT_ADD"
export const PRODUCT_BY_ID      = "PRODUCT_BY_ID"
export const PRODUCT_DELETE     = "PRODUCT_DELETE"
export const PRODUCT_SEARCH     = "PRODUCT_SEARCH"
export const CLEAR_PRODUCT      = "CLEAR_PRODUCT"
export const PRODUCT_NEW_LOG    = "PRODUCT_NEW_LOG"
export const GET_PRODUCT_LOG    = "GET_PRODUCT_LOG"
export const EXPORT_PRODUCT     = "EXPORT_PRODUCT"


//--------ATTRIBUTES--------
export const ATTRIBUTES_LIST    = 'ATTRIBUTES_LIST'
export const ATTRIBUTES_ADD     = 'ATTRIBUTES_ADD'
export const ATTRIBUTES_DELETE  = 'ATTRIBUTES_DELETE'
export const ATTRIBUTE_BY_ID    =  'ATTRIBUTE_BY_ID'
export const ATTRIBUTE_EDIT     =  'ATTRIBUTE_EDIT'


//--------DPL USERS--------
export const DPL_LIST           = "DPL_LIST"
export const DPL_BY_ID          = "DPL_BY_ID"
export const DPL_UPDATE         = "DPL_UPDATE"


//--------ORDERS--------
export const CLUBBED_BUYING_ORDER_LIST   = "CLUBBED_BUYING_ORDER_LIST"
export const CLUBBED_BUYING_ORDER_BY_ID  = "CLUBBED_BUYING_ORDER_BY_ID"
export const CLUBBED_BUYING_ORDER_EDIT   = "CLUBBED_BUYING_ORDER_EDIT"
export const LOGS_LIST                   = "LOGS_LIST"
export const NEW_LOG                     = "NEW_LOG"
export const CLEAR_ORDER_BY_ID           = "CLEAR_ORDER_BY_ID"
export const NEW_ORDER                   = "NEW_ORDER"
export const SEARCH_ORDER                = "SEARCH_ORDER"
export const EXPORT_CLUBBED_BUYING_ORDER_LIST = "EXPORT_CLUBBED_BUYING_ORDER_LIST"