import { CATEGORY_LIST, CATEGORY_EDIT, CATEGORY_BY_ID, SET_MESSAGE } from "./types"
import categoryService from "../services/category.service"
import { handleErrors } from "../helpers/common-function.helper"



// CATEGORY LIST
export const categoryList = () => async (dispatch) => {
  return categoryService.categoryList().then(
    (data)  => { dispatch({ type: CATEGORY_LIST, payload: data, }) },
    (error) => handleErrors(error, dispatch)
  )
}


// CATEGORY BY ID
export const getCategoryById = (id) => async (dispatch) => {
  return categoryService.getCategoryById(id).then(
    (data) => { dispatch({ type: CATEGORY_BY_ID, payload: data, }) },
    (error) => handleErrors(error, dispatch)
  )
}


// CATEGORY ADD
export const addCategory = (data) => async (dispatch) => {
  return categoryService.addCategory(data).then(
    (res)   => { dispatch(categoryList()) },
    (error) => handleErrors(error, dispatch)
  )
}


// CATEGORY EDIT
export const updateCategory = (id, data) => async (dispatch) => {
  return categoryService.editCategory(id, data).then(
    (res)   => { dispatch(categoryList()) },
    (error) => handleErrors(error, dispatch)
  );
};


// CATEGORY DELETE
export const deleteCategory = (catId) => async (dispatch) => {
  return categoryService.deleteCategory(catId).then(
    (res)   => { dispatch(categoryList()) },
    (error) => handleErrors(error, dispatch)
  )
}