import React, {useState} from 'react';
import axios from "axios";

const AttributeAdd = ({attributes, btnLoader, setBtnLoader}) => {

  return (
    <>
      <div className="input_wrap w-100 ">
        <input name="name" type="text" placeholder=" "/>
        <label>Name</label>
      </div>

      <div className="form-group mt-3"> 
        <label className="main-content-label tx-11">Parent Attribute</label> 
        <select name="parent" className="form-control">
          <option value="">None</option>
          {!attributes && <option value="">loading...</option>}
          {attributes?.filter(res => res.parent==='').map((attData) => 
            <Attribute key={attData.id} attData={attData} attributes={attributes} />
          )} 
        </select>
      </div>  
      
      <button disabled={btnLoader} className="btn btn-main-primary pd-x-20 float-right" type="submit">
        {!btnLoader ? '+ Add Attribute' : <span className="spinner-border spinner-border-sm mx-5"></span>}
      </button>
    </>
  )
}


const Attribute = ({ attData, attributes }) => {
  return (
    <> 
      {attData.parent === '0' ? '' : <option key={attData.id} value={attData.id}>{ attData.name }</option> }
      <SubAttribute key={attData.id + 1} attributes={attributes} id={attData.id} ar={1}/> 
    </>
  )
}

const SubAttribute = ({ attributes, id , ar  }) => {
  var arrowRows = []
  for (var i = 1; i <= ar; i++) { arrowRows.push('- ') }

  return(
    <React.Fragment key={id}>
      {attributes.filter(res => res.parent===id).map( (attData2, index) => 
        <option key={attData2.id} value={attData2.id}>{arrowRows} { attData2.name }</option>     
      )}
    </React.Fragment>
  )
}

export default AttributeAdd;
