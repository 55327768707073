import React, {useEffect, useState} from 'react'
import { connect } from "react-redux"
import { useHistory } from "react-router-dom"
import MainLayout from '../../layouts/main.component'
import { clubbedBuyOrderList, searchOrder, deleteOrder } from '../../../actions/orders.action'
import ClubbedBuyingEditOrder from './edit-order.component'
import ClubbedBuyingAddOrder from './add-order.component'
import { orderStatusOptions, orderPagination } from '../../../helpers/order.helper'
import moment from 'moment'
import Select from 'react-select'
import DeleteConfirm from '../../../helpers/delete.helper'
import ExportOrder from './export-order.component'


const ClubbedBuyingOrderList = ({ dispatch, orderListState, capability, clubbedBuyOrderSearch}) => {
  const history = useHistory() //ROUTER
	const [pagination, setPagination] = useState([])
  const [search, setSearch]         = useState(''); //SEARCH 
  const [page, setPage]             = useState(1)
	const [limit, setLimit]           = useState(25)
	const [loader, setLoader]         = useState(false)
	const [sortBy, setSortBy]         = useState('desc')
  const [add, setAdd]               = useState(false)
  const [edit, setEdit]             = useState(false)
  const [orderId, setOrderId]       = useState()
  const [orderList, setOrderList]   = useState()
  const [showExport, setShowExport] = useState(false)


  useEffect(() => { //SEARCH
    if(!search){
      setOrderList(orderListState)
    }else{
      dispatch(searchOrder(search))
      setLoader(true)
    }
  },[search, orderListState])

  useEffect(() => {
    setPage(1)
    setLoader(true)
  } ,[limit, sortBy])


  useEffect(()=>{
    setTimeout(async() => {
      document.title = 'Orders | Clubbed Buying'
      if(edit == false){
        setLoader(true)
        await dispatch(clubbedBuyOrderList(page,limit,sortBy))
        await setLoader(false)
      }
    }, 100)
  },[page, limit, sortBy])


  useEffect(() => {
		setPagination([])
		var paginationLength = Math.ceil(orderList?.total / limit)
		if(!paginationLength > 0) return
    Array(paginationLength).fill().map( (data,i) => setPagination(page => ([
      ...page, { label:i+1, value:i+1 }
    ])))
	},[orderList])


  if(edit) document.querySelector('html').style.overflow = "hidden"
  else document.querySelector('html').style.overflow = "unset"
  const refreshList = () => dispatch(clubbedBuyOrderList(page,limit,sortBy))

  //DELETE
	const [showDelete, setShowDelete]  = useState(false)
	const [deleteId, setDeleteId]      = useState()
	const [deleteName, setDeleteName]  = useState()
	const [isDeleted, setIsDeleted]    = useState(false)
  const [other, setOther]            = useState()
  const [allowUpdateDelete, setAllowUpdateDelete] = useState(false)
  const [deleteDescription, setDeleteDescription] = useState()

  useEffect(() => {
    if(!allowUpdateDelete) setAllowUpdateDelete(true)
    else refreshList()
  },[isDeleted])


  useEffect(() => {
    setLoader(false)
    if(search) setOrderList(data => ({ ...data, orders: clubbedBuyOrderSearch }))
    else setOrderList(orderListState)
    if(history.location.hash.length){ setOrderId(history.location.hash.replace('#', '')); setEdit(true);}
  }, [clubbedBuyOrderSearch])

  
  useEffect(() => {
    if(!edit && history.location.hash.length) history.push('#')
  }, [edit])


  return (
    <MainLayout>

      {/* BREADCRUMB */} 
			<nav className="breadcrumb mt-3 mb-0"> 
				<div className="cursor-pointer mr-4 text-primary" onClick={history.goBack}><i className='bx bxs-chevron-left'></i> Back</div>
        <div className="mr-auto page_title">Orders List</div>
				<div className="breadcrumb-item" onClick={()=> history.push('/') }>Dashboard</div>
				<div className="breadcrumb-item active">Clubbed Order</div>
			</nav>


      {/* HEADER */}
			<div className="d-flex justify-content-between align-items-center">
        <div className="h4 mt-4 mb-3">Orders <span className="badge badge-primary ml-2">{orderList?.total}</span></div>
        <div className="d-flex align-items-center">
          <button className="btn btn-white border-5 mr-2 title-top" data-title='Refresh' onClick={()=> window.location.reload()}><i className="bx bx-refresh pt-1 font-size-16"></i></button>
          <div className="form-group w-100 mb-0"> 
            <div className="input-icon"> 
              <span className="input-icon-addon"> <i className="fe fe-search" /> </span> 
              <input type="search" onChange={e => setSearch(e.target.value)} className="form-control ht-40" placeholder="Search" /> 
            </div> 
          </div>
          <button className='btn btn-outline-warning ml-2 center' onClick={() => setShowExport(true)}><i className='bx bxs-file-export'></i> Export</button>
          {capability?.clubbed_order.add ? <button className="btn btn-primary ml-2 text-nowrap" onClick={() => setAdd(true)}> + Add Order</button> : null}
          <div onClick={() => setPage(orderList.prevPage)} className={`${orderList?.prevPage == 0 ? 'disabled' : ''}`}><button className="btn btn-white center p-9 ml-2"><i className=" bx bx-chevron-left font-size-19"></i></button></div>
					<div onClick={() => setPage(orderList.nextPage)} className={`${orderList?.nextPage == 0 ? 'disabled' : ''}`}><button className="btn btn-white center p-9 ml-1"><i className=" bx bx-chevron-right font-size-19"></i></button></div>
        </div>
      </div>


      <div className="row">

        {/* BANNER LIST */}
        <div className="col-md-12">
          <div className="card card-body userlist-table">
            {search && <div className="mb-2 text-secondary">Search <b className="text-warning">"{search}"</b></div>}

            <table className="table table-hover table-bordered text-center category_table border-0 text-nowrap mb-0">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Product</th>
                  <th>Order</th>
                  <th>Payment</th>
                  <th>User</th>
                  <th>Date</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {!loader && <>
                    { orderList?.orders.length  <= 0  ?
                      <tr name=" "><td className="text-center count_ignore" colSpan="7">No Data Available</td></tr>
                    :
                      orderList?.orders?.map((data,index) => (
                        data.items.length ? <OrderItem history={history} setDeleteDescription={setDeleteDescription} limit={limit} page={page} index={index} setOther={setOther} setShowDelete={setShowDelete}  setDeleteId={setDeleteId} setDeleteName={setDeleteName} key={index} data={data} setEdit={setEdit} setOrderId={setOrderId}/> : null
                      ))
                    }
                </>}
              </tbody>
            </table>

            {/* LOADER */}
            {!orderList || loader ?
              <div className="d-flex justify-content-center flex-column mt-4 mb-3 align-items-center">
                <div>Wait <b>Buddy</b>...</div>
                <div className="spinner-grow" role="status"/>
              </div>
            : null}

            {search.length <= 0 && <Pagination  addClassName="mt-3 position-relative z-index-200" page={page} setPage={setPage} sortBy={sortBy} setSortBy={setSortBy} limit={limit} setLimit={setLimit} orderList={orderList} pagination={pagination}/>}

          </div>
        </div>
			</div>  


      {/* EDIT */}
      {edit ? <ClubbedBuyingEditOrder refreshList={refreshList}  dispatch={dispatch} orderId={orderId} formHandle={setEdit}/> : null}

      {/* Add */}
      {add ? <ClubbedBuyingAddOrder refreshList={refreshList} dispatch={dispatch} orderId={orderId} formHandle={setAdd}/> : null}

      {/* DELETE */}
      {showDelete && <DeleteConfirm response={setIsDeleted} description={deleteDescription} id={deleteId} other={other} title={deleteName} componentHandle={setShowDelete} dispatch={dispatch} deleteItem={deleteOrder}/>}

      {/* EXPORT PRODUCT */}
			{showExport && <ExportOrder handlePopup={setShowExport}></ExportOrder>}

    </MainLayout>
  )
}



/* -------------------------------
					  ORDER ITEM
----------------------------------*/
const OrderItem = ({ data, setEdit, history, setOrderId, index, limit, page, setShowDelete,  setDeleteId, setDeleteName, setOther, setDeleteDescription}) => {
  
  const deleteDescriptionMessage = <>
    <div className='d-flex align-items-center'>- &nbsp; <span className='badge badge-danger-transparent mr-1'>{data.items.length}</span> Items</div>
    <div>- &nbsp; All Logs</div>
  </>

  return (
    <tr name=" " className='cursor-pointer' onClick={() => {setOrderId(data._id); setEdit(true); history.push(`#${data._id}`)}}>
      <td className='count_ignore'>{(limit * page) - (limit - 1) + index}</td>
      <td className='position-relative overflow-hidden'>
          {data.items.map((item, index) => (
            <div key={index} className={`animated mb-1 ${index > 1 ? 'd-none fadeOut' : ' fadeIn'}`}>
              <div className={`${data.items.length > 2 ? 'align-items-end' : 'align-items-center'} ${!item.variation?.name ? 'bg-warning-transparent-2':''} mb-2 last-mb-0 d-flex`}>
                <img 
                  className="rounded avatar-md mr-2 border" 
                  src={
                    item.variation?.images?.filter(data => data.featured == 'yes').length ?
                    item.variation?.images?.filter(data => data.featured == 'yes')[0].link:
                    item.variation?.images[0]?.link
                  }
                /> 
                <div className="text-left">
                  <div className="font-weight-500 white-space-normal rounded line-2" title={item.variation?.name}>{item.variation?.name ? item.variation?.name : "Sorry! 😭 Product Not Available"}</div>
                </div>
              </div>
            </div>
          ))}
        {data.items.length > 2 ? 
          <span className='badge total-product badge-light'>{data.items.length}</span>
        : null}
      </td>

      <td>
        <div className='d-flex justify-content-between flex-column text-left'>
          <span className='font-weight-500 rounded mb-2'>Number : <span className='text-secondary'>{data.order_number}</span></span>
          <span className='font-weight-500 rounded d-flex align-items-center'>Status : 
            {orderStatusOptions.map((options,i) => {
              if(options.value == data.status) 
                return <span key={i} data-title={options.name} className='title-bottom ml-3'><span  data-title={options.name} className={`badge badge-1 
                  ${ data.status == "cb-order-placed" ? 'badge-warning-transparent' :null }
                  ${ data.status == "cb-order-confirmation-info-sent" ? 'badge-primary-transparent' :null }
                  ${ data.status == "cb-order-confirmed-online-payment" ? 'badge-purple-transparent' :null }
                  ${ data.status == "cb-order-confirmed-cod-payment" ? 'badge-purple-transparent' :null }
                  ${ data.status == "cb-order-expired" ? 'badge-danger-transparent' :null }
                  ${ data.status == "cb-order-out-for-delivery-prepaid" ? 'badge-teal-transparent' :null }
                  ${ data.status == "cb-order-out-for-delivery-cod" ? 'badge-teal-transparent' :null }
                  ${ data.status == "cb-order-delivered" ? 'badge-success-transparent text-success' :null }
                  ${ data.status == "cb-order-cancelled" ? 'badge-danger' :null }
                `}>
                  {data.status == "cb-order-placed" ? <i className='bx bx-shopping-bag mr-1'/>:null}
                  {data.status == "cb-order-confirmation-info-sent" ? <i className='bx bx-envelope mr-1'/>:null}
                  {data.status == "cb-order-confirmed-online-payment" ? <i className='bx bx-credit-card mr-1'/>:null}
                  {data.status == "cb-order-confirmed-cod-payment" ? <i className='bx bx-money mr-1'/>:null}
                  {data.status == "cb-order-expired" ? <i className='bx bx-calendar-x mr-1'/>:null}
                  {data.status == "cb-order-out-for-delivery-prepaid" ? <i className='bx bx-package mr-1'/>:null}
                  {data.status == "cb-order-out-for-delivery-cod" ? <i className='bx bx-package mr-1'/>:null}
                  {data.status == "cb-order-delivered" ? <i className='bx bxs-check-circle mr-1'/>:null}
                  {data.status == "cb-order-cancelled" ? <i className='bx bxs-x-circle mr-1'/>:null}
                {options.name}</span></span>
            })}
          </span>
        </div>
      </td>

      <td>
        <div className='d-flex align-items-center'><span className='mr-2'>Mode :</span>
          {data?.payment_mode == 'expired' ? <span className="badge badge-1 badge-danger-transparent text-capitalize">{data?.payment_mode}</span>:''}
          {data?.payment_mode == 'inactive' ? <span className="badge badge-1 badge-warning-transparent text-capitalize">{data?.payment_mode}</span>:''}
          {data?.payment_mode == 'active' ? <span className="badge badge-1 badge-success-transparent text-capitalize">{data?.payment_mode}</span>:''} 
          {data?.payment_mode != 'expired' && data?.payment_mode != 'inactive' && data?.payment_mode != 'active' ? <span className="badge badge-1 badge-primary-transparent text-capitalize">{data?.payment_mode}</span>:''}
        </div>
        <div className='d-flex align-items-center mt-1'><span className='mr-1'>Status :</span>
          {data?.payment_status == 'unpaid' ? <span className="badge badge-1 badge-warning-transparent text-capitalize">{data?.payment_status}</span>:''} 
          {data?.payment_status == 'paid' || data?.payment_status == 'SUCCESS' ? <span className="badge badge-1 badge-success-transparent text-capitalize">{data?.payment_status}</span>:''} 
          {data?.payment_status != 'paid' && data?.payment_status != 'unpaid' && data?.payment_status != 'SUCCESS' ? <span className="badge badge-1 badge-warning-transparent text-capitalize">{data?.payment_status}</span>:''} 
        </div>
      </td>

      <td className='text-left text-wrap'>
        {data?.user?.first_name +' '+ data?.user?.last_name}
      </td>

      <td className='text-left text-secondary'>
        <div className="text-secondary">{moment(data.createdAt).utcOffset('+00:00').format('lll')}</div>
      </td>
      {data?.status == 'cb-order-delivered' || data?.status == 'cb-order-cancelled' ? 
        <td>-</td>
      :
        <td onClick={e => e.stopPropagation()}>
          <button onClick={() => {setShowDelete(true); setDeleteDescription(deleteDescriptionMessage); setOther(data.items[0]._id); setDeleteId(data._id); setDeleteName(`Order No. ${data.order_number}`);}} className="btn btn-sm btn-white title-bottom right" data-title="Delete Order"><i className='bx bx-trash h4 m-0 text-danger'></i></button>
        </td>
      }
    </tr>
  )
}



/* -------------------------------
					PAGINATION
----------------------------------*/
const Pagination = ({page, setPage, sortBy, setSortBy, limit, setLimit, orderList, pagination, addClass}) => {
	return (
		<>
			<style>{`.pagination:not(.p-2) li:nth-child(${page+1}) > *{background:#0068ff;color:white}`}</style>
			<div className={`d-flex justify-content-between align-items-center mt-2 position-relative z-index-100 ${addClass}`}>
				<div className='d-flex align-items-center'>
					<select defaultValue={sortBy} onChange={e => setSortBy(e.target.value)} className='form-control mr-2'>
						<option value={'desc'}>New</option>
            <option value={'asc'}>Old</option>
					</select>
					<select defaultValue={limit} onChange={e => setLimit(e.target.value)} className='form-control'>
            {orderPagination.map((data, dataIndex) =>  <option key={dataIndex} value={data}>{data}</option>)}
					</select>
					<div className='font-weight-500 mr-3 text-secondary text-nowrap ml-2'>{(limit * page) - (limit - 1)}-{(limit * page) > orderList?.total ? orderList?.total : (limit * page)} of {orderList?.total}</div>
				</div>

        <div className='d-flex'>
          <button onClick={() => setPage(orderList.prevPage)} className={`btn btn-outline-primary border-light rounded-5 ${orderList?.prevPage == 0 ? 'disabled' : ''}`}><i className='bx bxs-chevron-left'></i></button>
          <Select 
            value={pagination[orderList?.currentPage - 1]}
            onChange={e => setPage(e.label)}
            options={pagination}
            menuPlacement='top'
            placeholder='Page'
            className='mx-2 react-select pagination-select'
          />
          <button onClick={() => setPage(orderList.nextPage)} className={`btn btn-outline-primary border-light rounded-5 ${orderList?.nextPage == 0 ? 'disabled' : ''}`}><i className='bx bxs-chevron-right'></i></button>
        </div>
			</div>
		</>
	)
}



function mapStateToProps(state) {
  let { clubbedBuyOrderList }   = state.orders;
  let { clubbedBuyOrderSearch } = state.orders
  let orderListState = clubbedBuyOrderList
  let capability     = state?.auth?.currentUser?.capability
  capability         = capability ? JSON.parse(capability) : null
  return {
    orderListState,
    capability,
    clubbedBuyOrderSearch
  };
}

export default connect(mapStateToProps)(ClubbedBuyingOrderList);