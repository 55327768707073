import moment from 'moment'
import { SET_MESSAGE } from '../actions/types'
import { toast } from 'react-toastify'
import CryptoJS from 'crypto-js'


//Remove Spacing " " Start & End
export function removeSpace(data, breakLineRemove = true, advance = true, space = false, number = false){
  if( data?.charAt(0) == " ") data = data.substring(1)
  if( data.charAt(data.length - 1) == " ") data = data.substring(0, data.length - 1)
  
  if(breakLineRemove){
    data = data.replaceAll(' \n ','')
    .replaceAll('\n ','')
    .replaceAll('\n','')
    .replaceAll('  ',' ')
    .replaceAll('  ',' ')
    .replaceAll(' , ',',')
    .replaceAll(' ,',',')
    .replaceAll(', ',',')
  }
  if(advance) data = data.replace(/[^a-z0-9&>'<; \.,_-]/gim,"").trim()
  if(data == ' ') data = data.replace(' ','')
  if(space) data = data.replaceAll(' ','')
  if(number && data.length && data != parseFloat(data)) return alert(`Alert! invalid number formate "${data}"`)
  return data
}


//CHECK IMAGE IS "Real" or "Not"
export function checkImage(url) {
  if(url.includes('https://')) return(url.match(/\.(jpeg|jpg|gif|png|webp)$/) != null)
  else return false
}


//CONVERT ARRAY TO OBJECT
export function convertToArray(data){ 
	if(typeof data == 'object'){
		var arrayData = Object.keys(data).map(key => {return data[key]}) 
		return arrayData
	}
	return data	
}


//TIME
export function dateTimeConvertFormat(date, format="YYYY-MM-DD, LT") {
  return moment(date).utcOffset("+05:30").format(format)
}


//ADD PAISA
export function addPaisa(rupee){
  if(isNaN(rupee)) rupee = 0
  if(!rupee) rupee = 0
  rupee = String(rupee)
  if(!rupee.includes('.')){
    return rupee+'.00'
  }else if(rupee.includes('.',rupee.length - 2)){
    return rupee+ '0'
  }else if(rupee.includes('.',rupee.length - 3)){
    return rupee
  }else{
    alert(`Something wrong with this Price: ${rupee}`)
  }
}


//ON FOCUS CENTER TEXT FIELD
export function centerInput(e){
  e.target.scrollIntoView({behavior: "smooth", block: "nearest"})
}


//Update App
export function clearCache(){
  // localStorage.clear()
  sessionStorage.clear()
  if(!localStorage.getItem('refresh') || parseInt(localStorage.getItem('refresh')) <= 10){
    localStorage.setItem('refresh', parseInt(localStorage.getItem('refresh')) ? parseInt(localStorage.getItem('refresh')) + 1 : 1)
    window.location.reload(true)
  }else{
    localStorage.removeItem('refresh')
  }
}


//TEXT EDITOR SETTING
export const sunEditorSetting = [
  ['undo', 'redo'],
  ['fontSize', 'formatBlock'],
  ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
  ['align', 'horizontalRule', 'list'],
  ['fontColor', 'hiliteColor'],
  ['outdent', 'indent'],
  ['table', 'link', 'image', 'video'], 
]


//FIRST LETTER CAPITAL
export const inputFirstLetterCapital = (e) => {
  e.target.value = e.target.value.charAt(0).toUpperCase() + e.target.value.substring(1)
}


//HANDLE API ERRORS
export function handleErrors(error, dispatch, returnReject =  true){
  if(error?.response?.data){
    dispatch({
      type: SET_MESSAGE,
      payload: error.response.data
    })
    if(returnReject) return Promise.reject()
  }else{
    toast.warning('Server Under Maintenance',{
      toastId:'maintenance',
      autoClose: false,
      hideProgressBar: true,
    })
    setTimeout(() => {
      toast.warning('Refresh Browser',{
        toastId:'maintenance-refresh',
        hideProgressBar: true,
        autoClose: false,
      })
    },3000)
    if(returnReject) return Promise.reject()
  }
}


//ENCRYPT TEXT
export const encryptWithAES = (text) => {
  const passphrase = process.env.REACT_APP_SIGNATURE;
  return CryptoJS.AES.encrypt(text, passphrase).toString();
};