import axios from "axios";
import Cookies from 'js-cookie';
import { useHistory } from "react-router-dom"

function LogoutUser() {
  const history = useHistory()
  history.push('/login')
}

class AuthService {
  async login(email, password) {
    return axios
      .post("/auth/admin/signin", { email, password })      
      .then((response) => {      
        // 5 / 1440 -- FIVE MINUTES
        Cookies.set('htb:sess', response.data.token, { secure: true , expires: 1 , path: '' });
        window.location.reload() 
      });

  } 

  async currentUser() {      
    return axios
      .get(process.env.REACT_APP_API_URL+"/auth/admin/currentuser", {
        headers: {
          "authorization" : Cookies.get('htb:sess')
        }
      })
      .then((response) => { 
        return response.data;
      });      
  }

  async logout() {   
    // return axios.post("/auth/admin/signout", { }).then((response) => {});    
    Cookies.remove('htb:sess')
    Cookies.remove('htb:sess')
    window.location.href = '/login' 
  }

}

export default new AuthService();
