import React, {useEffect, useState} from 'react'
import { connect } from "react-redux"
import { useHistory } from "react-router-dom"
import MainLayout from '../../layouts/main.component'
import BannerAdd from './banner-add.component'
import BannerEdit from './banner-edit.component'
import { toast } from 'react-toastify'
import DeleteConfirm from '../../../helpers/delete.helper'
import { clubbedBuyingBannerById,clubbedBuyingBannerDelete,clubbedBuyingBannerList } from '../../../actions/clubbed-buying.action'

const BannerList = ({ dispatch, bannerList, bannerById, capability }) => {

  const history = useHistory() //ROUTER
  const [editBannerForm, setEditBannerForm] = useState(false) //BANNNER EDIT 'show/hide'
  const [addBannerForm, setAddBannerForm]   = useState(false); //BANNER ADD 'show/hide'
  const [search, setSearch] = useState(''); //SEARCH 
  

  useEffect(()=>{
    dispatch(clubbedBuyingBannerList());
    document.title = 'Banner | Clubbed Buying';
  },[dispatch])


  //DELETE
  const [showDelete, setShowDelete]  = useState(false)
  const [deleteId, setDeleteId]      = useState()
  const [deleteName, setDeleteName]  = useState()


  //--- BANNER EDIT ---
  const editBanner = async (id) => {
    await dispatch(clubbedBuyingBannerById(id));
    await setEditBannerForm(true);
  }


  return (
    <MainLayout>

       {/* EDIT */}
      {editBannerForm && <BannerEdit defaultData={bannerById} dispatch={dispatch} formHandle={setEditBannerForm}/>}


       {/* ADD */}
      {addBannerForm && <BannerAdd dispatch={dispatch} formHandle={setAddBannerForm}/>}

      {/* DELETE */}
      {showDelete && <DeleteConfirm  id={deleteId} title={deleteName} componentHandle={setShowDelete} dispatch={dispatch} deleteItem={clubbedBuyingBannerDelete}/>}


      {/* BREADCRUMB */} 
			<nav className="breadcrumb mt-3 mb-0"> 
				<div className="cursor-pointer mr-4 text-primary" onClick={history.goBack}><i className='bx bxs-chevron-left'></i> Back</div>
        <div className="mr-auto page_title">Banner List</div>
				<div className="breadcrumb-item" onClick={()=> history.push('/') }>Dashboard</div>
				<div className="breadcrumb-item active">Banner</div>
			</nav>


      {/* HEADER */}
			<div className="d-flex justify-content-between align-items-center">
        <div className="h4 mt-4 mb-3">Banner <span className="badge badge-primary ml-2">{bannerList?.length}</span></div>
        <div className="d-flex align-items-center">
          <button className="btn btn-white border-5 mr-2 title-top" data-title='Refresh' onClick={()=> window.location.reload()}><i className="bx bx-refresh pt-1 font-size-16"></i></button>
          <div className="form-group w-100 mb-0"> 
            <div className="input-icon"> 
              <span className="input-icon-addon"> <i className="fe fe-search" /> </span> 
              <input type="search" onChange={e => setSearch(e.target.value.toLowerCase())} className="form-control ht-40" placeholder="Search by device, position" /> 
            </div> 
          </div>   
          {capability?.banner.add ? <button className="btn btn-primary ml-2" onClick={() => setAddBannerForm(true)}> +&nbsp;Add&nbsp;Banner</button> :null}
        </div>
      </div>



      <div className="row">

        {/* BANNER LIST */}
        <div className="col-md-12">

          <style>{/* SEARCH */}
            {`.category_table tbody tr:not([name${search.length ? `*="${search}"`: ''}]){
                visibility:hidden;
                position:absolute
            }`}
          </style>

          <div className="card card-body userlist-table">
            {search && <div className="mb-2">Search <b className="text-warning">"{search}"</b></div>}

            <table className="table table-bordered text-center category_table border-0 text-nowrap mb-0">
              <thead>
                <tr>
                  <th>#</th>
                  <th>IMAGE</th>
                  <th className='title-top title-arrow-center-x' data-title="Position in slider">Position <i className='bx bx-info-circle font-size-15 text-primary'></i></th>
                  <th>Device</th>
                  <th>Status</th>
                  {capability?.banner.edit || capability?.banner.delete ? <th>Action</th> :null}
                </tr>
              </thead>
              <tbody>
                {bannerList?.map((data,index) => (
                  <Banner setShowDelete={setShowDelete} capability={capability}  setDeleteId={setDeleteId} setDeleteName={setDeleteName} key={index} number={index+1} data={data} editBanner={editBanner} formHandle={setEditBannerForm}/>
                ))}
              </tbody>
            </table>

            {/* LOADER */}
            {!bannerList && 
            <div className="d-flex justify-content-center flex-column mt-4 mb-3 align-items-center">
              <div>Wait <b>Buddy</b>...</div>
              <div className="spinner-grow" role="status"/>
            </div>}

          </div>
        </div>
			</div>  
    </MainLayout>
  )
}



/* -------------------------------
					  BANNER
----------------------------------*/
const Banner = ({ data, number, editBanner, setShowDelete,  setDeleteId, setDeleteName, capability}) => {
  return (
    <tr name={data.device +''+ data.in_order}>
       <td className="text-center"></td>{/* S.NO */}
       <td>{/* IMAGE */}
        <img style={{maxHeight:200,maxWidth:500}} src={data.image} className="rounded"/>
        <div className="mt-2 text-right d-flex">
          <div className="p-2 m-auto"><b>Banner Link:</b> <a href={data.link} target="_blank" className="text-primary">{data.link} <i className='bx bx-link' ></i></a></div>
        </div>
      </td>

      <td className="text-center">{data.in_order}</td>{/* IMAGE POSITOIN IN SLIDER */}
      <td className="text-capitalize">{data.device}</td>{/* DEVICE */}

      <td>{/* STATUS */}
        {data.status === 'pending' ? <span className="badge badge-warning">Pending</span>:''}
        {data.status === 'active' ? <span className="badge badge-success">Active</span>:''} 
        {data.status === 'inactive' ? <span className="badge badge-secondary">InActive</span>:''}
        {data.status === 'banned' ? <span className="badge badge-danger">Banned</span>:''}
      </td>

      {capability?.banner.edit || capability?.banner.delete ?
      <td>{/* EDIT & DELETE */}
        <div className="btn-group align-top">
          {capability?.banner.edit ? <button className="btn btn-sm btn-white" data-title="Edit Banner" onClick={() => editBanner(data.id)}><i className="bx bx-pencil h4 m-0 text-primary"></i></button> :null}
          {capability?.banner.delete ? <button className="btn btn-sm btn-white" data-title="Delete Banner" onClick={() => {setShowDelete(true); setDeleteId(data.id); setDeleteName('Banner '+ number);}}><i className="bx bx-trash h4 m-0 text-danger"></i></button> :null}
        </div>
      </td> :null}
    </tr>
  )
}



function mapStateToProps(state) {
  const { message }       = state.message;
  const { bannerList }    = state.clubbedBuying.clubbedBuying;
  const { bannerById }    = state.clubbedBuying.clubbedBuying;
  let capability           = state?.auth?.currentUser?.capability
  capability               = capability ? JSON.parse(capability) : null
  return {
    message,
    bannerList,
    bannerById,
    capability
  };
}

export default connect(mapStateToProps)(BannerList);