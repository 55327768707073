import React, {useEffect} from 'react';
import { useHistory } from "react-router-dom"
import { Link } from 'react-router-dom'


const ForgotPassword = () => {

  const history = useHistory();

  useEffect(()=>{
    document.title = 'Forgot Password';
  }, [])

  return(
    <React.Fragment>
      <div className="row no-gutter">
        <div className="col-md-6 col-lg-6 col-xl-7 d-none d-md-flex bg-image center">
          <div className="position-relative z-index-100">
            <div className='center'>
              <a href="index.html"><img src="/favicon.png" className="sign-favicon" alt="logo" /></a>
              <h1 className="main-logo ml-1 mr-0 my-auto tx-28 text-white">HashTagBazaar</h1>
            </div>
            <div className='bg-white shadow-sm w-100 p-3 rounded mt-2 font-size-16 text-dark text-center'>Admin Panel</div>
          </div>
        </div>
        <div className="col-md-6 col-lg-6 col-xl-5 bg-white">
          <div className="login d-flex align-items-center py-2">
            <div className="container p-0">
              <div className="row">
                <div className="col-md-10 col-lg-10 col-xl-9 mx-auto">
                  <div className="main-card-signin d-md-flex bg-white border-0">
                    <div className="wd-100p">
                      <div className="main-signin-header">
                        <h2>Forgot Password!</h2>
                        <h4>Please Enter Your Email</h4>
                        <form action="page-profile.html">
                          <div className="form-group"><label>Email</label> <input className="form-control" placeholder="Enter your email" type="text" /></div>
                          <button className="btn btn-main-primary btn-block" type='submit'>Send</button>
                        </form>
                      </div>
                      <div className="main-signup-footer mg-t-20">
                        <p>Forget it, <Link to="/login"> Send me back</Link> to the sign in screen.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default ForgotPassword;