import { useEffect, useState, useRef } from 'react'
import { connect } from 'react-redux'
import { getProductLog, addProductLog } from '../../actions/product.action'
import moment from 'moment'
import { toast } from 'react-toastify'

function ProductLogs({id, dispatch, handleComponent, productLog, userId}) {
  const [showAddLog, setShowAddLog] = useState(false)

  useEffect(() => 
    dispatch(getProductLog(id)) 
  ,[])

  return (
    <>
      <div className="modal" aria-hidden="true" style={{display:'block'}}> 
        <div className="overlayer" onClick={() => handleComponent(false)}></div>
        <div className="modal-dialog modal-dialog-right"> 
          <div className="modal-content modal-content-demo"> 
            <div className="modal-header"> 
              <h6 className="modal-title d-flex align-items-center"><i className='bx bx-note font-size-22 mr-2'></i> Product Logs</h6>
              <button className="btn font-size-13 btn-outline-primary p-1 pl-2 pr-2" onClick={() => setShowAddLog(true)}>+ Add Note</button>
            </div> 
              <div className="modal-body p-0 pb-0-imp max-height-unset">                                                   
                <div className={`note_body ${!productLog?.length ? 'center' : ''}`}>
                  {productLog ? 
                    <>
                    {productLog?.length > 0 ? 
                      productLog?.map((note,i) => (
                        <div key={i} className="note_item">
                          <div className="note_message text-secondary border" dangerouslySetInnerHTML={{__html:note?.notes}}></div>
                          <div className="note_item_footer text-black-50 d-flex align-items-center mt-1">
                            <i className='bx bxs-user-circle mr-1 font-size-21'></i>
                            <div className="text-left d-flex flex-column text-monospace">
                              <span>{note?.updatedBy?.first_name} { note?.updatedBy?.last_name} </span>
                              <span className="text-monospace text-uppercase line-height-11">{moment(note?.createdAt).utcOffset('+00:00').format('lll')}</span>
                            </div>
                          </div>
                        </div>
                      ))
                      :
                      <div className="text-center p-3 bg-white shadow-sm rounded-10">
                        😇 Zero Note
                      </div>
                    }
                    </>
                  : 
                    <div className="d-flex mx-auto justify-content-center flex-column mt-4 mb-3 align-items-center">
                      <div>Wait <b>Buddy</b>...</div>
                      <div className="spinner-grow" role="status"/>
                    </div>
                  }
                </div>
              </div>
          </div> 
        </div> 
      </div>

      {/* ADD LOG */}
      {showAddLog && <AddLog dispatch={dispatch} userId={userId} id={id} handleComponent={setShowAddLog}/>}
    </>
  )
}


/* -------------------------
        ADD LOG   
------------------------- */
const AddLog = ({id, handleComponent, dispatch, userId}) => {

  const [loader, setLoader] = useState(false)
  const noteRef             = useRef()
  const notify              = useRef()


  async function onSubmitAddLog(e){
    e.preventDefault()
    var noteMessage = `
        <b class="border-bottom mb-2">Note Added</b>
        ${noteRef.current.value}
      `

    var data = {
      "productId"  : id,
      "notes"      : noteMessage,
      "updatedBy"  : userId
    }
    
    if(!noteRef.current.value?.length){
      alert('Buddy! 🙂 Please Add Note Before Submit.')
      return
    }
    
    setLoader(true)
    await toast.promise(
      dispatch(addProductLog(data)),
      {
        pending: 'Adding Note...',
        success: 'Successfully! Note Added',
        error: 'Not Added'
      }
    )
    dispatch(getProductLog(id))
    await setLoader(false)
    await document.querySelector('#hideEdit')?.classList.add('fadeOutDown')
    setTimeout(async () => await handleComponent(false) ,1000)
  }


  return (
    <div className="modal effect-flip-vertical show align-items-end" id="modaldemo8" aria-modal="true" style={{display:"flex"}}>
        <div className="modal-dialog modal-dialog-centered animated fadeInUpBig" id="hideEdit" role="document">

          <form className="modal-content modal-content-demo" onSubmit={e => onSubmitAddLog(e)}>
            <div className="modal-header d-flex align-items-center">
              <h6 className="modal-title">Add Product Notes</h6>
            </div>

            <div className="modal-body pt-2 mt-1">
              <div className="address_box animated">
                <div className="form-group">
                  <label className="main-content-label tx-11">Note</label>
                  <textarea ref={noteRef} className="form-control" rows={4}></textarea>
                </div>
              </div>
            </div>
            
            <div className="modal-footer">
              <div className="btn ripple btn-outline-primary" data-dismiss="modal" type="button" onClick={() => handleComponent(false)}>Close</div>
              <button className="btn ripple btn-primary" type="submit" style={{minWidth:83}}>{loader ? <span className="spinner-border spinner-border-sm"></span>: '+ Add Note'}</button>
            </div>
          </form>
        </div>
    </div>
  )
}


function mapStateToProps(state){
  const { productLog } = state.products.products
  const userId         = state?.auth?.currentUser?.id;
  return{
    productLog,
    userId
  }
}

export default connect(mapStateToProps)(ProductLogs)