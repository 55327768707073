import { CLUBBED_BUYING_ORDER_LIST, CLUBBED_BUYING_ORDER_BY_ID, EXPORT_CLUBBED_BUYING_ORDER_LIST, SEARCH_ORDER, LOGS_LIST} from "./types"
import ordersService from "../services/orders.service"
import { handleErrors } from "../helpers/common-function.helper"


export const clubbedBuyOrderList = (page,limit,sortBy) => async (dispatch) => { //ORDER LIST
  return ordersService.orderList(page,limit,sortBy).then(
    (data) => { dispatch({ type: CLUBBED_BUYING_ORDER_LIST, payload: data }) },
    (error) => handleErrors(error, dispatch)
  )
}

export const exportClubbedBuyOrderList = (page,limit,sortBy) => async (dispatch) => { //ORDER LIST
  return ordersService.orderList(page,limit,sortBy).then(
    (data)  => { dispatch({ type: EXPORT_CLUBBED_BUYING_ORDER_LIST, payload: data })},
    (error) => handleErrors(error, dispatch)
  )
}

export const clubbedBuyEditOrder = (id, data) => async (dispatch) => { //ORDER EDIT
  return ordersService.editOrder(id, data).then(
    (data)  => {},
    (error) => handleErrors(error, dispatch)
  )
}

export const getOrderById = (id) => async (dispatch) => { //ORDER BY ID
  return ordersService.getOrderById(id).then(
    (data)  => { dispatch({ type: CLUBBED_BUYING_ORDER_BY_ID, payload: data, }) },
    (error) => handleErrors(error, dispatch)
  )
}

export const clubbedBuyDeleteSingleOrderProduct = (orderId,itemId) => async (dispatch) => { //ORDER SINGLE PRODUCT DELETE
  return ordersService.clubbedBuyDeleteSingleOrderProduct(orderId,itemId).then(
    (data)  => {},
    (error) => handleErrors(error, dispatch)
  )
}

export const deleteOrder = (orderId,itemId) => async (dispatch) => { //ORDER SINGLE PRODUCT DELETE
  return ordersService.deleteOrder(orderId,itemId).then(
    (data)  => {},
    (error) => handleErrors(error, dispatch)
  )
}

export const logsList = (orderId) => async (dispatch) => { //LOG LIST
  return ordersService.logsList(orderId).then(
    (data)  => { dispatch({ type: LOGS_LIST, payload: data }) },
    (error) => handleErrors(error, dispatch)
  )
}

export const newLog = (data) => async (dispatch) => { //NEW LOG
  return ordersService.newLog(data).then(
    (res)   => {dispatch(logsList(data.orderId))},
    (error) => handleErrors(error, dispatch)
  )
}

export const newOrder = (data) => async (dispatch) => { //NEW ORDER
  return ordersService.newOrder(data).then(
    (data)  => {},
    (error) => handleErrors(error, dispatch)
  )
}

export const searchOrder = (query) => async (dispatch) => { //SEARCH ORDER
  return ordersService.searchOrder(query).then(
    (data)  => {dispatch({type: SEARCH_ORDER,payload: data})},
    (error) => handleErrors(error, dispatch)
  )
}

export const regenerateInvoice = (orderId) => async (dispatch) => { //REGENERATE INVOICE
  return ordersService.regenerateInvoice(orderId).then(
    (data)  => {},
    (error) => handleErrors(error, dispatch)
  )
}