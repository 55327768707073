import React, { useEffect, useState, useRef, useMemo } from 'react'
import { connect } from 'react-redux'
import { useHistory, Link } from 'react-router-dom'
import { SET_MESSAGE } from '../../actions/types'
import axios from 'axios'
import SunEditor from 'suneditor-react'
import 'suneditor/dist/css/suneditor.min.css' 
import MainLayout from '../layouts/main.component'
import { addProduct } from '../../actions/product.action' /* Remove It */
import { updateProduct, deleteProduct } from '../../actions/product.action';
import { attributesListShow } from '../../actions/attributes.action'
import { clubbedBuyingSocietyList } from '../../actions/clubbed-buying.action'
import { categoryList } from '../../actions/category.action'
import Select from 'react-select'
import { toast } from 'react-toastify'
import { convertToArray, sunEditorSetting, handleErrors } from '../../helpers/common-function.helper'
import { unitType, stockStatus, deliveryTime, clubbedBuyingRange, productStatus, taxType, taxPercentage } from '../../helpers/product.helper'
import ProductLogs from './product-logs.component'

//IMAGE - "FilePond"
import { FilePond, File, registerPlugin } from 'react-filepond'
import "filepond/dist/filepond.min.css"
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation"
import FilePondPluginImagePreview from "filepond-plugin-image-preview"
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css"
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview, FilePondPluginFileValidateType, FilePondPluginFileValidateSize)




/* -------------------------------
					 VIEW PRODUCT
----------------------------------*/
const ViewProduct = ({dispatch, categories, societyList, attributesList, userId, message, capability, ... props}) => {
	const [productDetail, setProductDetail]           = useState()
	const [productSuggestions, setProductSuggestions] = useState([])
	const [showLog, setShowLog]                       = useState(false)

	useEffect( async () =>{
		dispatch(categoryList())
		dispatch(clubbedBuyingSocietyList())
		dispatch(attributesListShow())
		setProductDetail(await axios.get("/products/" + props.match.params.id).then(response => { return response.data }).catch(error => handleErrors(error, dispatch)))
	},[])

	useEffect(() => {
		document.title = productDetail?.variation[0].name ?  `View | ${productDetail?.variation[0].name}` : 'Loading...'
	},[productDetail])

	const [search, setSearch]                         = useState('') //SEARCH CATEGORY
	const [variationManagment, setVariationManagment] = useState([]) //PRODUCT VARIATION
	const history     = useHistory() //HISTORY
	const hsn_code    = useRef()     //FOR HSN
	const status      = useRef()     //FOR STATUS
	const keywords    = useRef()     //FOR KEYWORDS
	const brand       = useRef()     //FOR BRANDS


	/* CATEGORIES
   ============================= */
	const [categoriesData, setCategoriesData] = useState()
	const category_list = (e) =>{
		if(e.target.checked){
			setCategoriesData(data => ({
				... data,
				[e.target.name]:{
					"name"          : e.target.name,
					"category_id"   : e.target.value
				}	
			}))
		}else{
			setCategoriesData(data => ({
				... data,
				[e.target.name]:null
			}))
		}
	}

	useEffect(() => { // SET DEFAULT CATEGORY
		if(!categories || !productDetail) return
		productDetail?.categories?.map(data => {

			//CHECK CATEGORY
			var isCategoryExist = categories.filter(cData => cData.id == data.category_id) 
			var checkCategoryNameUpdate = categories.filter(cData => cData.name == data.name) 
			
			if(!productSuggestions.length){
				!isCategoryExist.length && setProductSuggestions(list => [... list, {type: 'danger', message: <>This Category <b>{data.name}</b> not exist anymore 😭</>}])
				if(isCategoryExist.length) !checkCategoryNameUpdate.length && setProductSuggestions(list => [... list, {type: 'warning', message: <>Category name updated <del className='font-weight-500' data-title={data.name}>{data.name}</del> {`->`} <b>{isCategoryExist[0]?.name}</b></>}])
			}
			
			setCategoriesData(cData => ({
				... cData,
				[data.name]:{
					"name"          : isCategoryExist.length ? isCategoryExist[0].name : data.name,
					"category_id"   : data.category_id,
					"slug"          : data?.slug == undefined ? categories?.filter(c => c.id == data.category_id)[0].slug : data?.slug,
					"isValid"       : isCategoryExist.length ? true : false,
					"old_name"      : !checkCategoryNameUpdate.length ? data.name : null
				}	
			}))
		})
	},[productDetail, categories])

	
	/* VARIATIONS "add or remove" TAB MANAGEMENT
  ============================================== */
	const [variationActive, setvariationActive] = useState()
	const [attributesListState, setAttributesListState] = useState()
	
	const [variations, setVariations] = useState() //VARIATIOINS
	var variationToArray = convertToArray(variations)

	useMemo(() => {
		productDetail?.variation.map((pData,index) => {
			setVariations(vdata => ({
				... vdata,
				[index]:<ProductVariation key={index} dispatch={dispatch} productDefaultData={pData} attributesList={attributesList} societyList={societyList} id={index} variationManagment={setVariationManagment}/>
			}))
		})
	},[productDetail, attributesList, societyList])

	function addVariation(){ //ADD VARIATIONS
		setVariations({
			...variations,
			[variationToArray.length]:<ProductVariation key={variationToArray.length} dispatch={dispatch} attributesList={attributesList} societyList={societyList} id={variationToArray.length} variationManagment={setVariationManagment}/>
		})
	}

	function removeVariation(id){ //REMOVE VARIATIONS
		document.getElementById('variation'+id).parentNode.className += ' zoomOut animated'
		setTimeout( () => { setVariations( { ...variations,[id]:null } ) }, 400 )
		setVariationManagment( data => ( { ...data, [id]:null } ) )

		// "VARIATION BUTTONS" - IF ACTIVE VARIATION BUTTON REMOVED THEN SET FIRST BUTTON ACTIVE
		{variationActive == 'variation'+id && setvariationActive(undefined)}
	}
	

	/* ==============================
		  	ADD PRODUCT "SUBMIT 😊" 
	=================================*/
	const addNewProduct = async (variationData) => {	
		document.querySelector('.add_product_btn')?.classList.remove('loading')

		const bodyData = {
			"seller"   	 : "34534",
			"updated_by" : userId,
			"hsn_code"   : hsn_code.current.value,
			"status"     : status.current.value,
			"keywords"   : keywords.current.value,
			"brand"      : brand.current.value,
			"categories" : convertToArray(categoriesData).filter(data => data !== null),
			"variations" : convertToArray(variationData).filter(data => data !== null)
		}
	}

	const submitData = () => {
		document.querySelectorAll('.add_main_product_btn').forEach(data => data.click())	
	}	

	//FIANL PRODUCT SUBMIT
	useEffect(() => {
		if(convertToArray(variationManagment).filter(fdata => fdata !== null).length == variationCount){
			addNewProduct(variationManagment)
			setVariationManagment([])
		}
	},[variationManagment])

	//VARIATION COUNT
	const [variationCount, setVariationCount] = useState(convertToArray(variations)?.filter(data => data !== null).length)
	useEffect(()=>{
		setVariationCount(convertToArray(variations)?.filter(data => data !== null).length)
	},[variations])

	document.querySelectorAll('.tabs-menu1')[0]?.addEventListener('click',function(){
		document.querySelectorAll('.filepond--image-preview-wrapper').forEach(data => {
			data.click()
		})
	})


	/* DELETE PRODUCT
  ============================================== */
	const [deleteProductById, setDeleteProductById] = useState();
	const [deleteProductTitle, setDeleteProductTitle] = useState();

	function deleteProductHandle(id,title){
		setDeleteProductById(id);
		setDeleteProductTitle(title);
	}


	return (
		<MainLayout>

			{/* BREADCRUMB */} 
			<nav className="breadcrumb mt-3 mb-0"> 
				<div className="cursor-pointer mr-4 text-primary" onClick={history.goBack}><i className='bx bxs-chevron-left'></i>Back</div>
        <div className="mr-auto page_title">View Product</div>
				<div className="breadcrumb-item" onClick={()=> history.push('/') }>Dashboard</div>
				<div className="breadcrumb-item" onClick={()=> history.push('/products') }>Products</div>
				<div className="breadcrumb-item active">View Product</div>
			</nav>


			{/* DELETE PRODUCT */}
			{deleteProductById && <DeleteProductComponent history={history} id={deleteProductById} title={deleteProductTitle} componentHandle={setDeleteProductById} dispatch={dispatch}/>}


			{/* LOADER */}
			{!variations && 
			<div className="d-flex justify-content-center flex-column align-items-center mt-5">
				<div>Wait <b>Buddy</b>...</div>
				<div className="spinner-grow" role="status"/>
			</div>}


			{/* PRODUCT WARNING */}
			{productSuggestions.length > 0 && 
				<div className='my-3 bg-white border rounded-5 px-3 pt-3 pb-2 card flash animated-1s'>
					{	productSuggestions.map((data,index) => 
						<div key={index} className={`alert alert-${data.type} alert-dismissible fade show mb-2`}>
							{data.message}
						</div>)
					}
					<div className='mt-1 mb-1'><b> Buddy</b> 😇 Update Product to remove all warning &amp; errors</div>
				</div>
			}

			
			{/* BODY */}
			{ variations &&
			<div className={`container p-0 pb-2 mb-4 ${productSuggestions.length ? `mt-0` : `mt-4` }`}>
				<div className="row">

					<div className="col-md-9">

						<div className="panel panel-primary p-0 product_tab card_layout" id="product_info_box">
							<div className=" tab-menu-heading  tab-border border-bottom shadow-base position-relative z-index-1">
								<div className="tabs-menu1 d-flex justify-content-between align-items-center">
									<ul className="nav panel-tabs main-nav-line">
										<li><div href="#productDetails" className="nav-link pl-4 pr-4 active cursor-pointer" data-toggle="tab">Product Details</div></li>
										<li>
											<div href="#variations" className="nav-link pl-4 pr-4 cursor-pointer" data-toggle="tab">
												Variations <span className="badge-primary-transparent badge_circle">{productDetail?.variation.length - 1}</span>
											</div>
										</li>
									</ul>
									{capability?.product.add ? <Link to={'/products/addnew'} className="btn btn-outline-primary mr-2" target='_blank'>+ New Product</Link> : ''}
								</div>
							</div>

							<div className="panel-body p-0 border-0 mt-0 tabs-menu-body main-content-body-right border">
								<div className="tab-content variation_tab">
									<div className="tab-pane active" id="productDetails">
											{variationToArray[0]}
									</div>
									
                    <div className="tab-pane" id="variations">
                      {variationToArray.length > 1 &&
                      <div className="d-flex overflow-auto pt-3 pb-3 pr-3 pl-3 shadow-sm variation-btns z-index-10 position-relative">

                        {/* VARIATION TABS FUNCTIONALITY MANAGMENT WITH CSS */}
                        <style>
                          {variationActive == undefined && `.variation_btn:nth-of-type(1) > .btn{background:#003df2;color:white}`}
                          {variationActive == undefined && `.variation_box > div:nth-of-type(1){display:block}`}
                          {`${variationActive &&`#${variationActive}`}{background:#003df2;color:white}`}
                          {`${variationActive &&`.${variationActive}`}{display:block}`}
                        </style>

                        { variationToArray.map((data,index) => {
                            if(index !== 0 && data !== null){
                              return(
                                <div key={index} className="variation_btn position-relative text-nowrap mr-3 zoomIn animated">
                                  <div id={`variation${index}`} onClick={(e) => setvariationActive(e.target.id)} className="btn btn-outline-primary"> Variation</div>
                                </div>
                              )
                            }
                        })}

                      </div>}

										{ variationToArray.filter(data => data != null).length <= 1 &&
                      <div className="p-5 text-center font-weight-500 text-secondary flex-wrap d-flex align-items-center justify-content-center">
                        Still, No Product Variation Added  <i className='bx bx-laugh font-size-20 text-primary ml-2'></i>
                        <div className="w-100 mt-3"><button onClick={() => history.push('/products/edit/'+productDetail?.id)} className="btn btn-primary">+ Add Variation</button></div>
                      </div>
										}

										<div className="variation_box">
											{ variationToArray.map((data,index) => {
													if(index !== 0 && data !== null){
														return  <div key={index} className={`variation${index}`}>{data}</div>
													}
											})}	
										</div>

									</div>
								</div>
							</div>
						</div>
					</div>

					
					{/* Form Sidebar */}
					<div className="col-md-3">

						<div className="card p-3">
							<div className="d-flex justify-content-between mb-3">
								<span className="font-weight-500">Status</span>
                <div>
                  {productDetail?.status === 'pending' ? <span className="badge badge-warning text-capitalize badge_custom">{productDetail?.status}</span>:''}
                  {productDetail?.status === 'published' ? <span className="badge badge-success text-capitalize badge_custom">{productDetail?.status}</span>:''} 
                  {productDetail?.status === 'drafts' ? <span className="badge badge-secondary text-capitalize badge_custom">{productDetail?.status}</span>:''}
                  {productDetail?.status === 'review' ? <span className="badge badge-primary text-capitalize badge_custom">{productDetail?.status}</span>:''}
                </div>
							</div>

							<div className="input_wrap w-100 pointer-event-none">
								<input disabled ref={brand} type="text" placeholder=" " defaultValue={productDetail?.brand}/>
								<label>Brand</label>
							</div>

							<div className="input_wrap w-100 mt-3 pointer-event-none">
								<input disabled type="text" placeholder=" " defaultValue={productDetail?.seller}/>
								<label>Seller</label>
							</div>

							<div className="input_wrap w-100 mt-3 pointer-event-none">
								<input disabled type="text" placeholder=" " defaultValue={productDetail?.hsn_code}/>
								<label>HSN Code</label>
							</div>
							
              <div className="font-weight-400 mt-3 mb-1 text-secondary font-size-13">Keywords</div>
              <div className="d-flex flex-wrap">
								{productDetail?.keywords ? productDetail?.keywords?.split(',').map( (data,index) => (
									<span key={index} className="tag mb-1 mr-1">{data}</span>
								)) : <span className='text-secondary'>😭 No Keywords Added</span>}
							</div>

              <div className="font-weight-400 text-secondary mt-3 mb-1 font-size-13">Category</div>
							<div className="d-flex flex-wrap">
								{convertToArray(categoriesData)?.filter(data => data !== null)?.map( (data,index) => (
									data?.isValid == false ? 
									<span key={index} className={`tag mb-1 mr-1 cursor-pointer tag-danger text-decoration-line-throught pointer-event-none`}>{data.name}</span> :
									<span key={index} className="tag mb-1 display-unset mr-1 cursor-pointer" onClick={() => document.querySelector(`li[data-name='${data?.name.toLowerCase()}']`).scrollIntoView()}>{data?.old_name ? <><del>{data.old_name}</del> {`->`} <b>{data?.name}</b></> : data.name}</span> 
								))}
							</div>
							
							{capability?.product.edit    ? <Link to={'/products/edit/'+productDetail?.id}> <button className="btn btn-primary mt-3 w-100"><i className='bx bx-link-external'></i> Edit Product</button> </Link> : null}
							<button className="btn btn-outline-primary mt-2 add_product_btn no-tab-index" onClick={() => setShowLog(true)}><i className='bx bx-list-ul'></i> Show Logs</button>
							{capability?.product.delete ? <button className="btn btn-white mt-2" onClick={() => deleteProductHandle(productDetail?.id, productDetail?.variation.length == 1 && productDetail?.variation[0].name || productDetail?.variation.length > 1 && "All Vaiations")}><i className='bx bx-trash'></i> Delete Product</button> : null}

						</div>

					</div>
				</div>
			</div>}

			{/* PRODUCT LOGS */}
			{showLog && <ProductLogs id={productDetail?.id} handleComponent={setShowLog}/>}

		</MainLayout>
	)
}




/* -------------------------------
				 CLUBBED BUYING
----------------------------------*/
const ClubbedBuyingField = ({priceWindow,id,defaultData}) =>{

	const [priceRange, setPriceRange] = useState();
	const [price, setPrice] =  useState();

	const priceRangeRef = useRef();
	const priceRef = useRef();

	return(
		<div className="mt-4 mb-3 ml-0 mr-0 pt-3 pb-2 pl-3 pr-3 custom-legend">
			<div className="row">
				<div className="col-12 d-flex">
					<div className="font-size-a14 text-secondary custom-legend-heading">Price Range</div>
				</div>

				{/* PRICE RANGE */}
				<div className="col-md-6 mb-2">
					<input disabled type='text' defaultValue={defaultData.range} className='form-control'/>
				</div>

				{/* PRICE */}
				<div className="col-md-6 mb-2">
					<div className="input_wrap w-100">
						<input	disabled defaultValue={defaultData.price} type="number" onChange={e => setPrice(e.target.value)}  placeholder=" "/>
						<label>Price</label>
					</div>
				</div>
			</div>
		</div>
	)
}




/* -------------------------------
				     ATTRIBUTE
----------------------------------*/
const AttributeComponent = ({attributesList, attributeValue, id, defaultData, listOptions}) =>{

	const [options, setOptions]                                     = useState();
	const [attributeDefaultValueData, setAttributeDefaultValueData] = useState([]);
	const [attributeValueManage, setAttributeValueManage]           = useState();
	const attributeNameRef                                          = useRef();

	useEffect(() =>{
		defaultData && attributeValue(prevValue => ({
			...prevValue,
			[id]: {
				"name":defaultData.name,
				"value":defaultData.value.split(',').map(data => ({
					"name":data,
					"value":data
				}))
			}
		}))
	},[defaultData]);


	function handleAttributeValue(value){
		setAttributeValueManage(value);
		attributeValue(prevValue => ({
			...prevValue,
			[id]: {
				"name":attributeNameRef?.current?.value,
				"value":value
			}
		}))
	}

	function handleAttributeName(event){
		setAttributeValueManage(null)
		attributeValue(prevValue => ({
			...prevValue,
			[id]: {
				"name":attributeNameRef?.current?.value,
				"value":attributeValueManage
			}
		}));

		var index = event.target.selectedIndex;
    var optionElement = event.target.childNodes[index]
    var option =  optionElement.getAttribute('id');
		var optionData = attributesList?.filter(attr => attr.parent == option);
		if(optionData.length){
			setOptions([]);
			optionData.map((attr,index) => (
				setOptions(prev => ([
					... prev,
					{
						value:attr.name,
						label:attr.name
					}
				]))
			))
		}else{
			setOptions([]);
		}
	}


	// DEFAULT ATTRBUTE VALUE "DROPDOWN OPTIONS" ON LOAD
	useEffect(() => {
		var defaultOptionParent = attributesList?.filter(attr => attr.name == defaultData.name && attr.parent != undefined);
		var defaultOptionParentDropdownData = attributesList?.filter(attr => attr.parent == defaultOptionParent[0]?.id);
		if(defaultOptionParentDropdownData?.length){
			defaultOptionParentDropdownData?.map(attr => (
				setOptions(prev => {
					if(!prev == undefined){
						return [
							... prev,
							{
								value:attr.name,
								label:attr.name
							}
						]
					}
				})
			))
		}else{
			setOptions([]);
		}
	},[attributesList, defaultData]);

	// ATTRIBUTES VALUE "SELECTED TAGS"
	useMemo(() => { 
		var defaultAttrData =  defaultData?.value?.split(',');
		defaultData?.value?.length && defaultAttrData?.map(data => (
			setAttributeDefaultValueData(prev => ([
				... prev,
				{
					value:data,
					label:data
				}
			]))
		));
	},[defaultData]);

	return(
		<div className='col-md-12 row mt-1'>
			<div className="col-md-6 mb-2">
				{listOptions?.length &&
					<div className='select-box'>
					<label>Product Attribute</label>
					<select disabled defaultValue={defaultData.name} ref={attributeNameRef} name="attributes_name" className="form-control">
						<option value=''>Select...</option>
						{attributesList && attributesList.filter(attr => attr.parent == '').map((attr,index) => (
							<option key={index} id={attr.id} value={attr.name}>{attr.name}</option>
						))}
					</select>
				</div>	
				}	
			</div>
			<div className="col-md-6 mb-2">
				<div className='select-box pointer-event-none'>
					<label>Attribute Options</label>
					<Select 
						tabIndex={-1}
						isMulti
						disabled
						value={attributeValueManage}
						closeMenuOnSelect={false}
						defaultValue={attributeDefaultValueData}
						onChange={(e) => {handleAttributeValue(e)}}
						options={options}
						className="basic-multi-select react-select"
					/>
				</div>
			</div>
		</div>
	)
}



/* -------------------------------
					PRODUCT VARIATION
----------------------------------*/
const ProductVariation = ({attributesList, societyList, id, variationManagment, dispatch, productDefaultData}) => {

	const [featuredImage, setFeaturedImage]       = useState([])
	const [otherImages, setOtherImages]           = useState([])
	const [imageUrl, setImageUrl]                 = useState([])
	const [tab, setTab]                           = useState('Description')

	useEffect(() => { //SET DEFAULT IMAGES
		setFeaturedImage(productDefaultData?.images?.filter(data => data.featured == "yes").map(data => decodeURIComponent(data.link)))
		setOtherImages(productDefaultData?.images?.filter(data => data.featured == "no").map(data => decodeURIComponent(data.link)))
	},[productDefaultData])

	// ATTRIBUTE FUNCTIONALITY
	const [attributeValue, setAttributeValue] = useState([]);//STORE ON CHANGE Attribute Value
	const [attribute, setAttribute] = useState([]); // ADD MORE BUTTON FUNCTIONALITY
	const [attributeListOptions, setAttributeListOptions] = useState(); // ATTRIBUTE PARENTS FILTER

	const addAttributes = (e) =>{ // ADD ATTRIBUTE ON CLICK "ADD MORE"
		setAttribute(attribute.concat(<AttributeComponent key={attribute.length + 1} listOptions={attributeListOptions} defaultData={''} id={attribute.length + 1} attributesList={attributesList} attributeValue={setAttributeValue}/>))
	}

	useEffect(() =>{
		productDefaultData?.attributes?.map(() => setAttribute(prev => [
			...prev,
			''
		]));
		setAttributeListOptions(attributesList?.filter(attr => attr.parent == ''));
	},[attributesList])
	

	//CLUBBED BUYING FUNCTIONALITY
	const [clubbedBuying, setClubbedBuying] = useState();// CLUBBED BUYING "yes" - "no"
	const [clubbedBuyingField, setClubbedBuyingField] = useState([]); //PRICE RANGE FIELD
	const [priceWindow, setPriceWindow] = useState([]);//STORE PRICE RANGE VALUE ON CHANGE
	const addclubbedBuyingField = e => { // ADD PRICE RANGE FIELD
		setClubbedBuyingField(clubbedBuyingField.concat(<ClubbedBuyingField key={clubbedBuyingField.length + 1} defaultData='' id={clubbedBuyingField.length + 1} priceWindow={setPriceWindow}/>));
	}
	
	useEffect(()=> {
		setClubbedBuying(productDefaultData?.is_clubbed_buying == "yes" ? true : false)
	},[productDefaultData])


	//DEALS
	const [deals, setDeals] = useState(productDefaultData?.deals?.is_exclusive == 'yes' ? true: false);// CLUBBED BUYING "yes" - "no"

	// SOCIETY LIST
	const [societyListArray, setSocietyListArray] = useState([])
	const [societyValue, setSocietyValue] = useState([])
	const [societyDefaultValue, setSocietyDefaultValue] = useState([]) // DEFAULT VALUE


	useMemo(() => { // DEFAULT VALUE SHOW IN RIGH FORMATE
		setSocietyValue(productDefaultData?.clubbed_buying.society)
		setSocietyDefaultValue([])
		productDefaultData?.clubbed_buying.society.map(data => setSocietyDefaultValue(prev => ([
			...prev,
			{
				value:data,
				label:societyListArray?.filter(sData => sData?.value == data)[0]?.label
			}
		])));
	},[productDefaultData,societyListArray]);


	const societyHandleChange = (e) => {
		setSocietyValue(Array.isArray(e) ? e.map(x => x.value) : []);
	}
	useMemo(() =>{
		societyList?.map(society => (
			setSocietyListArray(prev => ([
				...prev,
				{
					value:society.id,
					label:society.name
				}
			]))
		))
	},[societyList]);


	// FUNCTION ==> UPLODED IMAGE TO SERVER TO GET URL
	function uploadImage(data, featured, name){
		data.map((iData, index) => {
			if(typeof iData.source == 'string') {
				setImageUrl(prevValue => ({
					...prevValue,
					[name + index]: {
						"link":encodeURI(iData.source),
						"featured": featured
					}
				}))
			}else{
				var imgInfo = new FormData();
				imgInfo.append('file', iData.file);
				imgInfo.append('foldername', 'p');
				
				axios.post("/file/upload", imgInfo)      
				.then((response) => {
					setImageUrl(prevValue => ({
						...prevValue,
						[name + index]: {
							"link":response.data,
							"featured": featured
						}
					}))
				}).catch(err => dispatch({ type:SET_MESSAGE, payload:err.response.data }))
			}
		})
	}


	const name                 = useRef()
	const sku_id               = useRef()
	const unit_name            = useRef()
	const unit_value           = useRef()
	const stock_status         = useRef()
	const total_stock_quantity = useRef()
	const mrp                  = useRef()
	const listing_price        = useRef()
	const purchase_price       = useRef()
	const order_size_min       = useRef()
	const order_size_max       = useRef()
	const description          = useRef()
	const features             = useRef()
	const is_clubbed_buying    = useRef()
	const pincode              = useRef()
	const price_min_range      = useRef()
	const city                 = useRef()
	const is_exclusive         = useRef()
	const discount_percentage  = useRef()
	const start_time           = useRef()
	const start_date           = useRef()
	const end_time             = useRef()
	const end_date             = useRef()

	
	/* ==============================
		  ADD VARIATION "SUBMIT 😊" 
	=================================*/
	function addVariation(e){
		e.preventDefault()
		setImageUrl([])

		//UPLOAD IMAGE
		uploadImage(featuredImage,'yes','feature')
		uploadImage(otherImages,'no','other')

		//ATTRIBUTES VALUE CHANGE FORMATE
		var attributeValueData = convertToArray(attributeValue)
		const attrFinalData = []
		attributeValueData?.map((data,i) => {
			var attrValue = ''
			data.value?.map((vdata,index) => {
				attrValue = attrValue+(index > 0 ? ",":"")+vdata.value			
			})
			const attrValueFormate={
				"name" : data.name,
				"value" : attrValue
			}
			attrFinalData.push(attrValueFormate)				
		})


		var imageLength = featuredImage.length + otherImages.length;
		var imageCheck = 0;
		var finalImageData = [];

		var submitVariationFinal = setInterval(() => {
			if( imageCheck == imageLength){
				variationManagment(data => {
					if(id != 0 && data[0] || id == 0){
						clearInterval(submitVariationFinal)
						return {
							... data,
							[id]:{
								"name"                 : id != 0 && variationName ? data[0].name : name.current.value,
								"sku_id"               : sku_id.current.value,
								"unit"                 :
									{
										"name"             : id != 0 && variationOtherDetails ? data[0].unit.name : unit_name.current.value,
										"value"            : id != 0 && variationOtherDetails ? data[0].unit.value : unit_value.current.value
									},
								"stock_status"         : stock_status.current.value,
								"total_stock_quantity" : id != 0 && variationOtherDetails ? data[0].total_stock_quantity : total_stock_quantity.current.value,
								"price"                :
									{
										"mrp"              : id != 0 && variationPrice ? data[0].price.mrp : mrp.current.value,
										"listing_price"    : id != 0 && variationPrice ? data[0].price.listing_price : listing_price.current.value,
										"purchase_price"   : id != 0 && variationPrice ? data[0].price.purchase_price : purchase_price.current.value
									},
								"order_size"           :
									{
										"min"              : id != 0 && variationPrice ? data[0].order_size.min : order_size_min.current.value,
										"max"              : id != 0 && variationPrice ? data[0].order_size.max : order_size_max.current.value
									},
								"content"              : 
									{
										"description"      : id != 0 && variationContent ? data[0].content.description : description.current.editor.getContents(),
										"features"         : id != 0 && variationContent ? data[0].content.features : features.current.editor.getContents()
									},
								"attributes"           : id != 0 && variationAttributes ? data[0].attributes : attrFinalData,
								"images"               : id != 0 && variationImages ? data[0].images : convertToArray(finalImageData),
								"videos"               : [],
								"is_clubbed_buying"    : id != 0 && variationClubbedBuying ? data[0].is_clubbed_buying : is_clubbed_buying.current.checked ? "yes" : "no",
								"clubbed_buying"       : 
									{
										"price_window"     : id != 0 && variationClubbedBuying ? data[0].clubbed_buying.price_window : convertToArray(priceWindow),
										"price"            :
											{
												"min"          : id != 0 && variationClubbedBuying ? data[0].clubbed_buying.price.min : price_min_range.current.value,
												"max"          : id != 0 && variationPrice ? data[0].clubbed_buying.price.max : mrp.current.value
											},
										"society"          : id != 0 && variationClubbedBuying ? data[0].clubbed_buying.society : societyValue,
										"pincode"          : id != 0 && variationClubbedBuying ? data[0].clubbed_buying.pincode : pincode.current.value,
										"city"             : id != 0 && variationClubbedBuying ? data[0].clubbed_buying.city : city.current.value	
									},
								"deals"                :
									{
										"is_exclusive"     : id != 0 && variationDeals ? data[0].deals.is_exclusive : is_exclusive.current.checked ? "yes" : "no",
										"discount"         : 
											{
												"percentage"   : id != 0 && variationDeals ? data[0].deals.discount.percentage : discount_percentage.current.value
											},
										"start_time"       : id != 0 && variationDeals ? data[0].deals.start_time : `${start_date.current.value}T${start_time.current.value}:00.000Z`,
										"end_time"         : id != 0 && variationDeals ? data[0].deals.end_time : `${end_date.current.value}T${end_time.current.value}:00.000Z`
									}
							}
						}
					}
					return data
				})	
			}
		}, 500)

		var checkImageUploadedOrNot = 
			setInterval(() => {
				setImageUrl(imageUrl => {

					if(convertToArray(imageUrl).length  == imageLength){
						clearInterval(checkImageUploadedOrNot)
						imageCheck = imageLength
						finalImageData = imageUrl
						return imageCheck, finalImageData
					}

					return imageUrl
				})
			},500);
	}

	useEffect(() => document.getElementById('refresh_spy_scroll').click(),[])

	// FUNCTIONAITY ==> SAME AS MAIN "In Variation"
	const [variationName, setVariationName]                   = useState(productDefaultData ? false : true)
	const [variationPrice, setVariationPrice]                 = useState(productDefaultData ? false : true)
	const [variationAttributes, setVariationAttributes]       = useState(productDefaultData ? false : true)
	const [variationLogistics, setVariationLogistics]         = useState(productDefaultData ? false : true)
	const [variationOtherDetails, setVariationOtherDetails]   = useState(productDefaultData ? false : true)
	const [variationImages, setVariationImages]               = useState(productDefaultData ? false : true)
	const [variationContent, setVariationContent]             = useState(productDefaultData ? false : true)
	const [variationClubbedBuying, setVariationClubbedBuying] = useState(productDefaultData ? false : true)
	const [variationDeals, setVariationDeals]                 = useState(productDefaultData ? false : true)
	
	function sidebarNav(e){
		e.preventDefault();
		e.stopPropagation(); 
		document.querySelector(e.target.getAttribute('href')).scrollIntoView()
	}

	return(
		<form className="d-flex variation_content" data-main={id == 0 && true} onSubmit={e => addVariation(e)}>
			<button className="d-none add_main_product_btn">Add&nbsp;Product</button>
			<div id="refresh_spy_scroll"></div>
			
			<div id={`product_details`+id} className="list-group p_details shadow-sm productDetails_sidebar">
				<nav className="nav nav-pills flex-column">
					<a className="nav-link" onClick={e => sidebarNav(e)} href={`#details_tab`+id}>Details</a>
					<nav className="nav nav-pills flex-column">
						<a className="nav-link ml-3 my-1" onClick={e => sidebarNav(e)} href={`#detail_price`+id}>Price</a>
						<a className="nav-link ml-3 my-1" onClick={e => sidebarNav(e)} href={`#other_details`+id}>Other Details</a>
						<a className="nav-link ml-3 my-1" onClick={e => sidebarNav(e)} href={`#detail_image`+id}>Images &amp; Videos</a>
						<a className="nav-link ml-3 my-1" onClick={e => sidebarNav(e)} href={`#product_description`+id}>Description</a>
						<a className="nav-link ml-3 my-1" onClick={e => sidebarNav(e)} href={`#detail_attribute`+id}>Attribute</a>
						<a className="nav-link ml-3 my-1" onClick={e => sidebarNav(e)} href={`#logistics`+id}>Logistics</a>
					</nav>
					<a className="nav-link mt-1" onClick={e => sidebarNav(e)} href={`#clubbed_buying`+id}>Clubbed Buying</a>
					<a className="nav-link mt-1" onClick={e => sidebarNav(e)} href={`#deals`+id}>Deals Discount</a>
				</nav>
			</div>
			
			<div data-spy="scroll" data-target={`#product_details`+id} data-offset="0" className="scrollspy-product position-relative pl-4 pr-4 pb-4">
				<div id={`details_tab`+id}>

					<div className="pt-4 d-flex">
						<div className="input_wrap w-100">
							<input disabled ref={name} defaultValue={productDefaultData?.name} data-disable={variationName} type="text" placeholder=" "/>
							<label>Product Name</label>
						</div>
						
						{id != 0 &&
							<label className="custom-switch cursor-pointer">
								<small className="mr-2 text-secondary">&nbsp;&nbsp;Same&nbsp;As&nbsp;Main</small>
								<input disabled onChange={() => setVariationName(!variationName)} type="checkbox" defaultChecked={variationName} className="custom-switch-input"/>
								<span className="custom-switch-indicator"></span> 
							</label>
						}
					</div>

					{/* PRICE */}
					<div id={`detail_price`+id}>
						<div className="form-group mb-0 heading pt-5">
							<div className="d-flex justify-content-between"> 
								<span className="mr-2 mb-2 heading-simple">Price</span>
							</div>
						</div>

						<div className="row" data-disable={variationPrice}>
							<div className="col-md-4 d-flex">
								<div className="input-group-prepend">
									<span className="input-group-text">₹</span> 
								</div>
								<div className="input_wrap w-100">
									<input disabled ref={mrp} defaultValue={productDefaultData?.price.mrp} type="number" placeholder=" "/>
									<label>MRP</label>
								</div>
							</div>

							<div className="col-md-4 d-flex">
								<div className="input-group-prepend">
									<span className="input-group-text">₹</span> 
								</div>
								<div className="input_wrap w-100">
									<input disabled ref={listing_price} defaultValue={productDefaultData?.price.listing_price} type="number" placeholder=" "/>
									<label>Price</label>
								</div>
							</div>

							<div className="col-md-4 d-flex">
								<div className="input-group-prepend">
									<span className="input-group-text">₹</span> 
								</div>
								<div className="input_wrap w-100">
									<input disabled ref={purchase_price} defaultValue={productDefaultData?.price.purchase_price} type="number" placeholder=" "/>
									<label>Cost price</label>
								</div>
							</div>
						</div>
					</div>

					{/* OTHER DETAILS */}
					<div id={`other_details`+id}>
						<div className="form-group mb-0 heading pt-5" id={`other_details`+id}>
							<div className="d-flex justify-content-between"> 
								<span className="mr-2 mb-2 heading-simple">Other Details</span>
							</div>
						</div>

						<div className="row mt-1">

							<div className="row m-0 p-0" data-disable={variationOtherDetails}>
								<div className='m-0 col-md-6'>
									<div className="input_wrap w-100">
										<input disabled ref={order_size_min} defaultValue={productDefaultData?.order_size.min} type="number" placeholder=" "/>
										<label>Minimum - Order Limit</label>
									</div>
								</div>
								
								<div className='m-0 col-md-6'>
									<div className="input_wrap w-100">
										<input disabled ref={order_size_max} defaultValue={productDefaultData?.order_size.max} type="number" placeholder=" "/>
										<label>Maximum - Order Limit</label>
									</div>
								</div>

								<div className='m-0 col-md-6 mt-3'>
									<div className="input_wrap w-100">
										<input disabled ref={total_stock_quantity} defaultValue={productDefaultData?.total_stock_quantity} type="number" placeholder=" "/>
										<label>Available Stock - Quantity</label>
									</div>
								</div>

								<div className="col-md-6 m-0 mt-3">
									<div className='select-box'>
										<label>Stock Status</label>
										{stockStatus.filter(option => option == productDefaultData?.stock_status).length || productDefaultData == undefined ?
										<select disabled ref={stock_status} defaultValue={productDefaultData?.stock_status} className="form-control">
											{stockStatus.map((option,i) => <option key={i} value={option}>{option}</option>)}
										</select>
									: toast.error(`Stock Status "${productDefaultData?.stock_status}" not available \n \nPlease Change it.`, {toastId:'stock_status'})}
									</div>
								</div>
							</div>

							<div className='m-0 mt-3 col-md-6'>
								<div className="input_wrap w-100">
									<input disabled ref={sku_id} defaultValue={productDefaultData?.sku_id} type="text" placeholder=" "/>
									<label>SKU</label>
								</div>
							</div>

							<div className="d-flex m-0 mt-3 col-md-6">
								<div className="input_wrap w-100">
									<input disabled ref={unit_value} defaultValue={productDefaultData?.unit.value} type="text" placeholder=" "/>
									<label>Unit</label>
								</div>
								<div className="input-group-append">
									{unitType.filter(option => option == productDefaultData?.unit.name).length ?
										<select disabled ref={unit_name} defaultValue={productDefaultData?.unit.name} className="input-group-text form-control h-100 min-width-100">
											{unitType.map((option,i) => <option key={i} value={option}>{option}</option>)}
										</select>
									: toast.error(`Unit Type "${productDefaultData?.unit.name}" not available \n \nPlease Change it.`,{toastId:'unit_name'})}
								</div>
							</div>
						</div>
					</div>

					<div id={`detail_image`+id} className="pointer-event-unset">
						{/* IMAGE */}
						<div className="form-group mb-0 heading pt-5" id={`detail_image`+id}>
							<div className="d-flex justify-content-between"> 
								<span className="mr-3 heading-simple">Images
									<span className='position-relative d-inline-block'>
										<button type='button' className='tag border-0 ml-2 popup-contol outline-unset btn btn-gray-700'>Show Image Link</button>
										<div className="popover shadow-2 animated show bs-popover-bottom t-40 r--0" role="tooltip"  x-placement="top">
											<div className="arrow"></div>
											<h3 className="popover-header d-flex font-size-13 d-flex align-items-center">Image Link</h3>
											<div className="popover-body" style={{minWidth:300}}>
												{productDefaultData.images.map((iData,i) => (
													<div key={i} className="d-flex align-items-center mb-2 last-mb-0"><img className="rounded avatar-md mr-2 border" src={iData?.link}/>
														<div className="text-left">
															<a href={iData?.link} target='_blank' className='text-overflow-hidden d-block w-250 mr-5'><i className='bx bx-link-external'></i> {iData?.link}</a>
															{iData?.featured == 'yes' ? <span className="tag tag-green mb-1 mr-1">Featured</span> : <span className="tag mb-1 mr-1">Other Image</span>}
														</div>
													</div>
												))}
											</div>
										</div>
									</span>
								</span>
							</div>
						</div>
						
						<div className="row" data-disable={variationImages}>
							<div className="col-md-6">
								<div className="text-center font-size-16 text-black-50 "><small>Featured Image</small></div>
								<FilePond
									files={featuredImage}
									allowReorder={true}
									onupdatefiles={(e) => setFeaturedImage(e)}
									maxFiles={1}
									disabled
									storeAsFile={true}
									maxFileSize={'1MB'}
									acceptedFileTypes={['image/png', 'image/jpeg', 'image/jpg']}
									name="test111" 
									labelIdle='Drag & Drop Image or <span class="filepond--label-action">Browse</span>'
								/>
							</div>
							<div className="col-md-6 other-images">
									<div className="text-center font-size-16 text-black-50 "><small>Other Images</small></div>
									<FilePond
										files={otherImages}
										allowReorder={true}
										onupdatefiles={setOtherImages}
										allowMultiple={true}
										maxFiles={8}
										disabled
										storeAsFile={true}
										maxFileSize={'1MB'}
										acceptedFileTypes={['image/png', 'image/jpeg', 'image/jpg']}
										name="files" 
										labelIdle='Drag & Drop Image or <span class="filepond--label-action">Browse</span>'
									/>
							</div>
						</div>

					  {/* VIDEO */}
						<div className="form-group mb-0 heading pt-5">
							<div className="d-flex justify-content-between"> 
								<span className="mr-2 heading-simple">Video</span>
							</div>
							<div className='row'>
								{productDefaultData?.videos?.map((video, index) => <React.Fragment key={index}>
									<div className='col-md-6 mt-2'>
										<iframe 
											src={`https://www.youtube.com/embed/${video?.link}`} 
											title="YouTube video player" 
											frameBorder="0" 
											className='rounded overflow-hidden w-100'
											allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
											allowFullScreen>
										</iframe>
										<a target='_blank' href={`https://www.youtube.com/embed/${video?.link}`} className='badge border line-1'>
											{video?.is_hero_video == 'yes' && <span className='badge badge-teal-transparent mr-1'>Featured</span>}
											https://www.youtube.com/embed/{video?.link}
										</a>
									</div>
									</React.Fragment>)}
									{productDefaultData?.videos.length == 0 && <div className='col-md-12'><span className='pl-3 text-secondary badge badge-info-transparent'>0 Video Added</span></div>}
							</div>
						</div>

					</div>


					{/*TAB - DESCRIPTION & FEATURES */}						
					<div id={`product_description`+id}>
						<div className="panel panel-primary p-0 product_tab pt-5" id={`product_description`+id}>
							<div className=" tab-menu-heading  tab-border border-bottom">
								<div className="tabs-menu1 d-flex justify-content-between align-items-center">
									<ul className="nav panel-tabs main-nav-line" data-disable={variationContent}>
										<li><a className={`nav-link cursor-pointer pl-2 pr-2 ${tab == "Description" ? 'active text-primary' : ''}`} onClick={() => setTab('Description')}>Description</a></li>
										<li><a className={`nav-link cursor-pointer pl-2 pr-2 ${tab == "Features" ? 'active text-primary' : ''}`} onClick={() => setTab('Features')}>Features</a></li>
									</ul>
								</div>
							</div>

							<div data-disable={variationContent} className="panel-body p-0 pt-3 border-0 mt-0 tabs-menu-body main-content-body-right border">
							<div className={`no-tab-index ${tab != "Description" ? "d-none" : ""}`}>
									<SunEditor 
										placeholder="Product description..." 
										setOptions={{ height: 210, buttonList: sunEditorSetting }} 
										defaultValue={productDefaultData?.content.description} 
										hideToolbar={true}
										disable={true}
										lang="en"
									/>
								</div>
								<div className={`no-tab-index ${tab != "Features" ? "d-none" : ""}`}>
									<SunEditor 
										placeholder="Product features..." 
										setOptions={{ height: 210, buttonList: sunEditorSetting }}  
										defaultValue={productDefaultData?.content.features}
										lang="en"
										hideToolbar={true}
										disable={true}
									/>
								</div>
							</div>
						</div>
					</div>


					{/* ATTRIBUTES */}	
					<div id={`detail_attribute`+id}>
						<div className="form-group mb-0 heading pt-5 mb-2 pb-1" id={`detail_attribute`+id}>
							<div className="d-flex justify-content-between"> 
								<div className="attribute_heading">
									<span className="mr-3 heading-simple">Attributes</span>
								</div>
							</div>
						</div>

						<div className="position-relative z-index-100" data-disable={variationAttributes}>
							<div className="row">
									{ productDefaultData?.attributes?.length	?
										productDefaultData?.attributes.map((data,index) => (
											<AttributeComponent key={index} listOptions={attributeListOptions} id={index} defaultData={data} attributesList={attributesList} attributeValue={setAttributeValue}/>
										))
									:
										<AttributeComponent id={0} listOptions={attributeListOptions} defaultData={''} attributesList={attributesList} attributeValue={setAttributeValue}/>
									}
                  {attribute}
							</div>
						</div>
					</div>


					{/* LOGISTICS */}	
					<div id={`logistics`+id}>
						<div className="form-group mb-0 heading pt-5 mb-2 pb-1">
							<div className="d-flex justify-content-between"> 
								<div className="attribute_heading">
									<span className="mr-3 heading-simple">Logistics</span>
								</div>
							</div>
						</div>

						<div className="position-relative z-index-100" data-disable={id != 0 ? variationLogistics : false}>
							<div className="row">
								<div className="col-md-12 m-0 mt-1">
									<div className='select-box'>
										<label>Delivery Days</label>
										<select disabled defaultValue={productDefaultData?.logistics?.delivery} className="form-control">
											<option className='text-secondary' value={''}>Select delivery time...</option>
											{deliveryTime.map((option,i) => <option key={i} value={option}>{option} Day{i > 0 && 's'}</option>)}
										</select>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				{/* CLUBBED BUYING */}
				<div id={`clubbed_buying`+id}>
					<div className="position-relative z-index-1 pt-5">
						{/* HEADING */}
						<div className="align-items-center d-flex justify-content-between heading">
								<div className="form-check d-flex align-items-center">
									<input disabled ref={is_clubbed_buying} onChange={e => setClubbedBuying(e.target.checked ? true: false)} defaultChecked={productDefaultData?.is_clubbed_buying == "yes" ? true : false} className="form-check-input mt-0" type="checkbox" name="clubbed_buying_status" id="clubbed_buying_status"/>
									<label className="form-check-label cursor-pointer" htmlFor="clubbed_buying_status">Clubbed Buying</label>
								</div>
						</div>
						
						<div data-disable={variationClubbedBuying} className={!clubbedBuying ? 'clubbed_buying_disabled' : ''}>
							{/* PRICE RANGE */}
							{productDefaultData?.clubbed_buying.price_window.length?
								productDefaultData?.clubbed_buying.price_window.map((data,index) => (	
									<ClubbedBuyingField key={index} defaultData={data} id={index} priceWindow={setPriceWindow}/>
								))
							:
								<ClubbedBuyingField id={0} defaultData=''  priceWindow={setPriceWindow}/>
							}
							{clubbedBuyingField}
						
							<div className="row">

								{societyList && <>
										<div className='col-md-12 mb-3'>
											<div className='select-box pointer-event-none'>
												<label>Select Societies</label>
												<Select
													tabIndex={-1}
													disabled
													isMulti
													closeMenuOnSelect={false}
													defaultValue={societyDefaultValue}
													onChange={societyHandleChange}
													options={societyListArray}
													className="basic-multi-select react-select"
													placeholder="Societies..."
												/>
											</div>
										</div>
								</>}

								{/* <div className="col-md-6 mb-3">
									<div className="input_wrap w-100">
										<input disabled ref={price_min_range} defaultValue={productDefaultData?.clubbed_buying.price.min} type="number"  placeholder=" "/>
										<label>Min Price - Range</label>
									</div>
								</div>
								<div className="col-md-6 mb-3">
									<div className="input_wrap w-100 pointer-event-none">
										<input disabled type="text" defaultValue="Max Price = MRP"  placeholder=" "/>
										<label>Max Price - Range</label>
									</div>
								</div> */}
								<div className="col-md-6">
									<div className="input_wrap w-100">
										<input disabled ref={pincode} defaultValue={productDefaultData?.clubbed_buying.pincode} type="number"  placeholder=" "/>
										<label>Pincode</label>
									</div>
								</div>
								<div className="col-md-6">
									<div className="input_wrap w-100 pointer-event-none">
										<input disabled ref={city} type="text" defaultValue={productDefaultData?.clubbed_buying.city}   placeholder=" "/>
										<label>City</label>
									</div>
								</div> 
							</div>	
						</div>	
					</div>	
				</div>
				
				{/* DEALS */}
				<div id={`deals`+id} className="position-relative z-index-1 pt-5">
					{/* DEALS - HEADING */}
					<div className="align-items-center d-flex justify-content-between heading mb-2">
							<div className="form-check d-flex align-items-center">
								<input disabled ref={is_exclusive} defaultChecked={productDefaultData?.deals.is_exclusive == "yes" && true} onChange={e => setDeals(e.target.checked ? true: false)} className="form-check-input mt-0" type="checkbox" id="deals"/>
								<label className="form-check-label cursor-pointer" htmlFor="deals">Deals</label>
								
								<div data-disable={variationDeals} className={!deals ? 'clubbed_buying_disabled' : ''}>
									<div className="input_wrap w-100 ml-4">
										<input disabled ref={discount_percentage} defaultValue={productDefaultData?.deals.discount.percentage} type="number"/>
										<label>Discount Percentage %</label>
									</div>
								</div>

							</div>
					</div>

					<div data-disable={variationDeals} className={!deals ? 'clubbed_buying_disabled' : ''}>
						{/* DEALS - START TIME */}
						<div className="mt-4 mb-3 ml-0 mr-0 pt-3 pb-2 pl-3 pr-3 custom-legend">
							<div className="row">
								<div className="col-12 d-flex">
									<div className="font-size-a14 text-secondary custom-legend-heading">Deal Start</div>
								</div>
								<div className="col-md-6 mb-2">
									<div className="input_wrap w-100">
										<input disabled ref={start_date} defaultValue={productDefaultData?.deals.start_time.split('T')[0]} type="date" placeholder=" "/>
										<label>Date</label>
									</div>
								</div>
								<div className="col-md-6 mb-2">
									<div className="input_wrap w-100">
										<input disabled ref={start_time} defaultValue={productDefaultData?.deals.start_time.split('T')[1].replace(':00.000Z','')} type="time" placeholder=" "/>
										<label>Time</label>
									</div>
								</div>
							</div>
						</div>

						{/* DEALS - END TIME */}
						<div className="mt-4 mb-3 ml-0 mr-0 pt-3 pb-2 pl-3 pr-3 custom-legend">
							<div className="row">
								<div className="col-12 d-flex">
									<div className="font-size-a14 text-secondary custom-legend-heading">Deal End</div>
								</div>
								<div className="col-md-6 mb-2">
									<div className="input_wrap w-100">
										<input disabled ref={end_date} defaultValue={productDefaultData?.deals.end_time.split('T')[0]} type="date" placeholder=" "/>
										<label>Date</label>
									</div>
								</div>
								<div className="col-md-6 mb-2">
									<div className="input_wrap w-100">
										<input disabled ref={end_time} defaultValue={productDefaultData?.deals.end_time.split('T')[1].replace(':00.000Z','')} type="time" placeholder=" "/>
										<label>Time</label>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

			</div>
		</form>
	)
}




/* -------------------------------
					DELETE PRODUCT
----------------------------------*/
const DeleteProductComponent = ({id,title, componentHandle, dispatch, history}) => {

	const deleteProductHandle = async (id) =>{
		await toast.promise(
			dispatch(deleteProduct(id)),
			{
				pending: 'Product Deleting...',
				success: 'Successfully! Product Deleted',
				error: 'Product not Deleted'
			}
		);

		await componentHandle(null);
		history.push('/products')
	}

	return(
		<div className="modal show" style={{display:'block'}}>
			<div className="modal-dialog modal-dialog-centered">
				<div className="modal-content tx-size-sm">
					<div className="modal-body simple-modal overflow-unset tx-center pd-y-20 pd-x-20">
						<button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={() => componentHandle(null)}>
							<span aria-hidden="true">×</span>
						</button>
						<i className="icon icon bx bx-trash tx-74 text-warning lh-1 d-inline-block"></i>
						<h4 className="tx-warning mg-b-20 text-overflow-hidden font-size-18">Delete <span className="text-secondary">"{title}"</span></h4>
						<p className="mg-b-20 mg-x-20">Are you sure <b>Buddy</b>?</p>
						<button aria-label="Close" className="btn ripple btn-outline-light m-1 pd-x-25" data-dismiss="modal" type="button" onClick={() => componentHandle(null)}>Cancle</button>
						<button aria-label="Close" className="btn ripple m-1 btn-warning pd-x-25" data-dismiss="modal" type="button" onClick={() => deleteProductHandle(id)}>Delete</button>
					</div>
				</div>
			</div>
		</div>
	)
}




function mapStateToProps(state){
	const { categories }     = state.categories;
	const { societyList }    = state.clubbedBuying.clubbedBuying;
	const { attributesList } = state.attributes;
	const { message }        = state.message;
	const userId             = state?.auth?.currentUser?.id;
	let capability          = state?.auth?.currentUser?.capability
  capability              = capability ? JSON.parse(capability) : null

	return {
		categories,
		societyList,
		attributesList,
		message,
		userId,
		capability
	};
}


export default connect(mapStateToProps)(ViewProduct);