import React from 'react';
import { NavLink, useHistory } from "react-router-dom";
import { connect } from 'react-redux';

const Navigation = ({capability, currentUser}) => { 
  const history = useHistory();

  return (
    capability ? 
      <div className="horizontal-main hor-menu clearfix side-header">
        <div className="horizontal-mainwrapper container clearfix">
          {/*Nav*/}
          <nav className="horizontalMenu clearfix">
            <div className="horizontal-overlapbg" />
            <ul className="horizontalMenu-list">
              <li>
                <span className="horizontalMenu-click">
                  <i className="horizontalMenu-arrow fe fe-chevron-down" />
                </span>
                <NavLink to="/dashboard" exact>
                  <i className="bx bx-bar-chart-square menu-icon" /> Dashboard 
                </NavLink>                
              </li>

              {capability?.clubbed_order?.module_enable ?
              <li>
                <span className="horizontalMenu-click"><i className="horizontalMenu-arrow fe fe-chevron-down"></i></span>
                <NavLink to="/orders" className="sub-icon pointer-event-none">
                  <i className="bx bx-cart menu-icon"></i> Orders 
                  <i className="fe fe-chevron-down horizontal-icon"></i>
                </NavLink>
                <ul className="sub-menu">
                    {capability?.clubbed_order?.module_enable ? <li><NavLink to='/orders/clubbed-order' className="slide-item" exact>Clubbed Order</NavLink></li> : null}
                    {capability?.clubbed_order?.invoice_generator ? <li><NavLink to='/orders/invoice-generator' className="slide-item" exact>Invoice Generater</NavLink></li> :null}
                </ul>
              </li> : null}
               
              {capability?.product?.module_enable || capability?.attribute?.module_enable || capability?.category?.module_enable ?
              <li>
                <span className="horizontalMenu-click"><i className="horizontalMenu-arrow fe fe-chevron-down"></i></span>
                <NavLink to="/products" className="sub-icon pointer-event-none">
                  <i className="bx bx-store menu-icon"></i> Products 
                  <i className="fe fe-chevron-down horizontal-icon"></i>
                </NavLink>
                <ul className="sub-menu">
                    {capability?.product?.module_enable ? <li><NavLink to='/products' className="slide-item" exact>Products</NavLink></li> : null}
                    {capability?.attribute?.module_enable ? <li><NavLink to='/products/attributes' className="slide-item" exact>Attributes</NavLink></li> : null}
                    {capability?.category?.module_enable ? <li><NavLink to='/products/categories' className="slide-item" exact>Categories</NavLink></li> : null}
                    {capability?.product?.import || capability?.product?.import_view ? <li><NavLink to='/products/import' className="slide-item" exact>Import</NavLink></li> : null}
                    {/* <li><NavLink to='/products/upload-image' className="slide-item" exact>Upload Image</NavLink></li> */}
                </ul>
              </li> :null}
              
              {/* {capability?.dpl?.module_enable ?
              <li>
                <span className="horizontalMenu-click"><i className="horizontalMenu-arrow fe fe-chevron-down"></i></span>
                <NavLink to="/edge" className="sub-icon pointer-event-none">
                  <i className="bx bx-cog menu-icon"></i> EDGE 
                  <i className="fe fe-chevron-down horizontal-icon"></i>
                </NavLink>
                <ul className="sub-menu">
                {capability?.dpl?.module_enable ? <li><NavLink to='/edge/dpl' className="slide-item" exact>Daily Price Logger</NavLink></li>  :null}
                </ul>
              </li> :null} */}

              {/* <li>
                  <span className="horizontalMenu-click"><i className="horizontalMenu-arrow fe fe-chevron-down"></i></span>
                  <NavLink to="/users" className="sub-icon">
                    <i className="bx bx-group menu-icon"></i> Users 
                    <i className="fe fe-chevron-down horizontal-icon"></i>
                  </NavLink>
                  <ul className="sub-menu">
                    <li><NavLink to="/users">Users</NavLink></li>
                    <li><NavLink to="/users/sellers" >Seller</NavLink></li>
                  </ul>
              </li> */}

              {capability?.user?.module_enable || capability?.capability?.module_enable ?
              <li>
                  <span className="horizontalMenu-click"><i className="horizontalMenu-arrow fe fe-chevron-down"></i></span>
                  <NavLink to="/users" className="sub-icon pointer-event-none">
                    <i className="bx bx-group menu-icon menu-icon"></i> Users 
                    <i className="fe fe-chevron-down horizontal-icon"></i>
                  </NavLink>
                  <ul className="sub-menu">
                      {capability?.user?.module_enable ? <li><NavLink to="/users" className="slide-item" exact>All Users</NavLink></li> :null}
                      {capability?.capability?.module_enable ? <li><NavLink to="/users/roles-capabilities" className="slide-item" exact>Roles &amp; Capabilities</NavLink></li> :null}
                  </ul>
              </li> :null}

              <li>
                <span className="horizontalMenu-click"><i className="horizontalMenu-arrow fe fe-chevron-down"></i></span>
                <NavLink to="/marketing-tool" className="sub-icon pointer-event-none">
                  <i className="bx bx-message-square-detail menu-icon"></i> Marketing Tools 
                  <i className="fe fe-chevron-down horizontal-icon"></i>
                </NavLink>
                <ul className="sub-menu">
                  <li><NavLink to="/marketing-tool/whatsapp">Whatsapp</NavLink></li>
                </ul>
              </li>
              
              {capability?.email_template?.module_enable || capability?.sms_template?.module_enable || capability?.firebase_notification_template?.module_enable ?
              <li>
                  <span className="horizontalMenu-click"><i className="horizontalMenu-arrow fe fe-chevron-down"></i></span>
                  <NavLink to="/message-template" className="sub-icon pointer-event-none">
                    <i className="bx bx-message-square-detail menu-icon"></i> Templates 
                    <i className="fe fe-chevron-down horizontal-icon"></i>
                  </NavLink>
                  <ul className="sub-menu">
                    {capability?.email_template?.module_enable ? <li><NavLink to="/message-template/email/list">Email</NavLink></li> :null}
                    {capability?.sms_template?.module_enable ? <li><NavLink to="/message-template/sms/list">SMS</NavLink></li> :null}
                    {capability?.firebase_notification_template?.module_enable ? <li><NavLink to="/message-template/firebase-notification/list">Firebase Notification</NavLink></li> :null}
                    <li><NavLink to="/message-template/send-notification">Send Notification</NavLink></li>
                  </ul>
              </li> :null}

              
              <li>
                  <span className="horizontalMenu-click"><i className="horizontalMenu-arrow fe fe-chevron-down"></i></span>
                  <NavLink to="/setting" className="sub-icon pointer-event-none">
                    <i className="bx bx-cog menu-icon"></i> Setting 
                    <i className="fe fe-chevron-down horizontal-icon"></i>
                  </NavLink>
                  <ul className="sub-menu">
                    <li className='title-left' data-title='🙂 Under Construction'><NavLink className='pointer-event-none' to="/setting/payment">Payment</NavLink></li>
                    <li className="sub-menu-sub">
                        <span className="horizontalMenu-click02"><i className="horizontalMenu-arrow fe fe-chevron-down"></i></span>
                        <NavLink to="/setting/apps-setting" className='pointer-event-none'>Apps Setting</NavLink>
                        <ul className="sub-menu">
                            <li  className='title-left' data-title='🙂 Under Construction'><NavLink to="/setting/clubbed-buying/society" className="pointer-event-none slide-item" exact>General Setting</NavLink></li>
                            {capability?.banner?.module_enable ? <li><NavLink to="/clubbed-buying/banner" className="slide-item" exact>Banner</NavLink></li> :null}
                        </ul>
                    </li>
                    <li className="sub-menu-sub">
                        <span className="horizontalMenu-click02"><i className="horizontalMenu-arrow fe fe-chevron-down"></i></span>
                        <NavLink to="/setting/clubbed-buying" className='pointer-event-none'  >Clubbed  Buying</NavLink>
                        <ul className="sub-menu">
                            {capability?.society?.module_enable ? <li><NavLink to="/clubbed-buying/society" className="slide-item" exact>Society &amp; Area</NavLink></li> :null}
                            <li className='title-left' data-title='🙂 Under Construction'><NavLink to="/clubbed-buying/weekly-buy-setting" className="slide-item pointer-event-none" exact>Weekly Buy Setting</NavLink></li>
                        </ul>
                    </li>
                  </ul>
              </li>

            </ul>
          </nav>
          {/*Nav*/}
        </div>
      </div>
    :  
    <>
      {currentUser &&
        <div>
          <div className='card p-5 center text-center container shadow-sm mt-5'>
            <div className='text-secondary'>Sorry! Buddy 🙂 you have no access</div>
          </div>
        </div>
      }
    </>
  )
};

function mapStateToProps(state) {
  const { currentUser } = state.auth
  let capability   = state.auth.currentUser?.capability
  capability       = capability ? JSON.parse(capability) : null
  return {
    capability,
    currentUser
  }
}

export default connect(mapStateToProps)(Navigation);