import { useState, useEffect } from "react"
import { connect } from "react-redux"
import exportFromJSON from 'export-from-json'
import { exportClubbedBuyOrderList } from '../../../actions/orders.action'
import { categoryList } from "../../../actions/category.action"
import { EXPORT_CLUBBED_BUYING_ORDER_LIST } from "../../../actions/types"
import moment from 'moment'


const ExportOrder = ({handlePopup, dispatch, categories, exportOrderList}) => {
	const [loader, setLoader]           = useState(false)
  const [page, setPage]               = useState(1)
	const [limit, setLimit]             = useState(25)
	const [sortBy, setSortBy]           = useState('desc')
  const [exportData, setExportData]   = useState([])
  const [isExported, setIsExported]   = useState(false)
  const [totalPageShow, setTotalPageShow] = useState(0)

  function loadOrderList(nextPage){
    setLoader(true); 
    dispatch(exportClubbedBuyOrderList(nextPage || page,limit,sortBy))
  }

  useEffect(() => dispatch(categoryList()), [])
  useEffect(async () => {
    if(!exportOrderList) return
    if(!categories) alert('Sorry! Category still not loaded')

    var formateList = []
    var totalPage   = Math.ceil(exportOrderList?.total / limit)
    setTotalPageShow(totalPage)
    
    exportOrderList?.orders?.map((pData, index) => (
      pData.items.map((iData,i) => {
        //CATEGORY
        var categoryList    = []
        var subCategoryList = []
        var leafList        = []
        iData?.product?.categories?.filter(cData => {
          categories.filter(category => {
            if(category.id == cData.category_id){
              switch(category.type){
                case 'category'    : return categoryList.push(category.name)
                case 'subcategory' : return subCategoryList.push(category.name)
                case 'leaf'        : return leafList.push(category.name)
                default            : return null
              }
            }
          })
        })

        formateList.push({
          'S. No.'                  : exportData.length + formateList.length + 1,
          'Order No.'               : pData.order_number,
          'Order Date'              : moment(pData.createdAt).utcOffset('+05:30').format('MM/DD/YYYY'),
          'Invoice No.'             : pData.invoice ? pData?.invoice[0]?.number || 'n/a' : 'n/a',
          'Invoice Date'            : pData.invoice ? moment(pData?.invoice[0]?.createdAt).utcOffset('+05:30').format('MM/DD/YYYY') || 'n/a' : 'n/a',
          'Customer ID'             : pData.user.user_number,
          'Customer Name'           : pData.user.first_name+ ' ' + pData.user.last_name,
          'SKU ID'                  : iData.variation?.sku_id,
          'Product'                 : iData.variation?.name,
          '#Bazaar'                 : categoryList.join(',') || 'n/a',
          '#Sub Category'           : subCategoryList.join(',') || 'n/a',
          '#Leaf'                   : leafList.join(',') || 'n/a',
          'Listing Price'           : iData.variation?.price.listing_price,
          'Purchase Price'          : iData?.prices.net_amount,
          'MRP'                     : iData?.prices.mrp,
          'Quantity'                : iData?.quantity,
          'GST %'                   : iData?.prices.tax_rate,
          'Total GST Amount'        : iData?.prices.tax_amount,
          'Total Product Amount'    : iData?.prices.total,
          'Total Invoice Amount'    : pData.prices.total_amount,
          'MRP	Customer Saved'     : iData?.prices.saving_amount,
          'Customer Saved (In %)'   : iData?.prices.saving_percentage,
          'Cost Price'              : iData?.prices.cost_price,
          'Total Cost Price'        : parseFloat(iData?.prices.cost_price) * parseFloat(iData?.quantity),
          'Profit'                  : iData?.prices.profit_amount,
          'Payment Received'        : pData.payment_status,
          'Payment Mode'            : pData.payment_mode,
          'Payment Date'            : 'n/a',
          'Product Link'            : `https://admin.hashtagbazaar.com/products/view/${iData?.product?._id}`
        })
      })
    ))
    setExportData(data => data.concat(formateList))
    if(page + 1 <= totalPage){
      setPage(page => page + 1)
      await loadOrderList(page + 1)
    }else{
      setLoader(false);
      setIsExported(true)
    }
  },[exportOrderList])


  function exportToCSV(downloadType){
    const fileName   = `Product List - ${moment(Date.now()).utcOffset('+05:30').format('lll')}`
    const exportType = exportFromJSON.types[downloadType]
    let data         = exportData
    exportFromJSON({ data, fileName, exportType })
  }


  async function refreshComponent(close){
    loadOrderList = null
    setIsExported(false)
    setPage(1)
    setLoader(false)
    setExportData([])
    await dispatch({
      type: EXPORT_CLUBBED_BUYING_ORDER_LIST,
      payload: null
    })
    loader && !isExported && window.location.reload()
    close && await handlePopup(false)
  }


  return (
    <>
    <div className="modal effect-flip-vertical show" id="modaldemo8" aria-modal="true" style={{display:"block"}}>
      <div className="overlayer" onClick={() => {refreshComponent(true)}}></div>
      <div className="modal-dialog modal-dialog-right" id="hideMe" role="document">

        <div className="modal-content modal-content-demo">
          <div className="modal-header px-3 py-2 shadow-base d-flex align-items-center position-relative z-index-100">
            <h6 className="modal-title py-1"><i className='bx bxs-file-export mr-1'></i> Export Orders</h6>
          </div>
          <div className="modal-body d-flex pt-4 pb-4">
            <div className="w-100 d-flex flex-column justify-center">

              {!loader && !isExported &&
              <>
                <div className="d-flex justify-content-between align-items-center">
                  <div>Sort By:</div> 
                  <select className="form-control ml-auto w-auto" onChange={e => setSortBy(e.target.value)}>
                    <option value='desc'>New Order First</option>
                    <option value='asc'>Old Order First</option>
                  </select>
                </div>
                <div className="card border mt-3 center px-3 py-5">
                  <button onClick={() => loadOrderList()} className={`btn btn-warning`}> 
                    {loader ? <span className="spinner-border spinner-border-sm mr-1"></span> : <i className='bx bxs-download mr-1'></i>}
                    Export Orders
                  </button>
                  <p className="mt-2 text-secondary">🙂 Export Orders</p>
                </div>
              </>}

              {loader && !isExported &&
                <div className="card border mt-3 px-3 py-5 animated-1s fadeIn">
                  <div className="text-center mb-1"><b>{exportData.length} Orders Exported</b></div>
                  <div className="progress rounded-50">
                    <div className="progress-bar progress-bar-striped progress-bar-animated" style={{width: `${(page/ totalPageShow) * 100}%`, height:15}}></div>
                  </div>
                </div>
              } 

              {isExported &&
                <div className="card border mt-3 center px-3 py-5 animated-1s fadeIn">
                  <div className="btn-group">
                    <button onClick={() => exportToCSV('csv')} className="btn btn-outline-primary"> 
                      <i className='bx bxs-download mr-1'></i>
                      Download CSV
                    </button>
                    
                    <button onClick={() => exportToCSV('xls')} className="btn btn-primary"> 
                      <i className='bx bxs-download mr-1'></i>
                      Download XLS
                    </button>
                  </div>
                  <p className="mt-2 text-center">🙂 Now, you can download</p>
                </div>
              } 

            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  )
}


function mapStateToProps(state){
  let { exportOrderList } = state.orders
  const { categories }    = state.categories
  return {
    exportOrderList,
    categories
  }
}

export default connect(mapStateToProps)(ExportOrder)