import React, { useEffect, useState } from 'react'
import { connect } from "react-redux"
import { useHistory } from "react-router-dom"
import MainLayout from '../../layouts/main.component'
import { getSmsByName, updateSmsTemplate } from '../../../actions/message-templates.action'
import { toast } from 'react-toastify'

const EditSms = ({ dispatch, message, messageTemplate, ...props }) => {

  const history                   = useHistory()
  const [btnLoader, setBtnLoader] = useState(false)

  useEffect(() => {
    document.title  = `Edit SMS | ${messageTemplate?.smsByName?.name}`
  },[messageTemplate])

  useEffect(() => {
    dispatch(getSmsByName(props.match.params.name))
  },[dispatch])


  const onSubmit = async (id, e) => { 
    e.preventDefault()
    const formData = new FormData(e.target)
    const data = {}
    for (let entry of formData.entries()) {
        data[entry[0]] = entry[1]
    } 

    await toast.promise(dispatch(updateSmsTemplate(id, data)),{
      pending: 'Adding Template...',
      success: 'Successfully! Add Template',
      error: {
        render(){
          setBtnLoader(false)
          return 'Template not added'
        }
      }
    })  
    setTimeout(async () => history.push('/message-template/sms/view/'+encodeURI(data.name)), 1000)
  }

  
  return (
    <MainLayout>
      {/* BREADCRUMB */}             
      <nav className="breadcrumb mt-3 mb-0"> 
        <div className="cursor-pointer mr-4 text-primary" onClick={history.goBack}><i className='bx bxs-chevron-left'></i> Back</div>
        <div className="mr-auto page_title">Edit SMS Template</div>
        <div className="breadcrumb-item" onClick={()=> history.push('/')}>Dashboard</div>
        <div className="breadcrumb-item" onClick={()=> history.push('/message-template/email/list')}>SMS Templates</div>
        <div className="breadcrumb-item active">Edit</div>
      </nav>


      {/* HEADER */}
      <div className="d-flex justify-content-between align-items-center">
        <div className="h4 mt-4 mb-3 d-flex align-items-center">Edit SMS Template</div>
        <button disabled={btnLoader} className="btn btn-primary" type="submit" onClick={() => document.querySelector('.add_sms_btn').click()}>
          {!btnLoader ? 'Update Template' : <span className="spinner-border spinner-border-sm mx-5"></span>}
        </button>
      </div>

      {messageTemplate.smsByName ?
        <div className='card card-body animated fadeIn d-flex flex-unset'>
          <form onSubmit={ (e) => {setBtnLoader(true); onSubmit(messageTemplate.smsByName.id, e)}}>  

            <button className='d-none add_sms_btn' type='sumbit'>Submit Form</button> 

            <div className="row">
              <div className="col-md-4">
                <div className="input_wrap w-100" data-title='Sorry! 🙂 I am not editable'>
                  <input name='name' readOnly defaultValue={messageTemplate.smsByName.name} type="text" className='disabled'  placeholder=" "/>
                  <label>Name</label>
                </div>
              </div>

              <div className="col-md-4">
                <div className="input_wrap w-100">
                  <input name="peid" defaultValue={messageTemplate?.smsByName?.peid} type="text"  placeholder=" "/>
                  <label>Peid</label>
                </div>
              </div>

              <div className="col-md-4">
                <div className="input_wrap w-100">
                  <input name="dlttemplateid" defaultValue={messageTemplate?.smsByName?.dlttemplateid} type="text"  placeholder=" "/>
                  <label>DLT Template ID</label>
                </div>
              </div>

              <div className="col-md-12 mt-3">
                <div className="form-group mb-0"> 
                  <label className="main-content-label tx-11 m-0">Content</label> 
                </div>
                <textarea className="form-control m-0" name="content" rows="9">{messageTemplate.smsByName.content}</textarea>
              </div>

            </div>
          </form> 
        </div>
      :
        <div className="d-flex justify-content-center flex-column mt-5 mb-3 align-items-center">
          <div>Wait <b>Buddy</b>...</div>
          <div className="spinner-grow" role="status"/>
        </div>
      }
    </MainLayout>
  )
}

function mapStateToProps(state) {
  const { message }             = state.message;
  const { messageTemplate }     = state.messageTemplate;

  return {
    message,
    messageTemplate
  };
}

export default connect(mapStateToProps)(EditSms);