import React,{useEffect} from 'react'
import { connect } from "react-redux"
import Header from './header.component'
import Navigation from './navigation.component'
import Footer from './footer.component'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'	
import {clearMessage} from '../../actions/message.action'

const MainLayout = (props) => {  
  const {message, dispatch} = props;

  // TOAST SUCCESS
  const successToast = (data,index) => toast.success(data, {
    position: "top-right",
    toastId:index,
    autoClose: 4000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });

  // TOAST ERROR
  const errorToast = (data,index) => toast.error(data, {
    position: "top-right",
    toastId:index,
    autoClose: 4000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });

  useEffect(() => {
    setTimeout(()=>{
      dispatch(clearMessage());
    },100);
  }, [message]);

  return(
    <>
      <Header currentUser={props.currentUser}/>
      <Navigation />

        {/* MESSAGES */}
        <ToastContainer/>
        {message?.errors?.map((err,index) => {errorToast(err.message,index)})}
        {message?.success?.map((success,index) => {successToast(success.message,index)})}

        <div className="container main-body">
          {props.children}
        </div>
        <div className='scrollTopBottom'>
        <i className='bx bx-up-arrow-alt' onClick={() => window.scrollTo(0, 0)}></i>
        <i className='bx bx-down-arrow-alt' onClick={() => window.scrollTo(0,document.body.scrollHeight)}></i>
        </div>
      <Footer />
    </>
  )
}

function mapStateToProps(state) {
  const { currentUser } = state.auth;
  const { message }      = state.message;
  return {
    currentUser,
    message
  };
}

export default connect(mapStateToProps)(MainLayout);
 
