import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import MainLayout from '../../layouts/main.component';
import { addFirebaseNotificationTemplate } from '../../../actions/message-templates.action';
import axios from 'axios';
import { SET_MESSAGE } from '../../../actions/types';

//IMAGE - "FilePond"
import { FilePond, File, registerPlugin } from 'react-filepond'
import "filepond/dist/filepond.min.css"
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation"
import FilePondPluginImagePreview from "filepond-plugin-image-preview"
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css"
import { toast } from 'react-toastify';
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview, FilePondPluginFileValidateType, FilePondPluginFileValidateSize)


const AddNotification = ({dispatch}) => {

  const history                   = useHistory()
  const [image, setImage]         = useState()
  const [btnLoader, setBtnLoader] = useState(false)
  document.title                  = 'Add Firebase Notification'

  useEffect(() => {
    setInterval(() => document.querySelectorAll('.no-tab-index *').forEach(e => e.tabIndex = -1), 2000)
  },[])

  const onSubmit = async (e) => { 
    e.preventDefault()
    
    if(!image){
      setBtnLoader(false)
      return toast.error('Buddy! 🙂 Upload image')
    }

    toast.loading('Submitting data...', {toastId:'loading'})


    //UPLOAD IMAGE 
    var imgInfo = new FormData();
    var imgLink;
    imgInfo.append('file', image[0]?.file);
    imgInfo.append('foldername', 'firebase');
    
    await axios.post("/file/upload", imgInfo)      
    .then((response) => {
      imgLink = response.data
    }).catch(err => {
      setBtnLoader(false)
      dispatch({ type:SET_MESSAGE, payload:err.response.data })
    })
    toast.dismiss('loading')

    //SUBMIT DATA
    if(imgLink){
      const formData = new FormData(e.target)
      formData.append('image', imgLink)
      const data = {}
      e.preventDefault()
      for (let entry of formData.entries()) {
          data[entry[0]] = entry[1]
      } 

      var isValide;
      Object.values(data).map((formField,index) => {
        if(!formField){ setBtnLoader(false); toast.error(<><b>{Object.keys(data)[index]}</b> is mandatory</>); isValide = false }
      })
      if(isValide == false) return

      await toast.promise(dispatch(addFirebaseNotificationTemplate(data)),{
        pending: 'Adding Firebase Notification...',
        success: 'Successfully! Add Firebase Notification',
        error: {
          render(){
            setBtnLoader(false)
            return 'Firebase Notification not added'
          }
        }
      })  
      setTimeout(async () => history.push('/message-template/firebase-notification/view/'+encodeURI(data.name)), 1000)
    }else{
      setBtnLoader(false)
      return toast.error('Something wrong with image upload')
    }
  }


  return (
    <MainLayout>
      {/* BREADCRUMB */}             
      <nav className="breadcrumb mt-3 mb-0"> 
        <div className="cursor-pointer mr-4 text-primary" onClick={history.goBack}><i className='bx bxs-chevron-left'></i> Back</div>
        <div className="mr-auto page_title">Add Firebase Notification</div>
        <div className="breadcrumb-item" onClick={()=> history.push('/')}>Dashboard</div>
        <div className="breadcrumb-item" onClick={()=> history.push('/message-template/firebase-notification/list')}>Firebase Notification</div>
        <div className="breadcrumb-item active">Add</div>
      </nav>


      {/* HEADER */}
      <div className="d-flex justify-content-between align-items-center">
        <div className="h4 mt-4 mb-3 d-flex align-items-center">Add Firebase Notification</div>
        <button disabled={btnLoader} className="btn btn-primary" type="submit" tabIndex={4} onClick={() => document.querySelector('.add_notification').click()}>
          {!btnLoader ? '+ Add Notification' : <span className="spinner-border spinner-border-sm mx-5"></span>}
        </button>
      </div>

      <div className='card card-body animated fadeIn flex-unset'>
        <form onSubmit={ (e) => {setBtnLoader(true); onSubmit(e)}}>  

          <button className='d-none add_notification' type='sumbit'>Submit Form</button> 

          <div className="row">
            <div className="col-md-3">
              <div className="input_wrap w-100">
                <input autoFocus name='name' type="text"  placeholder=" " tabIndex={1}/>
                <label>Name</label>
              </div>
            </div>

            <div className="col-md-9">
              <div className="input_wrap w-100">
                <input name="subject" type="text"  placeholder=" " tabIndex={2}/>
                <label>Subject</label>
              </div>
            </div>

            <div className='col-md-12 mt-3 no-tab-index'>
              <FilePond
                files={image}
                onupdatefiles={(e) => setImage(e)}
                maxFiles={1}
                storeAsFile={true}
                maxFileSize={'1MB'}
                acceptedFileTypes={['image/png', 'image/jpeg', 'image/jpg']}
                name="test111" 
                labelIdle='Drag & Drop Image or <span class="filepond--label-action">Browse</span>'
              />
            </div>

            <div className="col-md-12 mt-3">
              <div className="form-group mb-0"> 
                <label className="main-content-label tx-11 mb-0">Content</label> 
                <textarea className="form-control" name="content" rows="9" tabIndex={3}></textarea>
              </div>
            </div>

          </div>
        </form> 
      </div>
    </MainLayout>
  )
}

function mapStateToProps(state) {
  return
}

export default connect(mapStateToProps)(AddNotification);