import React,{useState} from "react";
import { updateAttribute } from "../../../actions/attributes.action";

const AttributeEdit = ({attribute,formHandle,attributes, dispatch}) =>{
  const [btnLoader, setBtnLoader]  = useState(false)

  // UPDATE ATTRIBUTE
  const onSubmitEditAttribute = async (e) => { 
    const formData = new FormData(e.target)
    const data = {}
    e.preventDefault()
    for (let entry of formData.entries()) {
        data[entry[0]] = entry[1]
    } 
    try{
      await dispatch(updateAttribute(attribute.id,data))
      setBtnLoader(false)
      await formHandle(false)
    }catch(e){
      setBtnLoader(false)
    }
  }

  return(
    <>
    {attribute &&
      <div className="modal effect-flip-vertical show" id="modaldemo8" aria-modal="true" style={{display:"block"}}>
        <div className="modal-dialog modal-dialog-right" role="document">
          <form onSubmit={ (e) => {setBtnLoader(true); onSubmitEditAttribute(e)}} className="modal-content modal-content-demo">
            <div className="modal-header">
              <h6 className="modal-title">Edit Attribute</h6>
              <button aria-label="Close" onClick={() => formHandle(false)} className="close" data-dismiss="modal" type="button"><span aria-hidden="true">×</span></button>
            </div>
            <div className="modal-body">
              {/* ATTRIBUTE NAME */}
              <div className="input_wrap w-100 ">
                <input name="name" type="text" defaultValue={attribute.name} placeholder=" "/>
                <label>Name</label>
              </div>

              {/* PARENT ATTRIBUTE */}
              <div className="form-group mt-3"> 
                <label className="main-content-label tx-11">Parent Attribute</label> 
                <select name="parent" className="form-control">
                  <option value="">None</option>
                  { (attributes) ? attributes.filter(res => res.parent==='').map( (catData) => (
                      <Attribute catData={catData} parentAttribute={attribute.parent} attributes={attributes} />
                  )
                  ) : <option value="">loading...</option> } 
                </select>
              </div> 
            </div>
            <div className="modal-footer">
              <div className="btn ripple btn-outline-primary" data-dismiss="modal" type="button" onClick={() => formHandle(false)}>Close</div>
              <button type="submit" disabled={btnLoader} className="btn ripple btn-primary">
                {!btnLoader ? 'Save changes' : <span className="spinner-border spinner-border-sm mx-4"></span>}
              </button>
            </div>
          </form>
        </div>
      </div>
    }
    </>
  )
}

// ATTRIBUTE
const Attribute = (tableData) => {
  const { catData, attributes, parentAttribute } = tableData;
  return (
    <> 
    {catData.parent==='0' ? '' : 
    <> 
    <option selected={parentAttribute == catData.id ? true: false} value={catData.id}>{ catData.name }</option>      
    </>
      }
      <SubAttribute subAttributeParent={parentAttribute} attributes={attributes} id={catData.id} ar={1}/> 
    </>
  )
}

// SUB-ATTRIBUTE
const SubAttribute = (tableSubData) => {
  const { attributes, id , ar, subAttributeParent  } = tableSubData;
  var arrowRows = [];
  for (var i = 1; i <= ar; i++) {
      arrowRows.push('- ');
  }
  return (
    <>
    {attributes.filter(res=> res.parent===id).map( (catData2, index) => (
        <React.Fragment>
          <option selected={subAttributeParent == catData2.id ? true: false} value={catData2.id}>{arrowRows} { catData2.name }</option>     
          <SubAttribute subAttributeParent={subAttributeParent} attributes={attributes} id={catData2.id} ar={ar+1}/> 
        </React.Fragment>
      ))
    }
    </>
  )
}

export default AttributeEdit