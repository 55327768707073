import { PRODUCT_LIST,PRODUCT_BY_ID, CLEAR_PRODUCT, PRODUCT_SEARCH, PRODUCT_ADD, GET_PRODUCT_LOG, EXPORT_PRODUCT } from "../actions/types";

const initialState ={
    products:{
        productList:null,
        productDetail:null,
        productSearch:null,
        productAdd:null,
        productLog:null,
        exportProductList:null,
    }
}

export default function foo(state = initialState,action){
    const {type,payload} = action;

    switch(type){
        case GET_PRODUCT_LOG:
            return {
                products:{
                    ...state.products,
                    productLog: payload
                }
            }
        case EXPORT_PRODUCT: 
            return{
                products:{
                    ...state.products,
                    exportProductList: payload
                }
            }
        case PRODUCT_LIST:
            return{
                products:{
                    ... state.products,
                    productList:payload
                }
            }
        case PRODUCT_ADD:
            return{
                products:{
                    ... state.products,
                    productAdd:payload
                }
            }
        case PRODUCT_BY_ID:
            return{
                products:{
                    ... state.products,
                    productDetail:payload
                }
            }
        case CLEAR_PRODUCT:
            return{
                products:{
                    ...state.products,
                    productDetail:null
                }
            }
        case PRODUCT_SEARCH:
            return{
                products:{
                    ...state.products,
                    productList:{
                        ...state.products.productList,
                        products: payload
                    },
                    productSearch:payload
                }
            }
        default:
            return state;
    }
}