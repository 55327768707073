import { ATTRIBUTES_LIST, ATTRIBUTE_BY_ID } from "../actions/types";

const initialState = {
  attributesList: null,
  attributeById:null
}

export default function foo(state =  initialState, action){
  const {type, payload} = action;
  
  switch(type){
    case ATTRIBUTES_LIST:
      return {
      ... state,
      attributesList: payload
    }

    case ATTRIBUTE_BY_ID:
      return {
      ... state,
      attributeById: payload
    }

    default:
      return state;
  }
}