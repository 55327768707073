import { 
  // CATEGORY
  CLUBBED_BUYING_CATEGORY_LIST, 
  CLUBBED_BUYING_CATEGORY_ADD,
  CLUBBED_BUYING_CATEGORY_BY_ID, 
  //ORDERS
  CLUBBED_BUYING_ORDERS, 

  //MESSAGE
  SET_MESSAGE, 

  //SOCIETY
  CLUBBED_BUYING_SOCIETY_LIST, 
  CLUBBED_BUYING_SOCIETY_ADD, 
  CLUBBED_BUYING_SOCIETY_BY_ID,
  
  // BANNER
  CLUBBED_BUYING_BANNER_LIST,
  CLUBBED_BUYING_BANNER_ADD,
  CLUBBED_BUYING_BANNER_EDIT,
  CLUBBED_BUYING_BANNER_BY_ID
  
} from "./types"
import clubbedBuyingService from "../services/clubbed-buying.service"
import { handleErrors } from "../helpers/common-function.helper"



/* -------------------------------
					 CATEGORY
----------------------------------*/
export const clubbedBuyingCategoryList = () => async (dispatch) => { //CATEGORY LIST
  return clubbedBuyingService.clubbedBuyingCategoryList().then(
    (data)  => { dispatch({ type: CLUBBED_BUYING_CATEGORY_LIST, payload: data, }); },
    (error) => handleErrors(error, dispatch)
  );
};

export const clubbedBuyingAddCategory = (data) => async (dispatch) => { //CATEGORY ADD
  return clubbedBuyingService.clubbedBuyingAddCategory(data).then(
    (data) => { dispatch({ type: CLUBBED_BUYING_CATEGORY_ADD, payload: data, }); dispatch(clubbedBuyingCategoryList())},
    (error) => handleErrors(error, dispatch)
  );
};

export const clubbedBuyingEditCategory = (data) => async (dispatch) => { //CATEGORY EDIT
  return clubbedBuyingService.clubbedBuyingEditCategory(data).then(
    (data) => { dispatch(clubbedBuyingCategoryList()) },
    (error) => handleErrors(error, dispatch)
  )
}

export const clubbedBuyingCategoryById = (catId) => async (dispatch) => { //CATEGORY BY ID
  return clubbedBuyingService.clubbedBuyingCategoryById(catId).then(
    (data) => { dispatch({ type:CLUBBED_BUYING_CATEGORY_BY_ID, payload:data }); dispatch(clubbedBuyingCategoryList())},
    (error) => handleErrors(error, dispatch)
  )
}

export const clubbedBuyingDeleteCategory = (catId) => async (dispatch) => { //CATEGORY DELETE
  return clubbedBuyingService.clubbedBuyingDeleteCategory(catId).then(
    (data) => { dispatch(clubbedBuyingCategoryList()) },
    (error) => handleErrors(error, dispatch)
  );
}


/* -------------------------------
					  ORDERS
----------------------------------*/
export const clubbedBuyingOrders = () => async (dispatch) => { //ORDER LIST
  return clubbedBuyingService.clubbedBuyingOrders().then(
    (data) => { dispatch({ type:CLUBBED_BUYING_ORDERS, payload:data }) },
    (error) => handleErrors(error, dispatch)
  );
}


/* -------------------------------
					  SOCIETY
----------------------------------*/
export const clubbedBuyingSocietyList = () => async (dispatch) => { //SOCIETY LIST
  return clubbedBuyingService.clubbedBuyingSocietyList().then(
    (data) => { dispatch({ type: CLUBBED_BUYING_SOCIETY_LIST, payload: data, }) },
    (error) => handleErrors(error, dispatch)
  );
};

export const clubbedBuyingAddSociety = (data) => async (dispatch) => { //SOCIETY ADD
  return clubbedBuyingService.clubbedBuyingAddSociety(data).then(
    (data) => { dispatch({ type: CLUBBED_BUYING_SOCIETY_ADD, payload: data, }); dispatch(clubbedBuyingSocietyList()) },
    (error) => handleErrors(error, dispatch)
  );
};

export const clubbedBuyingEditSociety = (data,id) => async (dispatch) => { //SOCIETY EDIT
  return clubbedBuyingService.clubbedBuyingEditSociety(data,id).then(
    (data) => { dispatch(clubbedBuyingSocietyList()) },
    (error) => handleErrors(error, dispatch)
  )
}

export const clubbedBuyingSocietyById = (id) => async (dispatch) => { //SOCIETY BY ID
  return clubbedBuyingService.clubbedBuyingSocietyById(id).then(
    (data) => { dispatch({ type:CLUBBED_BUYING_SOCIETY_BY_ID, payload:data }) },
    (error) => handleErrors(error, dispatch)
  )
}

export const clubbedBuyingDeleteSociety = (catId) => async (dispatch) => { //SOCIETY DELETE
  return clubbedBuyingService.clubbedBuyingDeleteSociety(catId).then(
    (data) => { dispatch(clubbedBuyingSocietyList())},
    (error) => handleErrors(error, dispatch)
  );
}


/* -------------------------------
					  BANNER
----------------------------------*/
export const clubbedBuyingBannerList = () => async (dispatch) => { //BANNER LIST  
  return clubbedBuyingService.clubbedBuyingBannerList().then(
    (data)  => { dispatch({ type: CLUBBED_BUYING_BANNER_LIST, payload: data, }) },
    (error) => handleErrors(error, dispatch)
  );
};

export const clubbedBuyingBannerById = (id) => async (dispatch) => { //BANNER BY ID
  return clubbedBuyingService.clubbedBuyingBannerById(id).then(
    (data)  => { dispatch({ type:CLUBBED_BUYING_BANNER_BY_ID, payload:data }) },
    (error) => handleErrors(error, dispatch)
  )
}

export const clubbedBuyingBannerAdd = (data) => async (dispatch) => { //BANNER ADD
  return clubbedBuyingService.clubbedBuyingBannerAdd(data).then(
    (data)  => { dispatch({ type: CLUBBED_BUYING_BANNER_ADD, payload: data, }); dispatch(clubbedBuyingBannerList()) },
    (error) => handleErrors(error, dispatch)
  );
};

export const clubbedBuyingBannerEdit = (data,id) => async (dispatch) => { //BANNER EDIT
  return clubbedBuyingService.clubbedBuyingBannerEdit(data,id).then(
    (data) => { dispatch({ type:CLUBBED_BUYING_BANNER_EDIT, payload:data }); dispatch(clubbedBuyingBannerList()) },
    (error) => handleErrors(error, dispatch)
  )
}

export const clubbedBuyingBannerDelete = (catId) => async (dispatch) => { //BANNER DELETE
  return clubbedBuyingService.clubbedBuyingBannerDelete(catId).then(
    (data) => { dispatch(clubbedBuyingBannerList()) },
    (error) => handleErrors(error, dispatch)
  );
}
