import React, {useState, useEffect} from 'react'
import axios from "axios"
import { SET_MESSAGE } from '../../actions/types'
import {category_type} from '../../helpers/category.helper'
import Select from 'react-select'


const CategoryAdd = ({ categories, dispatch, imageUrl, setParentCat, setImageUrl, btnLoader }) => {
  const [option, setOption]       = useState()


  //FORMATE DROPDOWN OPTIONS
  useEffect(() => {
    if(!categories) return
    var formateOptions = [];
    categories.map((parentCategory) => {
      if(parentCategory.parent == ''){ //PARENT CATEGORY
        formateOptions.push({label:`${parentCategory.name}`, value: parentCategory.id})
        
        categories.map((subCategory,i) => { //SUB-CATEGORY
          if(subCategory.parent == parentCategory.id){
            formateOptions.push({label:` -- ${subCategory.name}`, value: subCategory.id})

            categories.map((catData) => { //LEAF
              if(catData.parent == subCategory.id){
                formateOptions.push({label:` ----- ${catData.name}`, value: catData.id})

                categories.map((other) => { //OTHER
                  if(other.parent == catData.id){
                    formateOptions.push({label:` --------- ${other.name}`, value: other.id})
                  }
                })
              }
            })
          }
        })
      }
    })
    setOption(formateOptions)
  }, [categories])
  


  /* UPLOAD IMAGE
   ============================= */
  const uploadF = (selectorFiles) => {
    var data = new FormData();
    data.append('file', selectorFiles[0]);
    data.append('foldername', 'category');

    axios.post("/file/upload", data)      
    .then((response) => {
      setImageUrl(response.data);
    }).catch(err => {
      dispatch({
        type:SET_MESSAGE,
        payload:err.response.data
      })
    })
  }

  return (
    <>
      <div className="input_wrap w-100 ">
        <input name="name" type="text" placeholder=" "/>
        <label>Name</label>
      </div>

      <div className="input_wrap w-100 mt-3">
        <input name="position" type="number" placeholder=" "/>
        <label>Position</label>
      </div>

      <div className="form-group mt-3"> 
        <label className="main-content-label tx-11">Parent Category</label> 
        <Select
          options={option}
          isClearable={true}
          onChange={e => setParentCat(e?.value ? e.value : '')}
          className='react-select'
        />
      </div>  

      <div className="form-group"> 
        <label className="main-content-label tx-11">Category Type</label> 
        <select name="type" className="form-control text-capitalize">
          <option value=''>Select category type ...</option>
          {category_type.map(type => (
            <option value={type}>{type}</option>
          ))}
        </select>
      </div>

      <div className="form-group"> 
        <label className="main-content-label tx-11">Status</label> 
        <select name="status" className="form-control">
          <option value="true">Active</option>
          <option value="false">Inactive</option>
        </select>
      </div>

      <div className="form-group"> 
        <label className="main-content-label tx-11">Image</label> 
        <input name="image" defaultValue={imageUrl} type="hidden" />
        <input className="form-control" type="file" onChange={(e)=> uploadF(e.target.files)} /> 
        {imageUrl ? <><hr/> <img src={imageUrl} alt="" className="rounded wd-100p wd-sm-200" /></> : ''}
      </div>
      <button disabled={btnLoader} className="btn btn-main-primary pd-x-20 float-right" type="submit">
        {!btnLoader ? '+ Add Category' : <span className="spinner-border spinner-border-sm mx-5"></span>}
      </button>
    </>
  )
}


export default CategoryAdd;
