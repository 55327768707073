import React,{useState} from "react";
import axios from "axios";
import { clubbedBuyingEditSociety } from "../../../actions/clubbed-buying.action";
import { toast } from 'react-toastify';

const ClubbedBuyingSocietyEdit = ({defaultData,formHandle, dispatch}) =>{
  
  // FORM SUBMIT
  const onSubmitEditSociety = async (e) => { 
    const formData = new FormData(e.target)
    const data = {}
    e.preventDefault()
    for (let entry of formData.entries()) {
        data[entry[0]] = entry[1]
    } 

    const submitData = {
      name: data.name,
      status:data.status,
      address: {
        line1: data.line1,
        line2: data.line2,
        city: data.city,
        state: "Uttar Pradesh",
        pincode: data.pincode,
        country: "India"
      }
    }

    await toast.promise(
			dispatch(clubbedBuyingEditSociety(submitData,defaultData.id)),
			{
				pending: 'Society updating...',
				success: 'Successfully! Society Updated',
				error: 'Society not updated'
			}
		)

    await e.target.reset();
    await formHandle(false);
  }

  return(
    <>
    {defaultData &&
      <div className="modal effect-flip-vertical show" aria-modal="true" style={{display:"block"}}>
        <div className="modal-dialog modal-dialog-right" role="document">
          <form onSubmit={ (e) => onSubmitEditSociety(e)} className="modal-content modal-content-demo">
            <div className="modal-header">
              <h6 className="modal-title">Edit Banner</h6>
              <button aria-label="Close" onClick={() => formHandle(false)} className="close" data-dismiss="modal" type="button"><span aria-hidden="true">×</span></button>
            </div>
            <div className="modal-body">

              <div className="input_wrap w-100 ">
                <input name="name" defaultValue={defaultData.name} type="text" placeholder=" "/>
                <label>Name</label>
              </div>

              <div className="input_wrap w-100 mt-3">
                <input name="line1" defaultValue={defaultData.address.line1} type="text" placeholder=" "/>
                <label>Address Line 1</label>
              </div>

              <div className="input_wrap w-100 mt-3">
                <input name="line2" defaultValue={defaultData.address.line2} type="text" placeholder=" "/>
                <label>Address Line 2</label>
              </div>

              <div className="input_wrap w-100 mt-3">
                <input name="city" defaultValue={defaultData.address.city} type="text" placeholder=" "/>
                <label>City/ District/ Town</label>
              </div>

              <div className="input_wrap w-100 mt-3">
                <input name="pincode" defaultValue={defaultData.address.pincode} type="number" placeholder=" "/>
                <label>Pin Code</label>
              </div>

              <div className="input_wrap w-100 mt-3">
                <input name="state" defaultValue={defaultData.address.state} disabled type="text" placeholder=" "/>
                <label>State</label>
              </div>   

              <div className="form-group mt-3"> 
                <label className="main-content-label tx-11">Status</label> 
                <select defaultValue={defaultData.status} name="status" className="form-control">
                  <option value="active">Active</option>
                  <option value="inactive">InActive</option>
                </select>
              </div>

            </div>

            <div className="modal-footer">
              <div className="btn ripple btn-outline-primary" data-dismiss="modal" type="button" onClick={() => formHandle(false)}>Close</div>
              <button className="btn ripple btn-primary" type='submit'>Save Changes</button>
            </div>

          </form>
        </div>
      </div>
    }
    </>
  )
}

export default ClubbedBuyingSocietyEdit;