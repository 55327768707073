export const paymentModeOptions   = ['expired','inactive', 'active', 'cod','Wallet', 'CREDIT_CARD', 'DEBIT_CARD','NET_BANKING','UPI', 'PREPAID_CARD', 'PhonePe']
export const paymentStatusOptions = ['unpaid','paid', 'pending', 'SUCCESS']
export const paidPaymentMode      = ['Wallet', 'CREDIT_CARD', 'DEBIT_CARD','NET_BANKING','UPI', 'PhonePe']
export const forChangingPaymentStatusPaymentModeRequired =  ['cod','Wallet', 'CREDIT_CARD', 'DEBIT_CARD','NET_BANKING','UPI', 'PhonePe']
export const orderPagination     = [25, 50, 75, 100]

export const orderStatusOptions   = [
  {
    "name"           : "Order Placed",
    "value"          : "cb-order-placed",
    "new_order"      : true,
    "order_status"   : ['cb-order-placed', 'cb-order-confirmed-cod-payment', 'cb-order-confirmed-online-payment', 'cb-order-cancelled'],
    "payment_status" : ['unpaid'],
    "payment_mode"   : [],
    
  },
  {
    "name"  : "Order Confirmation Info Sent",
    "value" : "cb-order-confirmation-info-sent",
    "new_order"      : false,
    "order_status"   : ['cb-order-confirmation-info-sent', 'cb-order-cancelled'],
    "payment_status" : ['unpaid','paid'],
    "payment_mode"   : [],
  },
  {
    "name"  : "Order confirmed (Online  Payment)",
    "value" : "cb-order-confirmed-online-payment",
    "new_order"      : false,
    "order_status"   : ['cb-order-confirmed-online-payment', 'cb-order-out-for-delivery-prepaid', 'cb-order-cancelled'],
    "payment_status" : ['unpaid','paid'],
    "payment_mode"   : forChangingPaymentStatusPaymentModeRequired,
  },
  {
    "name"  : "Order confirmed (COD)",
    "value" : "cb-order-confirmed-cod-payment",
    "new_order"      : false,
    "order_status"   : ['cb-order-confirmed-cod-payment', 'cb-order-out-for-delivery-cod', 'cb-order-cancelled'],
    "payment_status" : ['unpaid','paid'],
    "payment_mode"   : forChangingPaymentStatusPaymentModeRequired,
  },
  {
    "name"  : "Order Expired",
    "value" : "cb-order-expired",
    "new_order"      : false,
    "order_status"   : ['cb-order-expired'],
    "payment_status" : [],
    "payment_mode"   : [],
  },
  {
    "name"  : "Order Out For Delivery (Prepaid)",
    "value" : "cb-order-out-for-delivery-prepaid",
    "new_order"      : false,
    "order_status"   : ['cb-order-out-for-delivery-prepaid', 'cb-order-delivered', 'cb-order-cancelled'],
    "payment_status" : ['unpaid','paid'],
    "payment_mode"   : forChangingPaymentStatusPaymentModeRequired,
  },
  {
    "name"  : "Order Out For Delivery (COD)",
    "value" : "cb-order-out-for-delivery-cod",
    "new_order"      : false,
    "order_status"   : ['cb-order-out-for-delivery-cod', 'cb-order-delivered', 'cb-order-cancelled'],
    "payment_status" : ['unpaid','paid'],
    "payment_mode"   : forChangingPaymentStatusPaymentModeRequired,
  },
  {
    "name"  : "Order Delivered",
    "value" : "cb-order-delivered",
    "new_order"      : false,
    "order_status"   : ['cb-order-delivered', 'cb-order-cancelled'],
    "payment_status" : [],
    "payment_mode"   : [],
  },
  {
    "name"  : "Order Cancelled",
    "value" : "cb-order-cancelled",
    "new_order"      : false,
    "order_status"   : ['cb-order-cancelled'],
    "payment_status" : [],
    "payment_mode"   : [],
  }
]
