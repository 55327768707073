import {useState, useEffect} from 'react'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import {convertToArray, inputFirstLetterCapital} from '../../helpers/common-function.helper'
import {addUser} from '../../actions/users.action'
import { roles } from '../../helpers/user.helper'
import { modulesList } from '../../helpers/user.helper'
import Select from 'react-select'
import { customerSearch } from '../../actions/users.action'


function UserAdd({handleComponent, dispatch, role, loadAndRefreshList, customerSearchResult}) {
  const [userRole, setUserRole]    = useState(role)
  const [btnLoader, setBtnLoader]  = useState(false)
  const defaultEmployeeNo          = 'HTB00001'
  const [userInfo, setUserInfo]    = useState()
  const [searching, setSearching]  = useState(false)
  const [searchCustomer, setSearchCustomer]             = useState('')
  const [customerSeachOption, setCustomerSearchOption]  = useState([])
  

  useEffect(async () =>{
    if(!searchCustomer.length) return 
    setSearching(true)
    await dispatch(customerSearch(searchCustomer,'customer', 'reference'))
    await setSearching(false)
  }, [searchCustomer])


  useEffect(() => {
    var formateCustomerOptions = customerSearchResult?.map((option,index) => ({
      value: option,
      label: `${option.first_name} ${option.last_name} "${option.mobile_number}" -  #${option.user_number}`
    }))
    setCustomerSearchOption(formateCustomerOptions)
  },[customerSearchResult])


  const onSubmitAddUser = async (e) => {
    e.preventDefault()

    var formateCapabilities = {}
    modulesList.map(module => {
      formateCapabilities[module.module_name] = {}
      module.components.map(component => {
        formateCapabilities[module.module_name][component.name] = component.default.filter(r => r == userRole).length ? true : false
        formateCapabilities[module.module_name]['module_enable'] = formateCapabilities[module.module_name]['module_enable'] !== true ? component.default.filter(r => r == userRole).length ? true : false : true
      })
    })

    const formData = new FormData(e.target)
    if(userRole != 'customer') formData.append('capability', JSON.stringify(formateCapabilities))
    else formData.append('password', Math.random())
    userInfo ? formData.append('reference', userInfo._id) : formData.append('reference', '')
    const data = {}
    for (let entry of formData.entries()) {
      data[entry[0]] = entry[1]
    } 

    if(data.user_number == defaultEmployeeNo){
      setBtnLoader(false);
      return toast.warning(<>Buddy! 🙂 Enter Valid <b>#HTB Employee Number</b></>)
    }

    
    await toast.promise(
      dispatch(addUser(data, role)),
      {
        pending: 'User adding...',
        success: 'Successfully! User Added',
        error: {
          render(){
            setBtnLoader(false);
            return 'User not added'
          }
          
        }
      }
    )
    await loadAndRefreshList()
    await handleComponent(false)
  }

  return (
    <div className="modal" aria-hidden="true" style={{display:'block'}}> 
      <div className="overlayer" onClick={() => handleComponent(false)}></div>
      <div className="modal-dialog modal-dialog-right"> 
        <div className="modal-content modal-content-demo"> 
          <div className="modal-header"> 
            <h6 className="modal-title d-flex align-items-center"><i className='bx bx-user-plus font-size-22 mr-2'></i> Add User - &nbsp; <span className='text-capitalize'>{userRole}</span></h6>
          </div> 
          <form onSubmit={ (e) => {setBtnLoader(true); onSubmitAddUser(e)}}>
            <div className="modal-body overflow-unset">                                                   
                <div className="row">
                  <div className="col-md-6">
                    <div className="input_wrap w-100">
                      <input name="first_name" type="text" onChange={inputFirstLetterCapital} placeholder=" " autoComplete='new-password'/>
                      <label>First Name</label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input_wrap w-100">
                      <input name="last_name" type="text" onChange={inputFirstLetterCapital} placeholder=" " autoComplete='new-password'/>
                      <label>Last Name</label>
                    </div>
                  </div>
                  <div className="col-md-6 mt-3">
                    <div className="input_wrap w-100">
                      <input name="email" type="email" placeholder=" " autoComplete='new-password'/>
                      <label>Email</label>
                    </div>
                  </div>
                  <div className="col-md-6 mt-3">
                    <div className="input_wrap w-100">
                      <input name="mobile_number" type="number" placeholder=" " autoComplete='new-password'/>
                      <label>Mobile Number</label>
                    </div>
                  </div>
                  <div className="col-md-6 mt-2">
                    <div className="form-group">
                      <label className="main-content-label tx-11">Role</label> 
                      <select defaultValue={role} onChange={e => setUserRole(e.target.value)} className="form-control" name="role">
                        <option value="">Select Role...</option>
                        {roles.map((role,i) => (
                          <option key={i} value={role.name}>{role.label}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6 mt-2">
                    <div className="form-group">
                      <label className="main-content-label tx-11">Status</label>
                      <select className="form-control" name="status">
                        <option value="active">Active</option>
                        <option value="pending">Pending</option>
                        <option value="inactive">InActive</option>
                        <option value="banned">Banned</option>
                      </select>
                    </div>
                  </div>

                  {userRole == 'customer' && userRole &&
                    <>
                      <div className="col-md-12 mt-2 mb-3 animated flash">
                        <div className="input_wrap w-100">
                          <input  name="user_number" defaultValue={'HBCN0000000001'} type="text" placeholder=" " autoComplete='new-password'/>
                          <label>HTB Customer Number</label>
                        </div>
                      </div>

                      <div className='col-md-12'>
                        <Select
                          isMulti={false}
                          isLoading={searching}
                          closeMenuOnSelect={true}
                          onInputChange={e => {setSearchCustomer(e);}}
                          onChange={e => {setUserInfo(e?.value);}}
                          autoFocus={true}
                          options={customerSeachOption}
                          className="basic-multi-select react-select"
                          placeholder="Add Reference"
                        />
                      </div>
                    </>
                  }
                  
                  {userRole != 'customer' && userRole && <>
                  <div className="col-md-12 mt-2 mb-3 animated flash">
                    <div className="input_wrap w-100">
                      <input  name="user_number" defaultValue={defaultEmployeeNo} type="text" placeholder=" " autoComplete='new-password'/>
                      <label>HTB Employee Number</label>
                    </div>
                  </div>

                  <div className="col-md-12 animated flash">
                    <div className="input_wrap w-100">
                      <input  name="password" type="password" placeholder=" " autoComplete='new-password'/>
                      <label>Password</label>
                    </div>
                  </div></>}
                </div>
            </div> 
            <div className="modal-footer">
                <div className="btn ripple btn-outline-primary" data-dismiss="modal" type="button" onClick={() => handleComponent(false)}>Close</div>
                <button type='submit' disabled={btnLoader} className="btn ripple btn-primary">
                  {!btnLoader ? 'Add User' : <span className="spinner-border spinner-border-sm mx-3"></span>}
                </button>
            </div>
          </form>   
        </div> 
      </div> 
    </div>
  )
}


function mapStateToProps(state) {
  let { customerAddressById }    = state.users.users
  let customerSearchResult       = state.users.users.referenceSearch
  return {
    customerSearchResult,
  };
}

export default connect(mapStateToProps)(UserAdd);